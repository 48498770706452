import React, { useEffect, useState } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

import { get } from 'lodash';

import {
  ExtendedFormContextType,
  useExtendedFormContext,
} from 'contexts/extendedFormContext';
import { Maybe } from 'types/UtilityTypes';
import { cn } from 'utils/shadcn';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, placeholder, onBlur, ...props }, ref) => {
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const trimmedValue = event.target.value.trim();
      event.target.value = trimmedValue;

      if (onBlur) {
        onBlur(event);
      }
    };

    const [highlightDirtyField, setHighlightDirtyField] = useState(false);

    let formContext: Maybe<UseFormReturn<any>> = null;
    let extendedFormContext: Maybe<ExtendedFormContextType> = null;

    try {
      formContext = useFormContext();
      extendedFormContext = useExtendedFormContext();
    } catch {
      // No form context available
    }

    useEffect(() => {
      if (formContext && extendedFormContext && props.name) {
        const {
          getFieldState,
          formState: { dirtyFields },
        } = formContext;
        const highlightDirtyFields =
          extendedFormContext.highlightDirtyFields ?? false;
        const fieldState = getFieldState(props.name);
        const isDirty = fieldState.isDirty || get(dirtyFields, props.name);

        setHighlightDirtyField(isDirty && highlightDirtyFields);
      } else {
        // If no form context, reset the highlight
        setHighlightDirtyField(false);
      }
    }, [extendedFormContext, formContext, props.name]);

    return (
      <input
        type={type}
        className={cn(
          'flex h-9 w-full rounded-[4px] border border-orange-border bg-white text-[14px] text-grayscale-content-2',
          'px-3 py-2 ring-offset-white scroll-mt-20',
          'file:border-0 file:bg-transparent file:text-[14px] file:font-medium',
          'placeholder:text-grayscale-content-2/30 focus-visible:border-pink-crimson',
          'disabled:cursor-not-allowed read-only:cursor-not-allowed read-only:bg-grayscale-bg-secondary read-only:border-grayscale-border focus-visible:read-only:border-grayscale-border  read-only:text-grayscale-content-3',
          className,
          highlightDirtyField &&
            'bg-yellow-50 disabled:bg-yellow-50/50 read-only:bg-yellow-50/50'
        )}
        ref={ref}
        placeholder={props.readOnly ? '' : placeholder}
        {...props}
        onBlur={handleBlur}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
