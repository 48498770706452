import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

interface CreateQuickQuoteReplyDraft {
  threadItemId: string;
  draftBody: string;
}

export async function createQuickQuoteReplyDraft({
  threadItemId,
  draftBody,
}: CreateQuickQuoteReplyDraft): Promise<Result<null, ApiError>> {
  try {
    const response = await axios.post<null>(`/quote/private/quickquote/draft`, {
      threadItemId,
      draftBody,
    });

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'createQuickQuoteReplyDraft' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
      changes: error.response?.data.changes,
    });
  }
}
