import { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { SidebarStateContext } from 'contexts/sidebarStateContext';

type FetchViewedLoadsResponse = {
  viewedLoadsHistory: string[];
};

export default async function useFetchViewedLoads(): Promise<void> {
  const { setCurrentState } = useContext(SidebarStateContext);

  const axiosRes = await axios.get<FetchViewedLoadsResponse>(`load/viewed`);
  const {
    data: { viewedLoadsHistory },
  } = axiosRes;

  setCurrentState((prevState) => ({
    ...prevState,
    viewedLoads: viewedLoadsHistory ?? [],
  }));
}
