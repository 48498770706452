import { Maybe } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';

type handleClickedSuggestionAndGetElementsProps = {
  scrollTo: Maybe<HTMLElement>;
  mustScrollUntil: Maybe<HTMLElement>;
};

export const handleClickedSuggestionAndGetElements = async (
  clickedSuggestion: Maybe<GenericSuggestion>
): Promise<handleClickedSuggestionAndGetElementsProps> => {
  if (!clickedSuggestion) {
    return {
      scrollTo: null,
      mustScrollUntil: null,
    };
  }

  let scrollTo: Maybe<HTMLElement> = null; // lowest vertical offset of all inputs
  let mustScrollUntil: Maybe<HTMLElement> = null; // highest vertical offset of all inputs

  // since we'll be dealing with element position, sleep wait for js to run a couple of event cycles and properly load DOM
  await new Promise((resolve) => setTimeout(resolve, 200));

  // Helper function to build field names for nested properties
  const findAndScrollToElements = (prefix: string, obj: any) => {
    Object.entries(obj).forEach(([key, value]) => {
      const fullFieldName = prefix ? `${prefix}.${key}` : key;

      // Determine the form field name based on the pipeline
      let fieldName;
      switch (true) {
        // We explicitly construct the field name for the following pipelines because they
        // don't match the suggestion format doesn't match the TMS form format
        case clickedSuggestion.pipeline === SuggestionPipelines.CarrierInfo:
          fieldName = `carrier.${fullFieldName}`;
          break;
        case clickedSuggestion.pipeline ===
          SuggestionPipelines.ApptConfirmation &&
          !!clickedSuggestion.suggested?.pickupApptTime:
          fieldName = `pickup.apptStartTime`;
          break;
        case clickedSuggestion.pipeline ===
          SuggestionPipelines.ApptConfirmation &&
          !!clickedSuggestion.suggested?.dropoffApptTime:
          fieldName = `consignee.apptStartTime`;
          break;
        default:
          fieldName = fullFieldName;
          break;
      }

      const fieldElem = document.querySelector(
        `[name="${fieldName}"], [data-name="${fieldName}"]`
      ) as HTMLElement;

      if (fieldElem?.offsetTop != null) {
        const fieldOffsetTop = fieldElem.offsetTop;

        if (!scrollTo || fieldOffsetTop <= scrollTo.offsetTop) {
          scrollTo = fieldElem;
        }

        if (!mustScrollUntil || fieldOffsetTop >= mustScrollUntil.offsetTop) {
          mustScrollUntil = fieldElem;
        }
      }

      // Recursively check nested fields if `value` is an object (for example, for `customer.addressLine1` in load building)
      if (value && typeof value === 'object') {
        findAndScrollToElements(fullFieldName, value);
      }
    });
  };

  // Start with the root object, `clickedSuggestion.suggested`
  findAndScrollToElements('', clickedSuggestion.suggested);

  return { scrollTo, mustScrollUntil };
};
