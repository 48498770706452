import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CarrierCheckInInputs } from 'pages/LoadView/TrackAndTrace/CarrierCheckIn';
import { ApiError } from 'types/api/ApiError';
import { UpdateCarrierCheckInResult } from 'types/api/UpdateCarrierCheckInResult';
import captureException from 'utils/captureException';

export async function updateCarrierCheckInSOPs(
  loadId: number,
  data: CarrierCheckInInputs
): Promise<Result<UpdateCarrierCheckInResult, ApiError>> {
  const {
    freightTrackingId,
    dispatch,
    pickup,
    afterPickup,
    inTransit,
    dropoff,
    afterDropoff,
  } = data;

  const requestBody = {
    freightTrackingId,
    dispatch: {
      checkIn: dispatch.checkIn,
      sendTime: dispatch.sendTime,
      duration: Number(dispatch.duration),
      timeUnit: dispatch.timeUnit,
      recipient: dispatch.recipient,
      cc: dispatch.cc,
      subject: dispatch.subject,
      emailDraft: dispatch.emailDraft,
    },
    pickup: {
      checkIn: pickup.checkIn,
      sendTime: pickup.sendTime,
      duration: Number(pickup.duration),
      timeUnit: pickup.timeUnit,
      recipient: pickup.recipient,
      cc: pickup.cc,
      subject: pickup.subject,
      emailDraft: pickup.emailDraft,
    },
    afterPickup: {
      checkIn: afterPickup.checkIn,
      sendTime: afterPickup.sendTime,
      duration: Number(afterPickup.duration),
      timeUnit: afterPickup.timeUnit,
      recipient: afterPickup.recipient,
      cc: afterPickup.cc,
      subject: afterPickup.subject,
      emailDraft: afterPickup.emailDraft,
    },
    inTransit: {
      checkIn: inTransit.checkIn,
      frequency: Number(inTransit.frequency),
      timeUnit: inTransit.timeUnit,
      recipient: inTransit.recipient,
      cc: inTransit.cc,
      subject: inTransit.subject,
      emailDraft: inTransit.emailDraft,
    },
    dropoff: {
      checkIn: dropoff.checkIn,
      sendTime: dropoff.sendTime,
      duration: Number(dropoff.duration),
      timeUnit: dropoff.timeUnit,
      recipient: dropoff.recipient,
      cc: dropoff.cc,
      subject: dropoff.subject,
      emailDraft: dropoff.emailDraft,
    },
    afterDropoff: {
      checkIn: afterDropoff.checkIn,
      sendTime: afterDropoff.sendTime,
      duration: Number(afterDropoff.duration),
      timeUnit: afterDropoff.timeUnit,
      recipient: afterDropoff.recipient,
      cc: afterDropoff.cc,
      subject: afterDropoff.subject,
      emailDraft: afterDropoff.emailDraft,
    },
  };

  try {
    const resp = await axios.post(`/load/${loadId}/sops/carrier`, requestBody);

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'updateCarrierCheckInSOPs' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to update Carrier Check-in SOPs' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to update Carrier Check-in SOPs',
    });
  }
}
