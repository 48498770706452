import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';
import captureException from 'utils/captureException';

export async function getQuickQuoteSuggestion(
  threadID: string
): Promise<Result<SuggestedQuoteChange[], ApiError>> {
  try {
    const response = await axios.get<SuggestedQuoteChange[]>(
      `thread/${encodeURIComponent(threadID)}/quote/request/suggestions`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getQuickQuoteSuggestion' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get quick quote suggestion' });
    }

    return err({
      message:
        error.response?.data.message || 'Failed to get quick quote suggestion',
    });
  }
}
