import { isAxiosError } from 'axios';
import 'dayjs/locale/en';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore auth is in the parent dir
import AuthService from '@auth/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Note } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export async function submitNote(
  loadId: number,
  note: string,
  source: Maybe<string>,
  isException: Maybe<boolean>,
  isOnTime: Maybe<boolean>
): Promise<Result<Note[], ApiError>> {
  const user = await AuthService.getCurrentUser();
  const finalNote = note + ' - ' + user?.email.split('@')[0];

  const requestBody = {
    note: finalNote,
    source: source,
    isException: isException,
    isOnTime: isOnTime,
  };

  try {
    const resp = await axios.post(`/load/${loadId}/note`, requestBody);

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitNote' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit note' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to submit note',
    });
  }
}
