import { Button } from 'components/Button';
import ButtonLoader from 'components/loading/ButtonLoader';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';

import { TruckTextInput } from '../../TruckListFormFields';

type RedwoodCarrierFieldsetProps = {
  handleValidateCarrier: () => Promise<void>;
  loading: boolean;
};

export const RedwoodCarrierFieldset = ({
  handleValidateCarrier,
  loading,
}: RedwoodCarrierFieldsetProps) => (
  <>
    <TruckTextInput name={`carrier.name`} label='Carrier Name' />
    <div className='mt-3'>
      <TruckTextInput name={`carrier.contact.name`} label='Contact Name' />
    </div>
    <div className='mt-3'>
      <TruckTextInput
        name={`carrier.contact.email`}
        label='Contact Email'
        required
      />
    </div>
    <div className='grid grid-cols-4 gap-4 mt-2 mx-0 w-full'>
      <div className='col-span-2'>
        <TruckTextInput name={`carrier.mc`} label='MC #' aiIconOnly={true} />
      </div>

      <div className='col-span-2'>
        <TruckTextInput name={`carrier.dot`} label='DOT #' aiIconOnly={true} />
      </div>
    </div>

    <Button
      buttonName={ButtonName.ValidateTruckListCarrier}
      buttonNamePosthog={ButtonNamePosthog.ValidateTruckListCarrier}
      type='button'
      className='w-full mt-8'
      disabled={loading}
      onClick={handleValidateCarrier}
    >
      {loading ? <ButtonLoader /> : 'Validate Carrier'}
    </Button>
  </>
);
