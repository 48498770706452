import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';

import { logUnknownSuggestionPipeline } from './logSuggestionErrors';

export const getSuggestionTitleByPipeline = (pipeline: SuggestionPipelines) => {
  switch (pipeline) {
    case SuggestionPipelines.CarrierInfo:
      return 'Carrier Info';
    case SuggestionPipelines.ApptConfirmation:
      return 'Appointment';
    case SuggestionPipelines.CheckCall:
      return 'Check Call';
    case SuggestionPipelines.QuickQuote:
      return 'Quick Quote';
    case SuggestionPipelines.LoadBuilding:
      return 'Load Building';
    default:
      logUnknownSuggestionPipeline('suggestionPipelineToTitle');
      return 'Other';
  }
};
