import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Load } from 'types/Load';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type CreateLoadResponse = {
  load: Load;
  message: string;
};

export async function createLoad(data: {
  load: Load;
}): Promise<Result<CreateLoadResponse, ApiError>> {
  try {
    const response = await axios.post<CreateLoadResponse>(`/load`, data);

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'createLoad' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
      load: error.response?.data.load,
    });
  }
}
