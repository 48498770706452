export type InputPreviousValueProps = {
  prevValue: string;
};

export default function InputPreviousValue({
  prevValue,
}: InputPreviousValueProps): JSX.Element {
  return (
    <div className='mt-0.5'>
      <span className='text-xs text-grayscale-content-3 ita font-medium'>
        {'Previous value: '}
      </span>
      <span className='text-xs text-grayscale-content-3 font-normal'>
        {prevValue}
      </span>
    </div>
  );
}
