import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import dayjs, { Dayjs } from 'dayjs';

import { Checkbox } from 'components/Checkbox';
import ExpandableContent from 'components/ExpandableContent';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { Textarea } from 'components/Textarea';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

import {
  CarrierCheckInInputs,
  CarrierSOPTextInput,
  formatNormalizedTimestamp,
} from './CarrierCheckIn';

export enum TimeUnits {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
}

interface CarrierCheckInDetailsFormProps {
  name:
    | 'dispatch'
    | 'pickup'
    | 'afterPickup'
    | 'inTransit'
    | 'dropoff'
    | 'afterDropoff';
  label: string;
  control: Control<any>;
  disabled?: boolean;
  isApptInPast?: boolean;
  load: NormalizedLoad;
}

const getSingularOrPlural = (value: number, unit: string) => {
  if (value == 1) {
    switch (unit) {
      case TimeUnits.Minutes:
        return 'minute';
      case TimeUnits.Hours:
        return 'hour';
      case TimeUnits.Days:
        return 'day';
      default:
        return unit.toLowerCase();
    }
  }

  return unit.toLowerCase();
};

export default function CarrierCheckInDetailsForm({
  name,
  label,
  control,
  disabled = false,
  isApptInPast = false,
  load,
}: CarrierCheckInDetailsFormProps) {
  const {
    formState: { errors },
  } = useFormContext();
  const formErrors = errors as FieldErrors<CarrierCheckInInputs>;

  const [emailSendTime, setEmailSendTime] = useState<Maybe<string>>(null);

  const pickupTime = dayjs(load.pickup.apptStartTime);
  const dropoffTime = dayjs(load.consignee.apptStartTime);

  const duration = useWatch({ control, name: `${name}.duration` });
  const timeUnit = useWatch({ control, name: `${name}.timeUnit` });
  const checkIn = useWatch({ control, name: `${name}.checkIn` });
  const frequency = useWatch({ control, name: `${name}.frequency` });

  const calculateEmailSendTime = (
    duration: number,
    timeUnit: TimeUnits,
    baseTime: Dayjs,
    timezone: string,
    isBefore: boolean = false
  ) => {
    let sendTime = baseTime;
    switch (timeUnit) {
      case TimeUnits.Minutes:
        sendTime = isBefore
          ? sendTime.subtract(duration, 'minute')
          : sendTime.add(duration, 'minute');
        break;
      case TimeUnits.Hours:
        sendTime = isBefore
          ? sendTime.subtract(duration, 'hour')
          : sendTime.add(duration, 'hour');
        break;
      case TimeUnits.Days:
        sendTime = isBefore
          ? sendTime.subtract(duration, 'day')
          : sendTime.add(duration, 'day');
        break;
    }

    return formatNormalizedTimestamp(dayjs(sendTime), 'MM/DD HH:mm', timezone);
  };

  const calculateSingleEmailTime = (
    startTime: dayjs.Dayjs,
    endTime: dayjs.Dayjs,
    timezone: string
  ) => {
    const midpoint = startTime.add(endTime.diff(startTime) / 2);
    return formatNormalizedTimestamp(midpoint, 'MM/DD HH:mm', timezone);
  };

  const calculateInTransitEmails = (
    start: string,
    end: string,
    frequency: number,
    unit: TimeUnits,
    load: NormalizedLoad
  ) => {
    const startTime = dayjs.tz(start, load.pickup.timezone);
    const endTime = dayjs.tz(end, load.consignee.timezone);

    let totalIntervals = 0;
    switch (unit) {
      case TimeUnits.Minutes:
        totalIntervals = endTime.diff(startTime, 'minute') / frequency;
        break;
      case TimeUnits.Hours:
        totalIntervals = endTime.diff(startTime, 'hour') / frequency;
        break;
      case TimeUnits.Days:
        totalIntervals = endTime.diff(startTime, 'day') / frequency;
        break;
    }

    return Math.max(Math.floor(totalIntervals), 0);
  };

  useEffect(() => {
    if (checkIn) {
      let sendTime = null;
      if (name === 'dispatch') {
        sendTime = calculateEmailSendTime(
          Number(duration),
          timeUnit,
          dayjs(),
          dayjs.tz.guess()
        );
      } else if (name === 'pickup') {
        sendTime = calculateEmailSendTime(
          isApptInPast ? Number(duration) : 30,
          timeUnit,
          isApptInPast ? dayjs() : pickupTime,
          isApptInPast ? dayjs.tz.guess() : load.pickup.timezone,
          !isApptInPast
        );
      } else if (name === 'dropoff') {
        sendTime = calculateEmailSendTime(
          isApptInPast ? Number(duration) : 30,
          timeUnit,
          isApptInPast ? dayjs() : dropoffTime,
          isApptInPast ? dayjs.tz.guess() : load.consignee.timezone,
          !isApptInPast
        );
      } else if (name === 'afterPickup') {
        if (duration && timeUnit) {
          sendTime = calculateEmailSendTime(
            Number(duration),
            timeUnit,
            isApptInPast ? dayjs() : pickupTime,
            isApptInPast ? dayjs.tz.guess() : load.pickup.timezone
          );
        }
      } else if (name === 'afterDropoff') {
        if (duration && timeUnit) {
          sendTime = calculateEmailSendTime(
            Number(duration),
            timeUnit,
            isApptInPast ? dayjs() : dropoffTime,
            isApptInPast ? dayjs.tz.guess() : load.consignee.timezone
          );
        }
      } else if (name === 'inTransit') {
        const startTime = pickupTime.isAfter(dayjs()) ? pickupTime : dayjs();
        if (frequency && timeUnit && frequency > 0) {
          const startTimeWithTZ = formatNormalizedTimestamp(
            startTime,
            'MM/DD HH:mm',
            load.pickup.timezone
          );
          const endTimeWithTZ = formatNormalizedTimestamp(
            dropoffTime,
            'MM/DD HH:mm',
            load.consignee.timezone
          );

          const emailCount = calculateInTransitEmails(
            startTimeWithTZ,
            endTimeWithTZ,
            Number(frequency),
            timeUnit,
            load
          );

          if (emailCount === 1) {
            const singleEmailTime = calculateSingleEmailTime(
              startTime,
              dropoffTime,
              load.pickup.timezone
            );
            sendTime = `Sending 1 email at ${singleEmailTime}`;
          } else {
            const timeUnitDisplay = getSingularOrPlural(frequency, timeUnit);
            sendTime = `Sending ${emailCount} emails every ${frequency} ${timeUnitDisplay} between ${startTimeWithTZ} and ${endTimeWithTZ}`;
          }
        } else {
          sendTime =
            'Please specify a valid frequency and time unit for in-transit emails.';
        }
      }
      setEmailSendTime(sendTime);
    } else {
      setEmailSendTime(null);
    }
  }, [checkIn, duration, timeUnit, frequency, name, pickupTime, dropoffTime]);

  return (
    <div className='grid gap-2 grid-cols-1 mb-2 w-full mx-0'>
      <Controller
        name={`${name}.checkIn`}
        control={control}
        render={({ field }) => (
          <div className='flex items-center space-x-2'>
            <Checkbox
              onCheckedChange={(checked) => {
                field.onChange(checked);
              }}
              checked={field.value}
              disabled={disabled}
            />
            <label
              htmlFor={`${name}.checkIn`}
              className='leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
            >
              {label}
            </label>
          </div>
        )}
      />

      {!disabled && (
        <div className='grid gap-2 grid-cols-2 w-full mx-0'>
          {(name === 'dispatch' || name === 'pickup' || name === 'dropoff') &&
            isApptInPast && (
              <div className='col-span-2'>
                <Label name=''>How long after now</Label>
              </div>
            )}

          {name === 'afterPickup' && (
            <div className='col-span-2'>
              <Label name=''>
                How long after {isApptInPast ? 'now' : 'pickup appt'}
              </Label>
            </div>
          )}

          {name === 'afterDropoff' && (
            <div className='col-span-2'>
              <Label name=''>
                How long after {isApptInPast ? 'now' : 'dropoff appt'}
              </Label>
            </div>
          )}

          {name !== 'inTransit' &&
            (name !== 'pickup' || isApptInPast) &&
            (name !== 'dropoff' || isApptInPast) && (
              <CarrierSOPTextInput
                name={`${name}.duration`}
                label=''
                inputType='number'
                hideAIHint={true}
                readOnly={disabled}
              />
            )}

          {name === 'inTransit' && (
            <CarrierSOPTextInput
              name={`${name}.frequency`}
              label=''
              inputType='number'
              hideAIHint={true}
              readOnly={disabled}
            />
          )}

          {(name !== 'pickup' || isApptInPast) &&
            (name !== 'dropoff' || isApptInPast) && (
              <div>
                <Controller
                  name={`${name}.timeUnit`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      disabled={disabled}
                    >
                      <SelectTrigger className='w-full mt-1'>
                        <SelectValue placeholder={TimeUnits.Hours} />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(TimeUnits).map((unit) => (
                          <SelectItem key={unit} value={unit}>
                            {unit}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </div>
            )}
        </div>
      )}

      {emailSendTime && (
        <div>
          <Label name=''>
            {name === 'inTransit'
              ? emailSendTime
              : `The email will be sent at: ${emailSendTime}`}
          </Label>
        </div>
      )}

      <ExpandableContent
        showText='Show email draft'
        hideText='Hide email draft'
        forceOpen={
          checkIn &&
          (formErrors?.[name]?.['recipient']?.message ||
            formErrors?.[name]?.['subject']?.message ||
            formErrors?.[name]?.['emailDraft']?.message)
        }
      >
        <div className='w-full col-span-6'>
          <CarrierSOPTextInput
            name={`${name}.recipient`}
            label='Recipient'
            inputType='email'
            placeholder='john@doe.com'
            readOnly={disabled}
            required={checkIn}
          />
        </div>
        <div className='w-full col-span-6 mt-4'>
          <CarrierSOPTextInput
            name={`${name}.cc`}
            label='CC'
            inputType='email'
            placeholder='john@doe.com'
            readOnly={disabled}
          />
        </div>
        <div className='w-full col-span-6 mt-4'>
          <CarrierSOPTextInput
            name={`${name}.subject`}
            label='Subject'
            inputType='text'
            readOnly={disabled}
            required={checkIn}
          />
        </div>
        <div className='w-full col-span-6 mt-4'>
          <Controller
            name={`${name}.emailDraft`}
            control={control}
            rules={{ required: checkIn ? 'Required' : undefined }}
            render={({ field: { onChange, value } }) => (
              <Textarea
                className='p-2 h-96 whitespace-pre-wrap'
                onChange={onChange}
                value={value}
                disabled={disabled}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`${name}.emailDraft`}
            render={({ message }: { message: string }) => (
              <p className='text-red-500 text-xs'>{message}</p>
            )}
          />
        </div>
      </ExpandableContent>
    </div>
  );
}
