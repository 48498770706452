import { ReactNode, useState } from 'react';

import { SidebarViewContext } from 'contexts/sidebarViewContext';
import SidebarView from 'types/enums/SidebarView';

export default function SidebarViewProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentView, setCurrentView] = useState<SidebarView>(
    SidebarView.Loads
  );

  return (
    <SidebarViewContext.Provider
      value={{
        currentView: currentView,
        setCurrentView: setCurrentView,
      }}
    >
      {children}
    </SidebarViewContext.Provider>
  );
}
