import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import {
  ChevronLeft,
  ChevronRight,
  Info,
  LocateFixed,
  RouteIcon,
} from 'lucide-react';
import { KeyedMutator } from 'swr';

import { Accordion } from 'components/Accordion';
import { Badge } from 'components/Badge';
import { Button } from 'components/Button';
import { Timeline } from 'components/CheckCallTimeline';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import DateTimeInput from 'components/input/DateTimeInput';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { SuggestionsResponse } from 'hooks/useFetchSuggestions';
import { useLoadInvalidation } from 'hooks/useLoadContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import useTMSContext from 'hooks/useTMSContext';
import { useToast } from 'hooks/useToaster';
import { getCheckCallHistory } from 'lib/api/getCheckCallHistory';
import { skipSuggestion } from 'lib/api/skipSuggestion';
import { SubmitCheckCallProps, submitCheckCall } from 'lib/api/submitCheckCall';
import { CheckCall } from 'types/CheckCall';
import { NormalizedLoad } from 'types/Load';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import CheckCallSource from 'types/enums/CheckCallSource';
import TMS from 'types/enums/Integrations';
import Pageview from 'types/enums/Pageview';
import { CheckCallSuggestion } from 'types/suggestions/LoadSuggestions';
import { titleCase } from 'utils/formatStrings';
import { normalizeDateForTMS } from 'utils/parseDatesForTMSForm';
import { normalizeUTCDate } from 'utils/time';

import { LoadSectionAccordionItem } from '../LoadInformationTab';

type CheckCallSectionProps = {
  normalizedLoad: NormalizedLoad;
  suggestions: CheckCallSuggestion[];
  invalidateSuggestions: KeyedMutator<SuggestionsResponse>;
};

interface StatusMapping {
  [key: string]: {
    [key in TMS]: Maybe<string | { code: number; value: string }>;
  };
}

const statusMapping: StatusMapping = {
  'at pickup': {
    aljex: 'At Shipper',
    turvo: { code: 2104, value: 'At pickup' },
    relay: 'Mark Arrived at Pickup',
    tai: null,
    mcleodenterprise: null, // TODO
  },
  loaded: {
    aljex: 'Loaded',
    turvo: { code: 2115, value: 'Picked up' },
    relay: 'Mark Loaded',
    tai: null,
    mcleodenterprise: null, // TODO
  },
  'in-transit': {
    aljex: 'In Transit',
    turvo: { code: 2105, value: 'En route' },
    relay: 'In Transit Update',
    tai: null,
    mcleodenterprise: null, // TODO
  },
  'at dropoff': {
    aljex: 'At Consignee',
    turvo: { code: 2106, value: 'At delivery' },
    relay: 'Mark Arrived at Delivery',
    tai: null,
    mcleodenterprise: null, // TODO
  },
  unloaded: {
    aljex: 'Delivered',
    turvo: { code: 2107, value: 'Delivered' },
    relay: 'Mark Delivered',
    tai: null,
    mcleodenterprise: null, // TODO
  },
};

const aljexStatusToLoadTimestampMapping: { [key: string]: string } = {
  'at shipper': 'Pickup Start Time',
  loaded: 'Pickup End Time',
  'at consignee': 'Delivery Start Time',
  delivered: 'Delivery End Time',
};

const aljexStatusEnums = [
  // Custom additional codes to support milestone events
  { value: 'At Consignee' },
  { value: 'At Shipper' },

  { value: 'Loaded' },
  { value: 'Delivered' },
  { value: 'Appt Needed/Change' },
  { value: 'Arrive Terminal' },
  { value: 'At Broker' },
  { value: 'Bad Info' },
  { value: 'Cons Closed' },
  { value: 'Custom Released' },
  { value: 'Customs Delay' },
  { value: 'Delay' },
  { value: 'Depart Terminal' },
  { value: 'Detention' },
  { value: 'Dot Shutdown' },
  { value: 'Empty Return' },
  { value: 'Holiday' },
  { value: 'In Transit' },
  { value: 'In-Gate' },
  { value: 'Labor' },
  { value: 'Left Message' },
  { value: 'Loaded Late' },
  { value: 'Margin Alert' },
  { value: 'Mech Breakdown' },
  { value: 'Notify' },
  { value: 'On Time' },
  { value: 'Os&D' },
  { value: 'Other' },
  { value: 'Out 4 Delivery' },
  { value: 'Out Gate' },
  { value: 'Refused' },
  { value: 'Routing' },
  { value: 'Running Late' },
  { value: 'Storage' },
  { value: 'Traffic' },
  { value: 'Undeliverable' },
  { value: 'Unidentified Freight' },
  { value: 'Weather Delays' },
];

const aljexReasonEnums = [
  { value: 'Accident' },
  { value: 'Alternate Carrier Delivered' },
  { value: 'Carrier Keying Error' },
  { value: 'Consignee - Related' },
  { value: 'Customer Requires Earlier Del.' },
  { value: 'Customer Requested Future Del' },
  { value: 'Failed To Call For Appt.' },
  { value: 'Hold Due To Customs' },
  { value: 'Held Per Customer' },
  { value: 'Industry Disruption' },
  { value: 'Incorrect Address' },
  { value: 'Mechincal Breakdown' },
  { value: 'Missed Delivery' },
  { value: 'Missed Pickup' },
  { value: 'No Req Arrival Date By Shipper' },
  { value: 'Normal Appt.' },
  { value: 'Normal Status' },
  { value: 'Overnite Related' },
  { value: 'Previous Stop' },
  { value: 'Recipient Unavailable - Delay' },
  { value: 'Refused By Recipient' },
  { value: 'Shipper Related' },
  { value: 'Unable To Locate' },
  { value: 'Weather Related' },
];

const relayStatusEnums = [
  { value: 'Dispatch Driver' },
  { value: 'Mark Loaded' },
  { value: 'In Transit Update' },
  { value: 'Mark Delivered' },
  { value: 'Add Tracking Note' },
  { value: 'Mark Arrived at Pickup' },
  { value: 'Mark Arrived at Delivery' },
];

const relayReasonEnums = [
  { value: 'Accident' },
  { value: 'Consignee Related' },
  { value: 'Driver Related' },
  { value: 'Mechanical Breakdown' },
  { value: 'Other Carrier Related' },
  { value: 'Previous Stop' },
  { value: 'Shipper Related' },
  { value: 'Holiday' },
  { value: 'Weather' },
  { value: 'Return To Shipper' },
  { value: 'Driver Not Available' },
  { value: 'Processing Delay' },
  { value: 'Trailer Not Available' },
  { value: 'Insufficient Time to Complete Delivery' },
];

const turvoStatusList = [
  { code: 2100, value: 'Quote active' },
  { code: 2101, value: 'Tendered' },
  { code: 2102, value: 'Covered' },
  { code: 2103, value: 'Dispatched' },
  { code: 2104, value: 'At pickup' },
  { code: 2115, value: 'Picked up' },
  { code: 2105, value: 'En route' },
  { code: 2106, value: 'At delivery' },
  { code: 2107, value: 'Delivered' },
  { code: 2116, value: 'Route Complete' },
  { code: 2108, value: 'Ready for billing' },
  { code: 2109, value: 'Processing' },
  { code: 2110, value: 'Carrier paid' },
  { code: 2111, value: 'Customer paid' },
  { code: 2112, value: 'Completed' },
  { code: 2113, value: 'Canceled' },
  { code: 2117, value: 'Tender - offered' },
  { code: 2118, value: 'Tender - accepted' },
  { code: 2119, value: 'Tender - rejected' },
  { code: 2114, value: 'Quote inactive' },
];

const tmsToStatusEnumsMap: { [key in TMS]: { value: string }[] } = {
  aljex: aljexStatusEnums,
  relay: relayStatusEnums,
  turvo: turvoStatusList,
  tai: [], // TODO
  mcleodenterprise: [], // TODO
};

const tmsToReasonEnumsMap: { [key in TMS]: { value: string }[] } = {
  aljex: aljexReasonEnums,
  relay: relayReasonEnums,
  turvo: [],
  tai: [],
  mcleodenterprise: [], // TODO
};

type CheckCallInputs = Omit<
  SubmitCheckCallProps,
  'isOnTime' | 'isException'
> & {
  isOnTime: 'Yes' | 'No';
  isException: 'Yes' | 'No';
};

function aljexIsMilestoneFunc(status: Maybe<string>): boolean {
  if (!status) {
    return false;
  }

  status = status.toLowerCase();

  return (
    status === 'at shipper' ||
    status === 'at consignee' ||
    status === 'loaded' ||
    status === 'delivered'
  );
}

function relayIsMilestoneFunc(status: Maybe<string>): boolean {
  if (!status) {
    return false;
  }

  status = status.toLowerCase();

  return (
    status.includes('mark arrived at') ||
    status.includes('loaded') ||
    status.includes('delivered')
  );
}

/**
 * A mapping of TMS names to their corresponding
 * functions that determine if a status represents a key milestone in the shipment process.
 *
 * The milestones are defined as:
 *  1. At pickup
 *  2. Loaded
 *  3. At dropoff
 *  4. Delivered
 *
 * Each function takes a TMS-specific status enum, which is a `Maybe<string>`, and returns `true` if the status
 * corresponds to one of the milestones, or `false` otherwise.
 *
 * @type {Object.<string, function(Maybe<string>): boolean>}
 */
const tmsToIsMilestoneMap: {
  [key in TMS]: Maybe<(status: Maybe<string>) => boolean>;
} = {
  [TMS.Aljex]: aljexIsMilestoneFunc,
  [TMS.Relay]: relayIsMilestoneFunc,
  [TMS.Turvo]: null, // TODO
  [TMS.Tai]: null,
  [TMS.McleodEnterprise]: null, // TODO
};

export const relaySourceEnums = [
  { value: CheckCallSource.Dispatcher },
  { value: CheckCallSource.Driver },
  { value: CheckCallSource.Shipper_Receiver },
  { value: CheckCallSource.Web },
];

type CheckCallTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<CheckCallInputs> };
const CheckCallTextInput = (props: CheckCallTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function CheckCallSection({
  normalizedLoad: load,
  suggestions,
  invalidateSuggestions,
}: CheckCallSectionProps) {
  useLogPostHogPageView(Pageview.CheckCalls);

  const { tmsName } = useTMSContext();
  const invalidateLoad = useLoadInvalidation();
  const isAljex = tmsName === TMS.Aljex;
  const isRelay = tmsName === TMS.Relay;
  const isTZAgnosticTMS = isAljex || isRelay;

  // TMS-specific variables
  const statusEnums = tmsToStatusEnumsMap[tmsName as TMS] || [];
  const reasonEnums = tmsToReasonEnumsMap[tmsName as TMS] || [];

  const isMilestoneFunc =
    tmsToIsMilestoneMap[tmsName as TMS] ||
    function (_: Maybe<string>): boolean {
      return false;
    };

  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [curSuggestion, setCurSuggestion] = useState<
    Maybe<CheckCallSuggestion>
  >(suggestions.length > 0 ? suggestions[suggestionIndex] : null);

  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(
    suggestions.length > 0 ? ['submitCheckCall'] : []
  );
  const [history, setHistory] = useState<Array<CheckCall>>([]);
  const [isMilestone, setIsMilestone] = useState<boolean>(
    isMilestoneFunc(curSuggestion?.status as string)
  );
  const [enableOutDatetimeField, setEnableOutDatetimeField] = useState(false);
  const [enableNextStopETAField, setEnableNextStopETAField] = useState(false);
  const [enableReasonField, setEnableReasonField] = useState(false);
  const [enableExceptionField, setEnableExceptionField] = useState(false);
  const [enableOnTimeField, setEnableOnTimeField] = useState(false);
  const [requireCityState, setRequireCityState] = useState(true);

  const fetchCheckCalls = async (loadID: number) => {
    const res = await getCheckCallHistory(loadID);
    if (res.isOk()) {
      setHistory(res.value ?? []);
    }
  };

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  // Collapsing sections and labelling fields after Email AI card is clicked
  useEffect(() => {
    if (!activeTabs.includes('submitCheckCall')) {
      setActiveTabs((oldTabs) => [...oldTabs, 'submitCheckCall']);
    }
  }, [clickedSuggestion]);

  useEffect(() => {
    if (load.ID) {
      fetchCheckCalls(load.ID);
    }
  }, [load.ID]);

  const getStatusFromMapping = (
    suggestedStatus: MaybeUndef<string>,
    integration: string
  ): Maybe<string> => {
    if (!suggestedStatus) {
      return null;
    }

    const mappedValue =
      statusMapping[suggestedStatus.toLowerCase()]?.[
        integration as keyof (typeof statusMapping)[string]
      ];

    if (typeof mappedValue === 'object') {
      return mappedValue?.value || null;
    } else if (typeof mappedValue === 'string') {
      return mappedValue;
    }

    return null;
  };

  const defaultValues = useMemo<CheckCallInputs>(() => {
    const parsedValues = {
      freightTrackingID: load.freightTrackingID,

      // NOTE: For dev, remove
      // city: 'Boston',
      // state: 'MA',
      // text: 'beep bop boop',
      // status: 'On Time',
      // reason: '',
      nextStopID: '',
      source: curSuggestion && isRelay ? CheckCallSource.Dispatcher : undefined,
      city: curSuggestion?.suggested.checkCallChanges.city || '',
      state: curSuggestion?.suggested.checkCallChanges.state || '',
      dateTime: curSuggestion?.suggested.checkCallChanges.timestamp
        ? normalizeDateForTMS(
            new Date(curSuggestion.suggested.checkCallChanges.timestamp)
          )
        : null,
      status:
        getStatusFromMapping(
          curSuggestion?.suggested.checkCallChanges.status,
          tmsName
        ) || '',
      reason: curSuggestion && tmsName === TMS.Aljex ? 'Normal Status' : '',
      notes: !(isMilestone && isAljex)
        ? curSuggestion?.suggested.checkCallChanges.notes || ''
        : '',
    };

    return parsedValues as CheckCallInputs;
  }, [load, curSuggestion]);

  const formMethods = useForm<CheckCallInputs>({ defaultValues });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    resetField,
  } = formMethods;

  // Set default date and reason if no suggestions without AI-filled label
  useEffect(() => {
    reset(defaultValues);

    if (defaultValues.dateTime === null) {
      setValue('dateTime', new Date());
    }

    if (!defaultValues.reason && isAljex) {
      setValue('reason', 'Normal Status');
    }
  }, [defaultValues]);

  // Reset suggestion and index when array of pending suggestions updated
  useEffect(() => {
    setSuggestionIndex(0);
    setCurSuggestion(suggestions[0]);
  }, [suggestions]);

  useEffect(() => {
    if (suggestionIndex >= 0 && suggestionIndex < suggestions.length) {
      setCurSuggestion(suggestions[suggestionIndex]);
    } else {
      setCurSuggestion(null);
    }
  }, [suggestionIndex]);

  const watchedStatus = watch('status');

  // Dynamically display/validate form inputs based on status pt. 1
  useEffect(() => {
    setIsMilestone(isMilestoneFunc(watchedStatus));
    const localEnableNextStop = showNextStopETAByTMS(tmsName, watchedStatus);
    setEnableNextStopETAField(localEnableNextStop);
    setEnableReasonField(showReasonFieldByTMS(tmsName, watchedStatus));
    setEnableExceptionField(showExceptionFieldByTMS(tmsName, watchedStatus));
    const localEnableOutDT = showOutDatetimeByTMS(tmsName, watchedStatus);
    setEnableOutDatetimeField(localEnableOutDT);
    if (localEnableOutDT) {
      setValue('outDatetimeWithoutTimezone', new Date());
    }

    // NFI Feature request: they first submit an "arrival at stop" CC, then "mark loaded/delivered",
    // which requires in and out datetimes. We AI-fill the in datetime based on the previous
    // "arrival at stop" timestamp
    if (isRelay) {
      // If dispatching driver, ETA is likely for pickup
      if (
        watchedStatus?.toLowerCase().includes('dispatch') &&
        localEnableNextStop
      ) {
        setValue('nextStopID', 'pickup');
      } else {
        setValue('nextStopID', '');
      }

      // If not "mark loaded" || "mark delivered", reset date and remove AI-fill
      if (!localEnableOutDT) {
        setValue('dateTime', new Date(), { shouldDirty: true });
        return;
      }
      const arrivedCC = history
        .filter(
          (call) =>
            call.status.toLowerCase() === 'arrival at stop' &&
            // If user clicked 'mark loaded', then search for pickup arrival (stop 1)
            // If user clicked 'mark delivered', then search for dropoff arrival (stop 2)
            call.city
              ?.toLowerCase()
              .includes(
                watchedStatus?.toLowerCase().includes('loaded')
                  ? 'stop 1'
                  : 'stop 2'
              )
        )
        .reduce((mostRecent: CheckCall | null, current: CheckCall) => {
          if (
            !mostRecent ||
            dayjs(current.dateTimeWithoutTimezone).isAfter(
              dayjs(mostRecent.dateTimeWithoutTimezone)
            )
          ) {
            return current;
          }
          return mostRecent;
        }, null);

      // If no previous "arrival at stop" found, reset date and remove AI-fill
      if (!arrivedCC || !dayjs(arrivedCC.dateTimeWithoutTimezone).isValid()) {
        setValue('dateTime', new Date(), { shouldDirty: true });
        return;
      }

      resetField('dateTime', {
        defaultValue: normalizeUTCDate(
          new Date(arrivedCC.dateTimeWithoutTimezone)
        ),
      });
    }
  }, [watchedStatus]);

  // Dynamically display/validate form inputs based on status pt. 2
  useEffect(() => {
    setRequireCityState(
      requireCityStateByTMS(tmsName, watchedStatus, isMilestone)
    );
    setEnableOnTimeField(
      showOnTimeFieldByTMS(tmsName, watchedStatus, isMilestone)
    );
  }, [isMilestone, watchedStatus]);

  const onSubmitCheckCall: SubmitHandler<CheckCallInputs> = async (data) => {
    setLoading(true);

    const cleanedData = data as unknown as SubmitCheckCallProps;

    const res = await submitCheckCall({
      ...cleanedData,
      isOnTime:
        data.isOnTime?.toLowerCase() === 'yes'
          ? true
          : data.isOnTime?.toLowerCase() === 'no'
            ? false
            : null,
      isException:
        data.isException?.toLowerCase() === 'yes'
          ? true
          : data.isException?.toLowerCase() === 'no'
            ? false
            : null,
      tmsName: tmsName,
      loadID: load.ID!,
      ID: 0,
      userTimezone: dayjs.tz.guess(),
      suggestion: curSuggestion,
    });

    if (res.isOk()) {
      if (res.value.history && res.value.history.length > 0) {
        setHistory(res.value.history);
      }
      toast({
        description: res.value.message || 'Successfully submitted check call',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    await invalidateSuggestions();
    // Invalidate load async so user sees updated data in Load Info tab
    invalidateLoad();
    setLoading(false);
  };

  const handleSkipSuggestion = async () => {
    setSkipLoading(true);

    const res = await skipSuggestion(curSuggestion!.id);

    if (res.isOk()) {
      toast({
        description: 'Suggestion skipped.',
        variant: 'default',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    await invalidateSuggestions();
    setSkipLoading(false);
  };

  return (
    <Accordion type='multiple' value={activeTabs} onValueChange={setActiveTabs}>
      <LoadSectionAccordionItem
        label='Submit Check Call'
        icon={<LocateFixed className='h-6 w-6' strokeWidth={1} />}
        name='submitCheckCall'
        activeTabs={activeTabs}
      >
        <div>
          {/* If there are suggestions, allow user to page through them */}
          {suggestions && suggestions.length > 0 && (
            <div className='flex justify-end mb-0'>
              <div className='flex flex-row items-center space-x-2'>
                <Button
                  type='button'
                  title='Previous Suggestion'
                  buttonNamePosthog={ButtonNamePosthog.CyleCheckCallSuggestion}
                  logProperties={{
                    loadID: load.ID,
                    freightTrackingID: load.freightTrackingID,
                    serviceID: load.serviceID,
                  }}
                  onClick={() =>
                    setSuggestionIndex(
                      suggestionIndex > 0
                        ? suggestionIndex - 1
                        : suggestions.length - 1
                    )
                  }
                >
                  <ChevronLeft className='h-4 w-4' />
                </Button>
                <Badge className='bg-sky-300 text-white hover:bg-sky-400'>{`${
                  suggestionIndex + 1
                }/${suggestions.length}`}</Badge>
                <Button
                  type='button'
                  title='Next Suggestion'
                  buttonNamePosthog={ButtonNamePosthog.CyleCheckCallSuggestion}
                  logProperties={{
                    loadID: load.ID,
                    freightTrackingID: load.freightTrackingID,
                    serviceID: load.serviceID,
                  }}
                  onClick={() =>
                    setSuggestionIndex(
                      suggestionIndex < suggestions.length - 1
                        ? suggestionIndex + 1
                        : 0
                    )
                  }
                >
                  <ChevronRight className='h-4 w-4' />
                </Button>
              </div>
            </div>
          )}

          {isMilestone && load.moreThanTwoStops && (
            <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-0'>
              <div>
                <Info
                  className='h-4 w-4 pl-1'
                  color='#969696'
                  strokeWidth={3}
                />
              </div>
              <span className='text-xs text-grayscale-content-3 font-medium'>
                {`For multi-stop loads, pickup/dropoff related check calls are supported only for the first pickup and last dropoff locations.
                More support for LTL loads coming soon!`}
              </span>
            </span>
          )}
          <ExtendedFormProvider aiDefaultValues={true}>
            <FormProvider {...formMethods}>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmitCheckCall)}
                  className='grid gap-4 grid-cols-1 mt-0 mx-0 w-full'
                >
                  {isRelay && (
                    <div>
                      <Label name='source' required>
                        Source
                      </Label>
                      <Controller
                        name='source'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {relaySourceEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {titleCase(option.value)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`source`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {statusEnums.length > 0 && (
                    <div>
                      <Label name='status' required>
                        Status
                      </Label>
                      <Controller
                        name='status'
                        control={control}
                        rules={{
                          required:
                            statusEnums.length > 0 ? 'Required' : undefined,
                        }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-1'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {statusEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`status`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {requireCityState && (
                    <CheckCallTextInput
                      name='city'
                      label='City'
                      required={requireCityState}
                      readOnly={!requireCityState}
                    />
                  )}

                  {requireCityState && (
                    <CheckCallTextInput
                      name='state'
                      label='State'
                      required={requireCityState}
                      readOnly={!requireCityState}
                    />
                  )}

                  <DateTimeInput
                    name='dateTime'
                    label={`${enableOutDatetimeField ? 'In ' : ''}Date and Time`}
                    control={control}
                    required
                    preventNormalizedLabelTZ={isTZAgnosticTMS}
                  />

                  {enableOutDatetimeField && (
                    <DateTimeInput
                      name='outDatetimeWithoutTimezone'
                      label='Out Date and Time'
                      control={control}
                      preventNormalizedLabelTZ={isTZAgnosticTMS}
                    />
                  )}

                  {isAljex && isMilestone && (
                    <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mt-0'>
                      <div>
                        <Info
                          className='h-4 w-4 pl-1'
                          color='#969696'
                          strokeWidth={3}
                        />
                      </div>
                      <span className='text-xs text-grayscale-content-3 font-medium'>
                        {`This updates ${
                          aljexStatusToLoadTimestampMapping[
                            getValues('status')?.toLowerCase()
                          ]
                        }.`}
                      </span>
                    </span>
                  )}

                  {enableNextStopETAField && (
                    <div>
                      <Label name='nextStopID' required>
                        {'ETA for Stop'}
                      </Label>
                      <Controller
                        name='nextStopID'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {/* TODO: Support multi-stop loads */}
                              <SelectItem key={'pickup'} value={'pickup'}>
                                {`Pickup (${load.pickup.name}, ${load.pickup.addressLine1}, ${load.pickup.city}, ${load.pickup.state})`}
                              </SelectItem>
                              <SelectItem key={'dropoff'} value={'dropoff'}>
                                {`Dropoff (${load.consignee.name}, ${load.consignee.addressLine1}, ${load.consignee.city}, ${load.consignee.state})`}
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`nextStopID`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {enableNextStopETAField && (
                    <DateTimeInput
                      name='nextStopETAWithoutTimezone'
                      label='Next Stop ETA'
                      control={control}
                      required
                      preventNormalizedLabelTZ={isTZAgnosticTMS}
                    />
                  )}

                  {enableOnTimeField && (
                    <div>
                      <Label name='isOnTime'>{'On Time?'}</Label>
                      <Controller
                        name='isOnTime'
                        control={control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem key={'yes'} value={'yes'}>
                                Yes
                              </SelectItem>
                              <SelectItem key={'no'} value={'no'}>
                                No
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`isOnTime`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {enableReasonField && (
                    <div>
                      <Label
                        name={'reason'}
                        required={!isRelay}
                      >{`${isAljex ? 'EDI Reason' : isRelay ? 'Late Reason' : 'Reason'}`}</Label>
                      <Controller
                        name='reason'
                        control={control}
                        rules={{
                          required: !isRelay ? 'Required' : undefined,
                        }}
                        render={({ field }) => (
                          // TODO: allow user to type and autofill
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                            disabled={isMilestone && isAljex}
                          >
                            <SelectTrigger className='w-full mt-1'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              {reasonEnums.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`reason`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  {enableExceptionField && (
                    <div>
                      <Label name='isException' required>
                        {'Is Exception?'}
                      </Label>
                      <Controller
                        name='isException'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className='w-full mt-2'>
                              <SelectValue placeholder='Choose' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem key={'yes'} value={'yes'}>
                                Yes
                              </SelectItem>
                              <SelectItem key={'no'} value={'no'}>
                                No
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`isException`}
                        render={({ message }: { message: string }) => (
                          <p className='text-red-500 text-xs'>{message}</p>
                        )}
                      />
                    </div>
                  )}

                  <CheckCallTextInput
                    name='notes'
                    label='Notes'
                    readOnly={isMilestone && isAljex}
                  />
                  <Button
                    buttonName={ButtonName.SubmitCheckCall}
                    buttonNamePosthog={ButtonNamePosthog.SubmitCheckCall}
                    type='submit'
                    className='w-full'
                    disabled={loading || skipLoading}
                    logProperties={{
                      loadID: load.ID,
                      freightTrackingID: load.freightTrackingID,
                      serviceID: load.serviceID,
                    }}
                  >
                    {loading ? <ButtonLoader /> : ButtonName.SubmitCheckCall}
                  </Button>

                  {curSuggestion && (
                    <Button
                      buttonName={ButtonName.SkipCheckCallSuggestion}
                      buttonNamePosthog={
                        ButtonNamePosthog.SkipCheckCallSuggestion
                      }
                      type='button'
                      className='w-full'
                      variant='outline'
                      disabled={loading || skipLoading}
                      onClick={handleSkipSuggestion}
                      logProperties={{
                        loadID: load.ID,
                        freightTrackingID: load.freightTrackingID,
                        serviceID: load.serviceID,
                      }}
                    >
                      {skipLoading ? <ButtonLoader /> : 'Skip Suggestion'}
                    </Button>
                  )}
                </form>
              </div>
            </FormProvider>
          </ExtendedFormProvider>
        </div>
      </LoadSectionAccordionItem>

      <LoadSectionAccordionItem
        label='Check Call History'
        icon={<RouteIcon className='h-6 w-6' strokeWidth={1} />}
        name='checkCallHistory'
        activeTabs={activeTabs}
      >
        {history ? <Timeline events={history} /> : null}
      </LoadSectionAccordionItem>
    </Accordion>
  );
}

function showOutDatetimeByTMS(tmsName: string, status: string): boolean {
  switch (tmsName) {
    case TMS.Relay:
      return (
        status.toLowerCase() == 'mark loaded' ||
        status.toLowerCase() == 'mark delivered'
      );

    default:
      return false;
  }
}

function showNextStopETAByTMS(tmsName: string, status: string): boolean {
  switch (tmsName) {
    case TMS.Relay: {
      const s = status?.toLowerCase();
      return s.includes('in transit') || s.includes('dispatch');
    }

    default:
      return false;
  }
}

function showReasonFieldByTMS(tmsName: string, status: string): boolean {
  switch (tmsName) {
    case TMS.Aljex:
      return true;

    case TMS.Relay: {
      const s = status?.toLowerCase();
      return !s.includes('note') && !s.includes('dispatch');
    }

    case TMS.Turvo:
      return false;

    default:
      return false;
  }
}

function requireCityStateByTMS(
  tmsName: string,
  status: string,
  isMilestone: boolean
): boolean {
  switch (tmsName) {
    case TMS.Aljex:
      return !isMilestone;

    case TMS.Relay:
      return !isMilestone && !status?.toLowerCase().includes('note');

    case TMS.Turvo:
      return !isMilestone;

    case TMS.Tai:
      return true;

    default:
      return false;
  }
}

function showExceptionFieldByTMS(tmsName: string, status: string): boolean {
  switch (tmsName) {
    case TMS.Relay:
      return status?.toLowerCase().includes('note');

    default:
      return false;
  }
}

function showOnTimeFieldByTMS(
  tmsName: string,
  status: string,
  isMilestone: boolean
): boolean {
  switch (tmsName) {
    case TMS.Relay:
      return isMilestone || status.toLowerCase().includes('note');

    default:
      return false;
  }
}
