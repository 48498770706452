import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

type QuoteConfidenceLevelProps = {
  confidence: number;
  lowConfidenceThreshold: number;
  mediumConfidenceThreshold: number;
};

export default function QuoteConfidenceLevel({
  confidence,
  lowConfidenceThreshold,
  mediumConfidenceThreshold,
}: QuoteConfidenceLevelProps): JSX.Element {
  const lowConfidence = lowConfidenceThreshold ?? 70;
  const mediumConfidence = mediumConfidenceThreshold ?? 80;

  return (
    <TooltipProvider>
      {confidence >= mediumConfidence ? (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='text-green-main'>{confidence}%</div>
          </TooltipTrigger>
          <TooltipContent>High Confidence</TooltipContent>
        </Tooltip>
      ) : confidence < mediumConfidence && confidence >= lowConfidence ? (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='text-yellow-main'>{confidence}%</div>
          </TooltipTrigger>
          <TooltipContent>Medium Confidence</TooltipContent>
        </Tooltip>
      ) : (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='text-red-main'>{confidence}%</div>
          </TooltipTrigger>
          <TooltipContent>Low Confidence</TooltipContent>
        </Tooltip>
      )}
    </TooltipProvider>
  );
}
