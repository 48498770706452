import { Maybe } from 'types/UtilityTypes';

export default function getElementByXPath(
  xpath: string,
  context: Document | HTMLElement = document
): Maybe<HTMLElement> {
  const result = document.evaluate(
    xpath,
    context,
    null,
    // Deep searches the DOM structure from until it finds a match for the exact baseXPath we've declared
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  );

  return result.singleNodeValue as HTMLElement | null;
}
