import { useContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ServiceContext } from 'contexts/serviceContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { Email } from 'types/Email';
import { Truck, TruckListErrors } from 'types/Truck';
import { Maybe } from 'types/UtilityTypes';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { TruckListCarrier } from 'types/suggestions/TruckListSuggestions';
import { isValidNonDateObject } from 'utils/isValidObject';

import RedwoodTruckListForm from './Redwood/RedwoodTruckListForm';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export enum TruckType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}

type TruckListSectionProps = {
  email: Maybe<Email>;
};

export default function TruckListSection({ email }: TruckListSectionProps) {
  const [serviceName, setServiceName] = useState<string>('');

  const [truckList, setTruckList] = useState<Truck[]>([]);
  const [trucksByDate, setTrucksByDate] = useState<[string, Truck[]][]>([]);

  const [truckListErrors, setTruckListErrors] =
    useState<Maybe<TruckListErrors>>();
  const [truckListCarrierInfo, setTruckListCarrierInfo] =
    useState<TruckListCarrier>();

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  useEffect(() => {
    if (clickedSuggestion?.pipeline !== SuggestionPipelines.TruckList) return;

    const truckListSuggestion = clickedSuggestion.suggested;

    const flattenedTrucks = truckListSuggestion.trucks.map((truck) => ({
      id: truck.id,
      tmpId: uuidv4(),
      pickupLocation: truck.pickupLocation.suggestion,
      pickupDate: truck.pickupDate.suggestion,
      dropoffLocation: truck.dropoffLocation.suggestion,
      dropoffDate: truck.dropoffDate.suggestion,
      type: truck.type.suggestion,
      length: truck.length.suggestion,
    }));

    setServiceName(truckListSuggestion.serviceName);
    setTruckList(flattenedTrucks);
    setTruckListErrors(truckListSuggestion.errors);
    setTruckListCarrierInfo(truckListSuggestion.carrier);
  }, [clickedSuggestion]);

  useMemo(() => {
    const groupedTrucks = truckList.reduce(
      (acc, truck) => {
        const date = truck.pickupDate
          ? dayjs.utc(truck.pickupDate).format('YYYY-MM-DD')
          : 'No pickup date found';
        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(truck);
        return acc;
      },
      {} as { [key: string]: Truck[] }
    );

    setTrucksByDate(
      Object.entries(groupedTrucks).sort((a, b) => a[0].localeCompare(b[0]))
    );
  }, [truckList]);

  const { serviceID } = useContext(ServiceContext);
  // Redwood doesn't use dropoff date time input so we need to hide it for now.
  const isRedwoodService = serviceID === 529;

  return isRedwoodService ? (
    <RedwoodTruckListForm
      email={email}
      serviceName={serviceName}
      trucksByDate={trucksByDate}
      setTrucksByDate={setTrucksByDate}
      truckListErrors={truckListErrors}
      setTruckListErrors={setTruckListErrors}
      truckListCarrierInfo={truckListCarrierInfo}
    />
  ) : (
    <h3 className='flex justify-center py-5 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-grayscale-content-3'>
      Invalid Truck List service
    </h3>
  );
}

export const createEmptyTruck = (date: string): Truck => ({
  tmpId: uuidv4(),
  pickupLocation: {
    city: '',
    state: '',
  },
  // TODO: add proper timezone parsing
  pickupDate: `${date}T00:00:00.000000-00:00`,
  dropoffLocation: {
    city: '',
    state: '',
  },
  dropoffDate: '',
  type: TruckType.VAN,
  length: 0,
});

export const getTruckIDByTmpID = (
  trucksByDate: [string, Truck[]][],
  tmpID: string
) => {
  const flattenedTrucks = _.chain(trucksByDate).flatten().flatten().value();

  const trucks = flattenedTrucks.filter((t) =>
    isValidNonDateObject(t)
  ) as Truck[];
  return trucks.find((t) => t.tmpId === tmpID)?.id;
};
