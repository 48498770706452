import { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { SidebarStateContext } from 'contexts/sidebarStateContext';

type FetchStarredLoadsResponse = {
  starredLoads: string[];
};

export default async function useFetchStarredLoads(): Promise<void> {
  const { setCurrentState } = useContext(SidebarStateContext);

  const axiosRes = await axios.get<FetchStarredLoadsResponse>(`load/starred`);
  const {
    data: { starredLoads },
  } = axiosRes;

  setCurrentState((prevState) => ({
    ...prevState,
    starredLoads: starredLoads ?? [],
  }));
}
