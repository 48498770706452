import { CompanyCoreInfo } from 'types/Load';

export function titleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function toSentenceCase(input: string): string {
  if (!input) return '';
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export function concatAddress(addr: CompanyCoreInfo): string {
  if (!addr) return '';

  const parts = [
    addr.name,
    addr.addressLine1,
    addr.addressLine2,
    addr.city,
    addr.state,
    addr.zipCode,
  ].filter(Boolean); // Filter out empty strings

  return parts.join(', ');
}
