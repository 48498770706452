import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';
import captureException from 'utils/captureException';

export async function getLoadBuildingSuggestion(
  threadID: string
): Promise<Result<LoadBuildingSuggestions[], ApiError>> {
  try {
    const response = await axios.get<LoadBuildingSuggestions[]>(
      `thread/${encodeURIComponent(threadID)}/suggestions/loadBuilding`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getLoadSuggestion' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get load building suggestions' });
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to get load building suggestions',
    });
  }
}
