// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import AuthService from '@auth/AuthService';

export default function hasValidUserAuth(): Promise<boolean> {
  const validateUserAuth = async (): Promise<boolean> =>
    await AuthService.hasValidUserAuth();

  return validateUserAuth();
}
