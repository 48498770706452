import { AvailableTabs } from 'constants/SidebarTabs';
import { Dropoff, NormalizedLoad, Pickup } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import getElementByXPath from 'utils/htmlParse';

import { HostInterface } from './interface';

export class Relay implements HostInterface {
  getFieldMoniker(key: keyof NormalizedLoad): string {
    switch (key) {
      case 'externalTMSID':
        return 'Relay Load #';
      case 'freightTrackingID':
        return 'Booking ID';
      default:
        return '';
    }
  }

  // FreightTrackingID = Booking ID for Relay
  parseFreightTrackingID(): string {
    const url = location.href;

    switch (true) {
      case url.includes('load_board_load_detail'): {
        return (
          document
            .querySelector('.text-info.pl-3.d-inline')
            ?.textContent?.trim() ?? ''
        );
      }

      case url.includes('tracking_load_detail'): {
        const match = url.match(/(\d{7})/);

        if (match) {
          return match[1];
        }
        break;
      }

      case url.includes('hub'): {
        const header = document.querySelector(
          'tr[data-phx-id="c1-load-signature"][data-phx-component="1"]'
        );
        const numElement =
          header?.querySelector('span.tw-text-gray-500.tw-text-xs') ??
          header?.querySelector('span:first-of-type');

        return numElement?.textContent?.trim() ?? '';
      }
    }

    // Planning Board modals include only Relay Ref # and Customer Ref/BOL
    return '';
  }

  // ExternalTMSID = Load # for Relay
  parseExternalTMSID(): string {
    const url = location.href;

    switch (true) {
      // URLs are in the form of:
      //  * https://{training.}relaytms.com/sourcing/load_board_load_detail/{(2|3)\d{6}}
      //  * https://{training.}relaytms.com/planning_board/stop_management/{(2|3)\d{6}}
      case url.includes('load_board_load_detail') ||
        url.includes('planning_board/stop_management'): {
        const match = url.match(/((2|3)\d{6})/);
        if (match) {
          return match[1];
        }
        break;
      }

      // If SchedulePlan or another modal opened; URLs are in the form of:
      //  * https://training.relaytms.com/planning_board/
      //  * https://training.relaytms.com/planning_board/{filteredCustomerName}
      case url.includes('planning_board'): {
        const elements = document.getElementsByClassName('col load-number');
        if (elements.length === 0) {
          return '';
        }

        const element = elements[0] as HTMLElement;

        return element?.innerHTML?.trim() ?? '';
      }

      case url.includes('tracking_load_detail'): {
        return (
          document
            .querySelector('.relay-reference-number.d-inline')
            ?.textContent?.trim() ?? ''
        );
      }

      case url.includes('hub'): {
        const match = url.match(/((2|3)\d{6})/);
        if (match) {
          return match[1];
        }
      }
    }

    return '';
  }

  determineDefaultLoadTab(): AvailableTabs {
    switch (true) {
      case location.href.includes('planning_board') ||
        location.href.includes('load_board_load_detail'):
        return AvailableTabs.AppointmentScheduling;

      case location.href.includes('tracking_board'):
        return AvailableTabs.TrackAndTrace;

      case location.href.includes('tracking_load_detail'):
        return AvailableTabs.TrackAndTrace;

      default:
        return AvailableTabs.LoadInformation;
    }
  }

  shouldAutomaticallyOpenDrumkit(): boolean {
    return (
      location.href.includes('planning_board') ||
      location.href.includes('load_board_load_detail') ||
      location.href.includes('tracking_load_detail')
    );
  }

  parsePickup(): Maybe<Pickup> {
    const pickupBaseXPath =
      '/html/body/div/main/div/main/div/div/div/div/form/div/div/div/div/div/div/';

    const res = {} as Pickup;

    res.name =
      getElementByXPath(pickupBaseXPath + 'div[1]')?.innerHTML?.trim() ?? '';

    res.addressLine1 =
      getElementByXPath(pickupBaseXPath + 'div[2]')?.innerHTML?.trim() ?? '';

    const cityState =
      getElementByXPath(pickupBaseXPath + 'div[3]')
        ?.innerHTML?.trim()
        ?.split(',') ?? '';

    if (cityState.length > 0) {
      res.city = cityState[0].trim();
    }
    if (cityState.length > 1) {
      res.state = cityState[1].trim();
    }

    res.phone =
      getElementByXPath(pickupBaseXPath + 'div[4]')?.innerHTML?.trim() ?? '';

    res.refNumber =
      document
        .getElementById('schedule-plan-form_stops_0_reference')
        ?.getAttribute('value')
        ?.trim() ?? '';

    return res;
  }

  parseDropoff(): Maybe<Dropoff> {
    const dropoffBaseXPath =
      '/html/body/div/main/div/main/div/div[2]/div/div/form/div[1]/div/div[2]/div/div[4]/div/';

    const res = {} as Dropoff;

    res.name =
      getElementByXPath(dropoffBaseXPath + 'div[1]')?.innerHTML?.trim() ?? '';

    res.addressLine1 =
      getElementByXPath(dropoffBaseXPath + 'div[2]')?.innerHTML?.trim() ?? '';

    const cityState =
      getElementByXPath(dropoffBaseXPath + 'div[3]')
        ?.innerHTML?.trim()
        ?.split(',') ?? '';

    if (cityState.length > 0) {
      res.city = cityState[0];
    }
    if (cityState.length > 1) {
      res.state = cityState[1];
    }

    res.phone =
      getElementByXPath(dropoffBaseXPath + 'div[4]')?.innerHTML?.trim() ?? '';

    res.refNumber =
      document
        .getElementById('schedule-plan-form_stops_1_reference')
        ?.getAttribute('value')
        ?.trim() ?? '';

    return res;
  }
}
