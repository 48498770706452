import * as React from 'react';

export default function GreenscreensLogo(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='224'
      height='36'
      viewBox='0 0 224 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.12403 9.28395C10.1467 9.28395 11.0394 9.49195 11.802 9.90795C12.582 10.324 13.18 10.87 13.596 11.546V9.49195H18.042V23.974C18.042 25.3086 17.7734 26.5133 17.236 27.588C16.716 28.68 15.91 29.5466 14.818 30.188C13.7434 30.8293 12.4 31.15 10.788 31.15C8.6387 31.15 6.89669 30.6386 5.56203 29.616C4.22736 28.6106 3.46469 27.2413 3.27403 25.508H7.66803C7.80669 26.0626 8.13603 26.496 8.65603 26.808C9.17603 27.1373 9.81736 27.302 10.58 27.302C11.4987 27.302 12.2267 27.0333 12.764 26.496C13.3187 25.976 13.596 25.1353 13.596 23.974V21.92C13.1627 22.596 12.5647 23.1506 11.802 23.584C11.0394 24 10.1467 24.208 9.12403 24.208C7.92803 24.208 6.84469 23.9046 5.87403 23.298C4.90336 22.674 4.13203 21.7986 3.56003 20.672C3.00536 19.528 2.72803 18.2106 2.72803 16.72C2.72803 15.2293 3.00536 13.9206 3.56003 12.794C4.13203 11.6673 4.90336 10.8006 5.87403 10.194C6.84469 9.58728 7.92803 9.28395 9.12403 9.28395ZM13.596 16.746C13.596 15.6366 13.284 14.7613 12.66 14.12C12.0534 13.4786 11.308 13.158 10.424 13.158C9.54003 13.158 8.78603 13.4786 8.16203 14.12C7.55536 14.744 7.25203 15.6106 7.25203 16.72C7.25203 17.8293 7.55536 18.7133 8.16203 19.372C8.78603 20.0133 9.54003 20.334 10.424 20.334C11.308 20.334 12.0534 20.0133 12.66 19.372C13.284 18.7306 13.596 17.8553 13.596 16.746Z'
        fill='#212121'
      />
      <path
        d='M25.9645 11.91C26.4845 11.1126 27.1345 10.4886 27.9145 10.038C28.6945 9.56995 29.5612 9.33595 30.5145 9.33595V14.042H29.2925C28.1832 14.042 27.3512 14.2846 26.7965 14.77C26.2418 15.238 25.9645 16.07 25.9645 17.266V24H21.5185V9.49195H25.9645V11.91Z'
        fill='#212121'
      />
      <path
        d='M46.5756 16.512C46.5756 16.928 46.5496 17.3613 46.4976 17.812H36.4356C36.5049 18.7133 36.7909 19.4066 37.2936 19.892C37.8136 20.36 38.4463 20.594 39.1916 20.594C40.3009 20.594 41.0723 20.126 41.5056 19.19H46.2376C45.9949 20.1433 45.5529 21.0013 44.9116 21.764C44.2876 22.5266 43.4989 23.1246 42.5456 23.558C41.5923 23.9913 40.5263 24.208 39.3476 24.208C37.9263 24.208 36.6609 23.9046 35.5516 23.298C34.4423 22.6913 33.5756 21.8246 32.9516 20.698C32.3276 19.5713 32.0156 18.254 32.0156 16.746C32.0156 15.238 32.3189 13.9206 32.9256 12.794C33.5496 11.6673 34.4163 10.8006 35.5256 10.194C36.6349 9.58728 37.9089 9.28395 39.3476 9.28395C40.7516 9.28395 41.9996 9.57862 43.0916 10.168C44.1836 10.7573 45.0329 11.598 45.6396 12.69C46.2636 13.782 46.5756 15.056 46.5756 16.512ZM42.0256 15.342C42.0256 14.5793 41.7656 13.9726 41.2456 13.522C40.7256 13.0713 40.0756 12.846 39.2956 12.846C38.5503 12.846 37.9176 13.0626 37.3976 13.496C36.8949 13.9293 36.5829 14.5446 36.4616 15.342H42.0256Z'
        fill='#212121'
      />
      <path
        d='M62.8571 16.512C62.8571 16.928 62.8311 17.3613 62.7791 17.812H52.7171C52.7864 18.7133 53.0724 19.4066 53.5751 19.892C54.0951 20.36 54.7278 20.594 55.4731 20.594C56.5824 20.594 57.3538 20.126 57.7871 19.19H62.5191C62.2764 20.1433 61.8344 21.0013 61.1931 21.764C60.5691 22.5266 59.7804 23.1246 58.8271 23.558C57.8738 23.9913 56.8078 24.208 55.6291 24.208C54.2078 24.208 52.9424 23.9046 51.8331 23.298C50.7238 22.6913 49.8571 21.8246 49.2331 20.698C48.6091 19.5713 48.2971 18.254 48.2971 16.746C48.2971 15.238 48.6004 13.9206 49.2071 12.794C49.8311 11.6673 50.6978 10.8006 51.8071 10.194C52.9164 9.58728 54.1904 9.28395 55.6291 9.28395C57.0331 9.28395 58.2811 9.57862 59.3731 10.168C60.4651 10.7573 61.3144 11.598 61.9211 12.69C62.5451 13.782 62.8571 15.056 62.8571 16.512ZM58.3071 15.342C58.3071 14.5793 58.0471 13.9726 57.5271 13.522C57.0071 13.0713 56.3571 12.846 55.5771 12.846C54.8318 12.846 54.1991 13.0626 53.6791 13.496C53.1764 13.9293 52.8644 14.5446 52.7431 15.342H58.3071Z'
        fill='#212121'
      />
      <path
        d='M74.3026 9.33595C76.0013 9.33595 77.3532 9.89062 78.3586 11C79.3812 12.092 79.8926 13.6 79.8926 15.524V24H75.4726V16.122C75.4726 15.1513 75.2213 14.3973 74.7186 13.86C74.2159 13.3226 73.5399 13.054 72.6906 13.054C71.8412 13.054 71.1652 13.3226 70.6626 13.86C70.1599 14.3973 69.9086 15.1513 69.9086 16.122V24H65.4626V9.49195H69.9086V11.416C70.3592 10.7746 70.9659 10.272 71.7286 9.90795C72.4912 9.52662 73.3493 9.33595 74.3026 9.33595Z'
        fill='#212121'
      />
      <path
        d='M89.1441 24.208C87.8788 24.208 86.7521 23.9913 85.7641 23.558C84.7761 23.1246 83.9961 22.5353 83.4241 21.79C82.8521 21.0273 82.5314 20.178 82.4621 19.242H86.8561C86.9081 19.7446 87.1421 20.152 87.5581 20.464C87.9741 20.776 88.4854 20.932 89.0921 20.932C89.6468 20.932 90.0714 20.828 90.3661 20.62C90.6781 20.3946 90.8341 20.1086 90.8341 19.762C90.8341 19.346 90.6174 19.0426 90.1841 18.852C89.7508 18.644 89.0488 18.4186 88.0781 18.176C87.0381 17.9333 86.1714 17.682 85.4781 17.422C84.7848 17.1446 84.1868 16.72 83.6841 16.148C83.1814 15.5586 82.9301 14.77 82.9301 13.782C82.9301 12.95 83.1554 12.196 83.6061 11.52C84.0741 10.8266 84.7501 10.2806 85.6341 9.88195C86.5354 9.48328 87.6014 9.28395 88.8321 9.28395C90.6521 9.28395 92.0821 9.73462 93.1221 10.636C94.1795 11.5373 94.7861 12.7333 94.9421 14.224H90.8341C90.7648 13.7213 90.5394 13.3226 90.1581 13.028C89.7941 12.7333 89.3088 12.586 88.7021 12.586C88.1821 12.586 87.7834 12.69 87.5061 12.898C87.2288 13.0886 87.0901 13.3573 87.0901 13.704C87.0901 14.12 87.3068 14.432 87.7401 14.64C88.1908 14.848 88.8841 15.056 89.8201 15.264C90.8948 15.5413 91.7701 15.8186 92.4461 16.096C93.1221 16.356 93.7114 16.7893 94.2141 17.396C94.7341 17.9853 95.0028 18.7826 95.0201 19.788C95.0201 20.6373 94.7775 21.4 94.2921 22.076C93.8241 22.7346 93.1395 23.2546 92.2381 23.636C91.3541 24.0173 90.3228 24.208 89.1441 24.208Z'
        fill='#212121'
      />
      <path
        d='M97.1161 16.746C97.1161 15.238 97.4195 13.9206 98.0262 12.794C98.6502 11.6673 99.5081 10.8006 100.6 10.194C101.709 9.58728 102.975 9.28395 104.396 9.28395C106.216 9.28395 107.733 9.76062 108.946 10.714C110.177 11.6673 110.983 13.0106 111.364 14.744H106.632C106.233 13.6346 105.462 13.08 104.318 13.08C103.503 13.08 102.853 13.4006 102.368 14.042C101.883 14.666 101.64 15.5673 101.64 16.746C101.64 17.9246 101.883 18.8346 102.368 19.476C102.853 20.1 103.503 20.412 104.318 20.412C105.462 20.412 106.233 19.8573 106.632 18.748H111.364C110.983 20.4466 110.177 21.7813 108.946 22.752C107.715 23.7226 106.199 24.208 104.396 24.208C102.975 24.208 101.709 23.9046 100.6 23.298C99.5081 22.6913 98.6502 21.8246 98.0262 20.698C97.4195 19.5713 97.1161 18.254 97.1161 16.746Z'
        fill='#212121'
      />
      <path
        d='M118.448 11.91C118.968 11.1126 119.618 10.4886 120.398 10.038C121.178 9.56995 122.045 9.33595 122.998 9.33595V14.042H121.776C120.667 14.042 119.835 14.2846 119.28 14.77C118.726 15.238 118.448 16.07 118.448 17.266V24H114.002V9.49195H118.448V11.91Z'
        fill='#212121'
      />
      <path
        d='M139.059 16.512C139.059 16.928 139.033 17.3613 138.981 17.812H128.919C128.989 18.7133 129.275 19.4066 129.777 19.892C130.297 20.36 130.93 20.594 131.675 20.594C132.785 20.594 133.556 20.126 133.989 19.19H138.721C138.479 20.1433 138.037 21.0013 137.395 21.764C136.771 22.5266 135.983 23.1246 135.029 23.558C134.076 23.9913 133.01 24.208 131.831 24.208C130.41 24.208 129.145 23.9046 128.035 23.298C126.926 22.6913 126.059 21.8246 125.435 20.698C124.811 19.5713 124.499 18.254 124.499 16.746C124.499 15.238 124.803 13.9206 125.409 12.794C126.033 11.6673 126.9 10.8006 128.009 10.194C129.119 9.58728 130.393 9.28395 131.831 9.28395C133.235 9.28395 134.483 9.57862 135.575 10.168C136.667 10.7573 137.517 11.598 138.123 12.69C138.747 13.782 139.059 15.056 139.059 16.512ZM134.509 15.342C134.509 14.5793 134.249 13.9726 133.729 13.522C133.209 13.0713 132.559 12.846 131.779 12.846C131.034 12.846 130.401 13.0626 129.881 13.496C129.379 13.9293 129.067 14.5446 128.945 15.342H134.509Z'
        fill='#212121'
      />
      <path
        d='M155.341 16.512C155.341 16.928 155.315 17.3613 155.263 17.812H145.201C145.27 18.7133 145.556 19.4066 146.059 19.892C146.579 20.36 147.212 20.594 147.957 20.594C149.066 20.594 149.838 20.126 150.271 19.19H155.003C154.76 20.1433 154.318 21.0013 153.677 21.764C153.053 22.5266 152.264 23.1246 151.311 23.558C150.358 23.9913 149.292 24.208 148.113 24.208C146.692 24.208 145.426 23.9046 144.317 23.298C143.208 22.6913 142.341 21.8246 141.717 20.698C141.093 19.5713 140.781 18.254 140.781 16.746C140.781 15.238 141.084 13.9206 141.691 12.794C142.315 11.6673 143.182 10.8006 144.291 10.194C145.4 9.58728 146.674 9.28395 148.113 9.28395C149.517 9.28395 150.765 9.57862 151.857 10.168C152.949 10.7573 153.798 11.598 154.405 12.69C155.029 13.782 155.341 15.056 155.341 16.512ZM150.791 15.342C150.791 14.5793 150.531 13.9726 150.011 13.522C149.491 13.0713 148.841 12.846 148.061 12.846C147.316 12.846 146.683 13.0626 146.163 13.496C145.66 13.9293 145.348 14.5446 145.227 15.342H150.791Z'
        fill='#212121'
      />
      <path
        d='M166.786 9.33595C168.485 9.33595 169.837 9.89062 170.842 11C171.865 12.092 172.376 13.6 172.376 15.524V24H167.956V16.122C167.956 15.1513 167.705 14.3973 167.202 13.86C166.7 13.3226 166.024 13.054 165.174 13.054C164.325 13.054 163.649 13.3226 163.146 13.86C162.644 14.3973 162.392 15.1513 162.392 16.122V24H157.946V9.49195H162.392V11.416C162.843 10.7746 163.45 10.272 164.212 9.90795C164.975 9.52662 165.833 9.33595 166.786 9.33595Z'
        fill='#212121'
      />
      <path
        d='M181.628 24.208C180.363 24.208 179.236 23.9913 178.248 23.558C177.26 23.1246 176.48 22.5353 175.908 21.79C175.336 21.0273 175.015 20.178 174.946 19.242H179.34C179.392 19.7446 179.626 20.152 180.042 20.464C180.458 20.776 180.969 20.932 181.576 20.932C182.131 20.932 182.555 20.828 182.85 20.62C183.162 20.3946 183.318 20.1086 183.318 19.762C183.318 19.346 183.101 19.0426 182.668 18.852C182.235 18.644 181.533 18.4186 180.562 18.176C179.522 17.9333 178.655 17.682 177.962 17.422C177.269 17.1446 176.671 16.72 176.168 16.148C175.665 15.5586 175.414 14.77 175.414 13.782C175.414 12.95 175.639 12.196 176.09 11.52C176.558 10.8266 177.234 10.2806 178.118 9.88195C179.019 9.48328 180.085 9.28395 181.316 9.28395C183.136 9.28395 184.566 9.73462 185.606 10.636C186.663 11.5373 187.27 12.7333 187.426 14.224H183.318C183.249 13.7213 183.023 13.3226 182.642 13.028C182.278 12.7333 181.793 12.586 181.186 12.586C180.666 12.586 180.267 12.69 179.99 12.898C179.713 13.0886 179.574 13.3573 179.574 13.704C179.574 14.12 179.791 14.432 180.224 14.64C180.675 14.848 181.368 15.056 182.304 15.264C183.379 15.5413 184.254 15.8186 184.93 16.096C185.606 16.356 186.195 16.7893 186.698 17.396C187.218 17.9853 187.487 18.7826 187.504 19.788C187.504 20.6373 187.261 21.4 186.776 22.076C186.308 22.7346 185.623 23.2546 184.722 23.636C183.838 24.0173 182.807 24.208 181.628 24.208Z'
        fill='#212121'
      />
      <path
        d='M192.564 24.208C191.784 24.208 191.143 23.9826 190.64 23.532C190.155 23.064 189.912 22.492 189.912 21.816C189.912 21.1226 190.155 20.542 190.64 20.074C191.143 19.606 191.784 19.372 192.564 19.372C193.327 19.372 193.951 19.606 194.436 20.074C194.939 20.542 195.19 21.1226 195.19 21.816C195.19 22.492 194.939 23.064 194.436 23.532C193.951 23.9826 193.327 24.208 192.564 24.208Z'
        fill='#212121'
      />
      <path
        d='M197.198 16.72C197.198 15.2293 197.475 13.9206 198.03 12.794C198.602 11.6673 199.373 10.8006 200.344 10.194C201.315 9.58728 202.398 9.28395 203.594 9.28395C204.617 9.28395 205.509 9.49195 206.272 9.90795C207.052 10.324 207.65 10.87 208.066 11.546V9.49195H212.512V24H208.066V21.946C207.633 22.622 207.026 23.168 206.246 23.584C205.483 24 204.591 24.208 203.568 24.208C202.389 24.208 201.315 23.9046 200.344 23.298C199.373 22.674 198.602 21.7986 198.03 20.672C197.475 19.528 197.198 18.2106 197.198 16.72ZM208.066 16.746C208.066 15.6366 207.754 14.7613 207.13 14.12C206.523 13.4786 205.778 13.158 204.894 13.158C204.01 13.158 203.256 13.4786 202.632 14.12C202.025 14.744 201.722 15.6106 201.722 16.72C201.722 17.8293 202.025 18.7133 202.632 19.372C203.256 20.0133 204.01 20.334 204.894 20.334C205.778 20.334 206.523 20.0133 207.13 19.372C207.754 18.7306 208.066 17.8553 208.066 16.746Z'
        fill='#212121'
      />
      <path
        d='M218.224 7.98395C217.444 7.98395 216.803 7.75862 216.3 7.30795C215.815 6.83995 215.572 6.26795 215.572 5.59195C215.572 4.89862 215.815 4.32662 216.3 3.87595C216.803 3.40795 217.444 3.17395 218.224 3.17395C218.987 3.17395 219.611 3.40795 220.096 3.87595C220.599 4.32662 220.85 4.89862 220.85 5.59195C220.85 6.26795 220.599 6.83995 220.096 7.30795C219.611 7.75862 218.987 7.98395 218.224 7.98395ZM220.434 9.49195V24H215.988V9.49195H220.434Z'
        fill='#212121'
      />
      <rect x='21' y='27' width='200' height='3' fill='#00A757' />
    </svg>
  );
}
