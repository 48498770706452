import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

import { Button } from 'components/Button';
import ButtonLoader from 'components/loading/ButtonLoader';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useToast } from 'hooks/useToaster';
import createTruckList from 'lib/api/createTruckList';
import { TruckListResponse } from 'lib/api/getTruckList';
import { ingestEmail } from 'lib/api/ingestEmail';
import { Email } from 'types/Email';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { EmailLabels } from 'types/enums/EmailLabels';
import Pageview from 'types/enums/Pageview';

import RedwoodTruckListForm from './Redwood/RedwoodTruckListForm';
import {
  getToastForIngestionTriggerFailed,
  getToastForIngestionTriggerSuccess,
} from './Redwood/RedwoodUtils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export enum TruckType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}

type TruckListSectionProps = {
  email: Maybe<Email>;
  truckList: MaybeUndef<TruckListResponse>;
};

export default function TruckListSection({
  email,
  truckList,
}: TruckListSectionProps) {
  useLogPostHogPageView(Pageview.TruckList);

  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [truckListFormData, setTruckListFormData] = useState(truckList);
  const [showManualIngest, setShowManualIngest] = useState(false);

  useEffect(() => {
    // Show manual ingest button only when:
    // 1. We have an email.
    // 2. No trucks are present.
    // 3. The email hasn't been processed yet (no data from backend).
    setShowManualIngest(
      Boolean(email?.threadID) &&
        (truckList?.trucks === undefined || truckList?.trucks?.length === 0) &&
        !email?.labels?.includes(EmailLabels.TruckList)
    );
  }, []);

  const handleCreateTruckList = async () => {
    if (!email || !email.threadID) return;

    setLoading(true);
    const res = await createTruckList(email.id, email.threadID);

    if (res.isOk()) {
      toast({ title: 'Truck List created', variant: 'success' });

      setTruckListFormData(res.value);
    } else {
      toast({ title: 'Error creating truck list', variant: 'destructive' });
    }

    setLoading(false);
  };

  const handleTriggerManualIngest = async () => {
    if (!email || !email.threadID) return;

    setLoading(true);
    const res = await ingestEmail(email.threadID);

    if (res.isOk()) {
      toast(getToastForIngestionTriggerSuccess());
    } else {
      toast(getToastForIngestionTriggerFailed(res.error.message));
    }

    setLoading(false);
  };

  return truckListFormData ? (
    <RedwoodTruckListForm email={email} truckList={truckListFormData} />
  ) : (
    <>
      {showManualIngest ? (
        <>
          <Button
            className='w-full my-8'
            buttonNamePosthog={ButtonNamePosthog.IngestEmail}
            onClick={handleTriggerManualIngest}
          >
            {loading ? <ButtonLoader /> : <span>Process email</span>}
          </Button>

          <span className='flex justify-center text-center'>OR</span>
        </>
      ) : null}

      <Button className='w-full my-8' onClick={handleCreateTruckList}>
        {loading ? <ButtonLoader /> : <span>Create Truck List</span>}
      </Button>
    </>
  );
}
