import { KeyedMutator } from 'swr';

import { Maybe } from 'types/UtilityTypes';
import { SuggestedLoadChange } from 'types/suggestions/LoadSuggestions';
import { useThrowableSWR } from 'utils/fetcher';

export type SuggestionsFetchResult = {
  suggestions: Maybe<SuggestedLoadChange[]>;
  invalidate: KeyedMutator<SuggestionsResponse>;
  isLoading: boolean;
  error: Error;
};

export type SuggestionsResponse = {
  suggestions: SuggestedLoadChange[];
};

export default function useFetchSuggestions(
  threadID?: Maybe<string>,
  loadID?: Maybe<number>
): SuggestionsFetchResult {
  const { data, isLoading, mutate, error } =
    useThrowableSWR<SuggestionsResponse>(
      threadID
        ? `thread/${encodeURIComponent(threadID)}/load/${loadID}/suggestions`
        : null
    );

  return {
    suggestions: data
      ? data.suggestions?.sort(
          // Sort in descending order (most recent first)
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      : null,
    invalidate: mutate,
    isLoading: isLoading,
    error: error,
  };
}
