import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { GetWarehouseResponse, SchedulingPortals } from 'types/Appointment';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export async function getWarehouseData(
  warehouseID: string,
  source: SchedulingPortals
): Promise<Result<GetWarehouseResponse, ApiError>> {
  try {
    const url = `/warehouse?warehouseID=${encodeURIComponent(warehouseID)}&source=${source}`;
    const { data } = await axios.get<GetWarehouseResponse>(url);

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'getWarehouseData' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get warehouse data' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get warehouse data',
    });
  }
}
