import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import useLogPostHogPageView from 'hooks/useLogPostHogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import McleodEnterpriseLoadBuildingForm from 'pages/QuoteView/LoadBuilding/McleodLoadBuildingForm';
import TaiLoadBuildingForm from 'pages/QuoteView/LoadBuilding/TaiLoadBuildingForm';
import { Maybe } from 'types/UtilityTypes';
import TMS from 'types/enums/Integrations';
import Pageview from 'types/enums/Pageview';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import captureException from 'utils/captureException';

export default function LoadBuildingSection() {
  const { serviceID, tmsIntegrations } = useServiceFeatures();
  const [isMcleodEnterpriseTMS, setIsMcleodEnterpriseTMS] =
    useState<Maybe<boolean>>(null);

  useLogPostHogPageView(Pageview.LoadBuilding, {
    form_type:
      isMcleodEnterpriseTMS === null
        ? 'loading'
        : isMcleodEnterpriseTMS
          ? 'mcleod'
          : 'tai',
    tms_integrations_count: tmsIntegrations.length,
    service_id: serviceID,
  });

  const {
    currentState: { curSuggestionList, goToSuggestionInCarousel },
  } = useContext(SidebarStateContext);

  // FIXME: Weird carousel animation when switching to LB tab, but doesn't happen for other tabs
  // (happens even outside of this useEffect)
  useEffect(() => {
    // Go to first load building suggestion in carousel
    goToSuggestionInCarousel(SuggestionPipelines.LoadBuilding);
  }, [curSuggestionList]);

  useEffect(() => {
    if (tmsIntegrations.length > 0) {
      // TODO: add support for multiple TMS integrations
      if (tmsIntegrations.length > 1 && isProd()) {
        captureException(
          new Error(
            'Service with multiple TMS integrations trying to build load'
          ),
          { serviceID: serviceID, tmsIntegrations: tmsIntegrations }
        );
      }
      setIsMcleodEnterpriseTMS(
        tmsIntegrations[0].name === TMS.McleodEnterprise
      );
    }
  }, [tmsIntegrations]);

  return isMcleodEnterpriseTMS === null ? null : isMcleodEnterpriseTMS ? (
    <McleodEnterpriseLoadBuildingForm />
  ) : (
    <TaiLoadBuildingForm />
  );
}
