import banner from '../../assets/banner.png';

export default function Intro(): React.JSX.Element {
  return (
    <>
      <div className='mb-2'>
        <img src={banner} className='p-2' />
      </div>
      <div className='text-base px-4 mb-4'>
        Drumkit is an Outlook add-on for logistics operations teams that fully
        automates fundamental workflows like:
        <ul className='list-disc pl-4 my-2'>
          <li>Building loads</li>
          <li>Scheduling appointments</li>
          <li>{'Track & trace'}</li>
        </ul>
        You can do 90% of your job without ever leaving your inbox!
        <br />
        Get started by signing in.
      </div>
    </>
  );
}
