import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CheckCall } from 'types/CheckCall';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export async function getCheckCallHistory(
  loadId: number
): Promise<Result<CheckCall[], ApiError>> {
  try {
    const response = await axios.get(`/load/${loadId}/checkCalls`);
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getCheckCallHistory' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get check call history' });
    }

    return err({
      message:
        error.response?.data.message || 'Failed to get check call history',
    });
  }
}
