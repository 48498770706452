import { isAxiosError } from 'axios';
import useSWR, { SWRResponse } from 'swr';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { MaybeUndef } from 'types/UtilityTypes';

import captureException from './captureException';

export const fetcher = (url: string) =>
  axios.get(url).then((res: any) => res.data);

export const useThrowableSWR = <Data = any, Error = any>(
  key: MaybeUndef<string>,
  retryOn404s: boolean = true
): SWRResponse<Data, Error> => {
  const swrResult = useSWR<Data>(key, fetcher, {
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
      // In dev, retry on 404s to allow dev ingestion to run
      if (
        (error.response && error.response.status === 404 && isProd()) ||
        !retryOn404s
      )
        return;

      if (retryCount >= 5) return;
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
    revalidateOnFocus: false,
  });

  const error = swrResult.error;

  if (error && isAxiosError(error) && error.response?.status === 401) {
    throw error;
  }

  if (error && error.message === 'Extension context invalidated.') {
    throw error;
  }

  if (error) {
    captureException(error, { functionName: 'useThrowableSWR' });
  }

  return swrResult;
};
