import {
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { AutoComplete, DatePicker, Input, Select } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { BaseOptionType } from 'antd/es/select';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import {
  CalendarIcon,
  MailsIcon,
  RouteIcon,
  SearchIcon,
  XCircleIcon,
} from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { LOAD_MONIKER } from '@constants/LoadMoniker';

import { Button } from 'components/Button';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadContextProvider from 'components/LoadContextProvider';
import StarredLoadList from 'components/StarredLoadList';
import StarredLoadToggle from 'components/StarredLoadToggle';
import SuggestionsCarousel from 'components/SuggestionsCarousel';
import TMSProvider from 'components/TMSProvider';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsTriggerVariants,
} from 'components/Tabs';
import { Toaster } from 'components/ToasterProvider';
import SidebarLoader from 'components/loading/SidebarLoader';
import { AvailableTabs } from 'constants/SidebarTabs';
import {
  SidebarStateContext,
  isEmailPlatform,
} from 'contexts/sidebarStateContext';
import { TMSContextType } from 'contexts/tms';
import useFetchLoadByFreightTrackingID from 'hooks/useFetchLoadByFreightTrackingID';
import useFetchStarredLoads from 'hooks/useFetchStarredLoads';
import useFetchSuggestions from 'hooks/useFetchSuggestions';
import useFetchViewedLoads from 'hooks/useFetchViewedLoads';
import useLogPageView from 'hooks/useLogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import InfoCircleIcon from 'icons/InfoCircle';
import fetchLoadByFreightTrackingID from 'lib/api/fetchLoadBySearchQuery';
import putViewedLoadHistory from 'lib/api/putViewedLoadHistory';
import { createTMSInstance } from 'lib/hosts/interface';
import AppointmentSchedulingSection from 'pages/LoadView/AppointmentTab';
import LoadInformationSection from 'pages/LoadView/LoadInformationTab';
import EmailsSection from 'pages/LoadView/OutboxTab';
import CarrierCheckInSection from 'pages/LoadView/TrackAndTrace/CarrierCheckIn';
import CheckCallSection from 'pages/LoadView/TrackAndTrace/CheckCalls';
import ShipperCheckInSection from 'pages/LoadView/TrackAndTrace/ShipperCheckIn';
import { Email } from 'types/Email';
import { ExternalLinks } from 'types/ExternalLinks';
import { Load, normalizeLoad } from 'types/Load';
import { LoadAttributes } from 'types/LoadAttributes';
import { PendingOutboxEmails } from 'types/PendingOutboxEmail';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import CheckCallSource from 'types/enums/CheckCallSource';
import TMS from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import {
  CheckCallSuggestion,
  SuggestedLoadChange,
} from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';

import { STATE_ABBREVIATIONS } from '../../constants/StateTimezones';
import OverviewSection from './OverviewSection';
import ExceptionSection from './TrackAndTrace/Exceptions';
import NoteSection from './TrackAndTrace/Notes';

enum LoadSearchFilters {
  PRO = 'PRO',
  Adv = 'Adv',
}

type LoadsSidebarProps = {
  email: Maybe<Email>;
  initialFreightTrackingIDs: string[];
};

export default function LoadsSidebar({
  email,
  initialFreightTrackingIDs,
}: LoadsSidebarProps) {
  const [proNumbers, setProNumbers] = useState(initialFreightTrackingIDs);
  const [proNumberInput, setProNumberInput] = useState('');
  const [tab, setTab] = useState(initialFreightTrackingIDs[0] || '');
  const [searchFilter, setSearchFilter] = useState(LoadSearchFilters.PRO);

  const [routePickupCity, setRoutePickupCity] = useState('');
  const [routePickupState, setRoutePickupState] = useState('');
  const [routeDropoffCity, setRouteDropoffCity] = useState('');
  const [routeDropoffState, setRouteDropoffState] = useState('');

  const [customerName, setCustomerName] = useState('');

  const [pickupDate, setPickupDate] = useState<Maybe<dayjs.Dayjs>>(null);
  const [dropoffDate, setDropoffDate] = useState<Maybe<dayjs.Dayjs>>(null);

  const { toast } = useToast();

  const {
    currentState: { starredLoads, viewedLoads },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: { isAdvancedSearchEnabled },
  } = useServiceFeatures();

  useMemo(() => {
    if (isAdvancedSearchEnabled) {
      useFetchStarredLoads();
      useFetchViewedLoads();
    }
  }, [isAdvancedSearchEnabled]);

  useEffect(() => {
    setProNumbers(initialFreightTrackingIDs);

    if (initialFreightTrackingIDs.length > 0) {
      setTab(initialFreightTrackingIDs[0]);
    } else {
      setTab('');
    }
  }, [initialFreightTrackingIDs]);

  // Auto-detect load open on in Planning Board on Relay
  const curRelayModalLoadID = useRef<string>(''); // Ref to track current schedule plan ID

  useEffect(() => {
    const handleModalEvent = () => {
      const tms = createTMSInstance(TMS.Relay);
      // Try parsing both IDs because depending on the TMS webpage we're on,
      // one may be available and the other night
      const parsedID = tms.parseExternalTMSID() ?? tms.parseFreightTrackingID();
      if (parsedID) {
        curRelayModalLoadID.current = parsedID;
        addLoadTab(parsedID);
      }
      // FIXME Closing just the load in the SchedulePlan modal closes all currently open load tabs
      // Ideally, searched load tabs would remain. But also not a major issue to keep the one from SchedulePlan open
      // } else if (curRelayModalLoadID) {
      //   closeTab(curRelayModalLoadID.current);
      //   curRelayModalLoadID.current = '';
      // }
    };

    document.addEventListener('loadModalEvent', handleModalEvent);
    return () => {
      document.removeEventListener('loadModalEvent', handleModalEvent);
    };
  }, []);

  const handleBlurSearchInput = () => {
    if (proNumberInput === '') {
      return;
    }

    const trimmedProNumber = proNumberInput.trim();

    addLoadTab(trimmedProNumber);
  };

  const addLoadTab = async (proNumber: string) => {
    setProNumbers((proNumbers) =>
      proNumbers.includes(proNumber) ? proNumbers : [...proNumbers, proNumber]
    );

    setTab(proNumber);
    setProNumberInput('');

    if (isAdvancedSearchEnabled) {
      const res = await putViewedLoadHistory(proNumber);
      if (res.isOk()) {
        const {
          value: { viewedLoadsHistory },
        } = res;

        setCurrentState((prevState) => ({
          ...prevState,
          viewedLoads: viewedLoadsHistory.reverse(),
        }));
      }
    }
  };

  const closeTab = (pro: string) => {
    const newProNumbers = proNumbers.filter((x) => pro !== x);
    setProNumbers(newProNumbers);
    setTab(newProNumbers[0]);
  };

  const handleKeyDownSearchInput = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      handleBlurSearchInput();
    }
  };

  const handleResetFilters = (value: LoadSearchFilters) => {
    setPickupDate(null);
    setDropoffDate(null);
    setRouteDropoffCity('');
    setRouteDropoffState('');
    setRoutePickupCity('');
    setRoutePickupState('');
    setSearchFilter(value);
  };

  const handleFetchLoadBySearch = async () => {
    const resp = await fetchLoadByFreightTrackingID({
      customerName,
      routePickupCity,
      routePickupState,
      routeDropoffCity,
      routeDropoffState,
      pickupDate,
      dropoffDate,
    });

    if (resp.isOk()) {
      const {
        value: { loadIDs },
      } = resp;

      if (loadIDs?.length === 0) {
        toast({
          description: 'No loads were found.',
          variant: 'destructive',
        });
        return;
      }

      loadIDs && loadIDs.forEach((loadID) => addLoadTab(loadID));
    } else {
      toast({
        description: resp.error.message,
        variant: 'destructive',
      });
    }
  };

  return (
    <div style={{ flex: '1' }}>
      <div className='flex flex-col'>
        <div className='w-full px-4 mt-4 flex gap-2 justify-between items-start'>
          <div className='search-container flex flex-auto'>
            <div className='search-filter h-9 flex items-center text-grayscale-content-2 text-[12px] px-2 rounded-l bg-grayscale-bg-secondary'>
              {isAdvancedSearchEnabled ? (
                <Select
                  onChange={(value: LoadSearchFilters) =>
                    handleResetFilters(value)
                  }
                  value={searchFilter}
                  options={Object.keys(LoadSearchFilters).map(
                    (filterOption) => ({
                      value: filterOption,
                      label:
                        filterOption === LoadSearchFilters.PRO
                          ? `${LOAD_MONIKER} #`
                          : filterOption,
                    })
                  )}
                />
              ) : (
                <div>{LOAD_MONIKER}&nbsp;#</div>
              )}
            </div>
            <div className='max-w-full w-auto flex flex-auto justify-between min-h-9 rounded-r border bg-white gap-2'>
              {searchFilter === LoadSearchFilters.PRO &&
                isAdvancedSearchEnabled && (
                  <AutoComplete
                    options={viewedLoads.reverse().map((vlID) => ({
                      label: `${vlID}`,
                      value: vlID,
                    }))}
                    onSelect={(v: string) => addLoadTab(v)}
                    value={proNumberInput}
                  >
                    <input
                      type='text'
                      className='flex-1 pl-2 bg-transparent focus-visible:outline-none text-orange-main text-[14px] block min-w-0'
                      value={proNumberInput}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setProNumberInput(e.target.value)
                      }
                      onBlur={handleBlurSearchInput}
                      onKeyDown={handleKeyDownSearchInput}
                      placeholder='Search loads...'
                    />
                  </AutoComplete>
                )}

              {searchFilter === LoadSearchFilters.PRO &&
              !isAdvancedSearchEnabled ? (
                <input
                  type='text'
                  className='flex-1 pl-2 bg-transparent focus-visible:outline-none text-orange-main text-[14px] block min-w-0'
                  value={proNumberInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setProNumberInput(e.target.value)
                  }
                  onBlur={handleBlurSearchInput}
                  onKeyDown={handleKeyDownSearchInput}
                  placeholder='Search loads...'
                />
              ) : null}

              {searchFilter === LoadSearchFilters.Adv &&
              isAdvancedSearchEnabled ? (
                <div className='route flex flex-col mx-4 my-2 gap-2'>
                  <Input
                    type='text'
                    placeholder='Customer Name'
                    value={customerName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCustomerName(e.target.value)
                    }
                  />

                  <DatePicker
                    placeholder='Pickup'
                    value={pickupDate}
                    onChange={(date: dayjs.Dayjs) => setPickupDate(date)}
                  />
                  <DatePicker
                    placeholder='Dropoff'
                    value={dropoffDate}
                    onChange={(date: dayjs.Dayjs) => setDropoffDate(date)}
                  />

                  <div className='flex gap-2'>
                    <Input
                      type='text'
                      placeholder='Pickup City'
                      value={routePickupCity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRoutePickupCity(e.target.value)
                      }
                    />
                    <Select
                      showSearch
                      placeholder='State'
                      style={{ width: 120 }}
                      className='text-grayscale-content-2'
                      optionFilterProp='children'
                      onChange={(v: string) => setRoutePickupState(v)}
                      value={routePickupState}
                      filterOption={(
                        input: string,
                        option: BaseOptionType | undefined
                      ) =>
                        (option?.label.toLocaleLowerCase() ?? '').includes(
                          input.toLocaleLowerCase()
                        )
                      }
                      options={STATE_ABBREVIATIONS.map((st: string) => ({
                        value: st,
                        label: st,
                      }))}
                    />
                  </div>

                  <div className='flex gap-2'>
                    <Input
                      type='text'
                      placeholder='Dropoff City'
                      value={routeDropoffCity}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRouteDropoffCity(e.target.value)
                      }
                    />
                    <Select
                      showSearch
                      placeholder='State'
                      style={{ width: 120 }}
                      className='text-grayscale-content-2'
                      optionFilterProp='children'
                      onChange={(v: string) => setRouteDropoffState(v)}
                      value={routeDropoffState}
                      filterOption={(
                        input: string,
                        option: BaseOptionType | undefined
                      ) =>
                        (option?.label.toLocaleLowerCase() ?? '').includes(
                          input.toLocaleLowerCase()
                        )
                      }
                      options={STATE_ABBREVIATIONS.map((st: string) => ({
                        value: st,
                        label: st,
                      }))}
                    />
                  </div>

                  <Button
                    variant='default'
                    className='bg-orange-pressed text-white h-6 rounded-[4px] text-[12px]'
                    buttonNamePosthog={ButtonNamePosthog.SearchLoad}
                    onClick={handleFetchLoadBySearch}
                  >
                    Search
                  </Button>
                </div>
              ) : null}

              {searchFilter === LoadSearchFilters.PRO &&
              isAdvancedSearchEnabled ? (
                <button
                  className='w-8 flex items-center justify-center shrink-0'
                  onClick={handleBlurSearchInput}
                >
                  <SearchIcon className='h-4 w-4' />
                </button>
              ) : null}
            </div>
          </div>

          {isAdvancedSearchEnabled && (
            <StarredLoadList
              tab={tab}
              starredLoads={starredLoads}
              addLoadTab={(proNumber) => addLoadTab(proNumber)}
            />
          )}
        </div>

        <div className='w-full flex-1 shrink-0 flex flex-col'>
          <Tabs
            value={tab}
            onValueChange={(tab) => setTab(tab)}
            className='w-full flex-1 shrink-0 flex flex-col mt-2'
          >
            <TabsList className='w-full h-[48px] gap-[12px] relative'>
              <div className='overflow-y-hidden overflow-x-scroll gap-2 h-[50px] flex'>
                {proNumbers.map((proNumber) => (
                  <TabsTrigger
                    value={proNumber}
                    key={proNumber}
                    className='text-sm h-8 flex flex-row !rounded-none'
                  >
                    {proNumber}
                    {proNumber === tab && (
                      <button onClick={() => closeTab(proNumber)}>
                        <XCircleIcon className='h-4 w-4' />
                      </button>
                    )}
                  </TabsTrigger>
                ))}
              </div>

              {isAdvancedSearchEnabled && (
                <div className='pl-4'>
                  <StarredLoadToggle tab={tab} starredLoads={starredLoads} />
                </div>
              )}
            </TabsList>

            <div className='flex-1 shrink-0'>
              {proNumbers.map((proNumber) => (
                <TabsContent value={proNumber} key={proNumber} className='pt-4'>
                  <LoadsSidebarLoadTab
                    email={email}
                    freightTrackingID={proNumber}
                  />
                </TabsContent>
              ))}
            </div>
          </Tabs>

          {email &&
          (!email.freightTrackingIDs ||
            email.freightTrackingIDs.length === 0) &&
          proNumbers.length === 0 ? (
            <div className='px-4 text-red-main'>
              No load found for this e-mail.
            </div>
          ) : (
            <></>
          )}

          {!email && proNumbers.length === 0 && (
            <div className='px-4'>Search for a load...</div>
          )}
        </div>
      </div>
      <Toaster />
    </div>
  );
}

type LoadsSidebarLoadTabProps = {
  email: MaybeUndef<Email>;
  freightTrackingID: string;
};

function LoadsSidebarLoadTab({
  email,
  freightTrackingID,
}: LoadsSidebarLoadTabProps) {
  const {
    tmsID,
    tmsName,
    load,
    loadAttributes,
    externalLinks,
    invalidate,
    invalidateKey,
    isLoading,
    pendingOutboxEmails,
    pickupWarehouse: loadPickupWarehouse,
    dropoffWarehouse: loadDropoffWarehouse,
    error: fetchError,
  } = useFetchLoadByFreightTrackingID(freightTrackingID);

  const loadNotFound =
    !isLoading &&
    fetchError &&
    isAxiosError(fetchError) &&
    fetchError.response?.status === 404;

  const invalidateLoad = async (
    load?: Maybe<Load>,
    attrs?: Maybe<LoadAttributes>
  ) => {
    if (load && attrs) {
      await invalidate({
        tmsID,
        tmsName,
        externalLinks,
        load,
        loadAttributes: attrs,
        pendingOutboxEmails,
        pickupWarehouse: loadPickupWarehouse,
        dropoffWarehouse: loadDropoffWarehouse,
      });
    } else {
      invalidate();
    }
  };

  return (
    <div className='flex-1 shrink-0'>
      {!load && isLoading && <SidebarLoader />}

      {/* {!isLoading && load && load.moreThanTwoStops && (
        <div className='px-4 text-red-main'>
          {'We will soon support multi-stop loads!'}
        </div>
      )} */}

      {load && !isLoading && !load.isPlaceholder && (
        <LoadTabContent
          tmsID={tmsID}
          tmsName={tmsName}
          email={email!}
          load={load!}
          loadAttributes={loadAttributes!}
          externalLinks={externalLinks}
          loadPickupWarehouse={loadPickupWarehouse!}
          loadDropoffWarehouse={loadDropoffWarehouse!}
          invalidate={invalidateLoad}
          invalidateKey={invalidateKey}
          pendingOutboxEmails={pendingOutboxEmails!}
        />
      )}

      {loadNotFound && (
        <div className='px-4 text-red-main'>
          Load not found for #{freightTrackingID}.
        </div>
      )}

      {/* We live lookup a load from TMS when a user opens email/searches for it. So if it's still a placeholder at this point,
  something went wrong with the live lookup (429, network timeout, etc) */}
      {(!loadNotFound && freightTrackingID && !load && !isLoading) ||
        (load?.isPlaceholder && (
          <div className='px-4 text-red-main'>Error fetching load.</div>
        ))}
    </div>
  );
}

type LoadTabContentProps = TMSContextType & {
  email: Email;
  load: Load;
  loadAttributes: LoadAttributes;
  externalLinks: MaybeUndef<ExternalLinks>;
  loadPickupWarehouse: MaybeUndef<Warehouse>;
  loadDropoffWarehouse: MaybeUndef<Warehouse>;
  invalidate: (load?: Load, loadAttributes?: LoadAttributes) => Promise<void>;
  invalidateKey: number;
  pendingOutboxEmails: MaybeUndef<PendingOutboxEmails>;
};

export function LoadTabContent({
  tmsID,
  tmsName,
  email,
  load,
  loadAttributes,
  externalLinks,
  loadPickupWarehouse,
  loadDropoffWarehouse,
  invalidate,
  invalidateKey,
  pendingOutboxEmails,
}: LoadTabContentProps) {
  useLogPageView();

  const {
    serviceFeaturesEnabled: {
      isAppointmentSchedulingEnabled,
      isTrackAndTraceEnabled,
      isExceptionsEnabled,
      isCheckCallCarrierSOPEnabled,
      isCheckCallShipperSOPEnabled,
      isCheckCallNotesEnabled,
      isCarrierEmailOutboxEnabled,
    },
  } = useServiceFeatures();

  const {
    suggestions,
    isLoading,
    invalidate: suggestionsInvalidate,
  } = useFetchSuggestions(email?.threadID, load.ID);

  const [tab, setTab] = useState(AvailableTabs.LoadInformation);

  const [modifiedSuggestions, setModifiedSuggestions] = useState<
    SuggestedLoadChange[]
  >([]);

  useEffect(() => {
    if (suggestions) {
      const updatedSuggestions = suggestions
        .filter((sug) => sug.status === 'pending')
        .map((sug) => {
          if (sug.pipeline === SuggestionPipelines.CarrierInfo) {
            return {
              ...sug,
              suggested: {
                ...sug.suggested,
                dispatchedTime: sug.suggested.dispatchedTime
                  ? sug.suggested.dispatchedTime
                  : dayjs().second(0).millisecond(0).toDate(),
                dispatchSource:
                  sug.suggested.dispatchSource ?? CheckCallSource.Dispatcher,
              },
            };
          }
          return sug;
        });

      setModifiedSuggestions(updatedSuggestions);
    }
  }, [suggestions]);

  const {
    currentState: { drumkitPlatform },
  } = useContext(SidebarStateContext);

  useEffect(() => {
    if (!isEmailPlatform(drumkitPlatform) && tmsName) {
      setTab(createTMSInstance(tmsName as TMS).determineDefaultLoadTab());
    }
  }, [tmsName]);

  const loadBelongsToCurrentEmail =
    email &&
    !load?.isPlaceholder &&
    email.freightTrackingIDs &&
    email.freightTrackingIDs.includes(load.freightTrackingID);

  const normalizedLoad = normalizeLoad(tmsName, load);

  // Reformat load attributes for context provider
  const fieldAttrs = useMemo(() => {
    const customerFieldAttrs = Object.entries(loadAttributes.customer).map(
      ([key, value]) => ({
        [`customer.${key}`]: value,
      })
    );
    const billToFieldAttrs = Object.entries(loadAttributes.billTo).map(
      ([key, value]) => ({
        [`billTo.${key}`]: value,
      })
    );
    const specificationsFieldAttrs = Object.entries(
      loadAttributes.specifications
    ).map(([key, value]) => ({
      [`specifications.${key}`]: value,
    }));
    const pickupFieldAttrs = Object.entries(loadAttributes.pickup).map(
      ([key, value]) => ({
        [`pickup.${key}`]: value,
      })
    );
    const consigneeFieldAttrs = Object.entries(loadAttributes.consignee).map(
      ([key, value]) => ({
        [`consignee.${key}`]: value,
      })
    );
    const carrierFieldAttrs = Object.entries(loadAttributes.carrier).map(
      ([key, value]) => ({
        [`carrier.${key}`]: value,
      })
    );
    const rateDataFieldAttrs = Object.entries(loadAttributes.rateData).map(
      ([key, value]) => ({
        [`rateData.${key}`]: value,
      })
    );

    return [
      { poNums: loadAttributes.poNums },
      ...customerFieldAttrs,
      ...billToFieldAttrs,
      ...specificationsFieldAttrs,
      ...pickupFieldAttrs,
      ...consigneeFieldAttrs,
      ...carrierFieldAttrs,
      ...rateDataFieldAttrs,
    ];
  }, [loadAttributes]);

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  // Redirecting to correct tab after Email AI card is clicked
  useEffect(() => {
    if (!clickedSuggestion) return;

    switch (clickedSuggestion.pipeline) {
      case SuggestionPipelines.CarrierInfo:
      case SuggestionPipelines.ApptConfirmation:
        setTab(AvailableTabs.LoadInformation);
        break;
      case SuggestionPipelines.CheckCall:
        setTab(AvailableTabs.TrackAndTrace);
        break;
      default:
        captureException(
          new Error('Invalid suggestion pipeline: ' + clickedSuggestion)
        );
    }
  }, [clickedSuggestion]);

  const checkCallSuggestions = useMemo(() => {
    if (!suggestions) return [];

    return suggestions.filter(
      (s): s is CheckCallSuggestion =>
        s.status === 'pending' && s.pipeline == SuggestionPipelines.CheckCall
    );
  }, [suggestions]);

  // Initialize tab refs
  const tabRefs = Object.fromEntries(
    Object.values(AvailableTabs).map((tab) => [
      tab,
      useRef<HTMLButtonElement>(null),
    ])
  ) as Record<AvailableTabs, React.RefObject<HTMLButtonElement>>;

  return (
    <>
      {isLoading && <SidebarLoader />}

      {!isLoading && load && modifiedSuggestions?.length > 0 && (
        <SuggestionsCarousel suggestions={modifiedSuggestions} />
      )}

      {!isLoading && load && !load.isPlaceholder && (
        <OverviewSection
          tmsName={tmsName as TMS}
          freightTrackingID={load.freightTrackingID}
          externalTMSID={load.externalTMSID}
          labels={
            loadBelongsToCurrentEmail && email.labels
              ? email.labels.split(',')
              : []
          }
          loadStatus={load.status}
          poNumbers={load.poNums ? load.poNums.split(',') : []}
          fromEmail={email?.from}
        />
      )}

      {!isLoading && load && (
        <TMSProvider tmsName={tmsName} tmsID={tmsID}>
          <LoadContextProvider
            fieldAttributes={fieldAttrs}
            loadAttrsObj={loadAttributes}
            invalidateLoad={invalidate}
          >
            <Tabs
              value={tab}
              onValueChange={(tab) => setTab(tab as AvailableTabs)}
              className='w-full h-full flex-1 shrink-0 flex flex-col'
            >
              <TabsList className='w-full overflow-x-auto flex gap-0'>
                <TabsTrigger
                  value={AvailableTabs.LoadInformation}
                  ref={tabRefs.loadInformation}
                  variant={TabsTriggerVariants.LoadTabs}
                >
                  <InfoCircleIcon
                    className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                    strokeWidth={1}
                  />
                  <p className='overflow-x-hidden'>Load Info</p>
                </TabsTrigger>
                {isAppointmentSchedulingEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.AppointmentScheduling}
                    ref={tabRefs.appointmentScheduling}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <CalendarIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Appointments</p>
                  </TabsTrigger>
                )}
                {isTrackAndTraceEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.TrackAndTrace}
                    ref={tabRefs.trackAndTrace}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <RouteIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Track & Trace</p>
                  </TabsTrigger>
                )}
                {isCarrierEmailOutboxEnabled && (
                  <TabsTrigger
                    value={AvailableTabs.Outbox}
                    ref={tabRefs.outbox}
                    variant={TabsTriggerVariants.LoadTabs}
                  >
                    <MailsIcon
                      className='min-h-[18px] max-h-[18px] min-w-[18px] max-w-[18px]'
                      strokeWidth={1}
                    />
                    <p className='overflow-x-hidden'>Outbox</p>
                  </TabsTrigger>
                )}
              </TabsList>
              <div className='bg-grayscale-bg-secondary flex-1 shrink-0'>
                <TabsContent
                  value={AvailableTabs.LoadInformation}
                  className='px-5'
                >
                  <ErrorBoundary>
                    <LoadInformationSection
                      load={load}
                      externalLinks={externalLinks}
                      key={`${load.ID}-${invalidateKey}`}
                    />
                  </ErrorBoundary>
                </TabsContent>
                {isAppointmentSchedulingEnabled && (
                  <TabsContent value={AvailableTabs.AppointmentScheduling}>
                    <ErrorBoundary>
                      <AppointmentSchedulingSection
                        normalizedLoad={normalizedLoad}
                        key={load.freightTrackingID}
                        loadPickupWarehouse={
                          loadPickupWarehouse?.warehouseID
                            ? loadPickupWarehouse
                            : null
                        }
                        loadDropoffWarehouse={
                          loadDropoffWarehouse?.warehouseID
                            ? loadDropoffWarehouse
                            : null
                        }
                      />
                    </ErrorBoundary>
                  </TabsContent>
                )}
                {isTrackAndTraceEnabled && (
                  <TabsContent
                    value={AvailableTabs.TrackAndTrace}
                    className='px-5'
                  >
                    {isCheckCallCarrierSOPEnabled && (
                      <ErrorBoundary>
                        <CarrierCheckInSection
                          normalizedLoad={normalizedLoad}
                          key={load.freightTrackingID}
                          setTab={setTab}
                        />
                      </ErrorBoundary>
                    )}
                    <ErrorBoundary>
                      <CheckCallSection
                        normalizedLoad={normalizedLoad}
                        key={normalizedLoad.freightTrackingID}
                        suggestions={checkCallSuggestions}
                        invalidateSuggestions={suggestionsInvalidate}
                      />
                    </ErrorBoundary>
                    {isExceptionsEnabled && (
                      <ErrorBoundary>
                        <ExceptionSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                    {isCheckCallShipperSOPEnabled && (
                      <ErrorBoundary>
                        <ShipperCheckInSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                    {isCheckCallNotesEnabled && (
                      <ErrorBoundary>
                        <NoteSection
                          normalizedLoad={normalizedLoad}
                          key={normalizedLoad.freightTrackingID}
                        />
                      </ErrorBoundary>
                    )}
                  </TabsContent>
                )}
                {isCarrierEmailOutboxEnabled &&
                  pendingOutboxEmails?.carrierEmails && (
                    <TabsContent value={AvailableTabs.Outbox}>
                      <ErrorBoundary>
                        <EmailsSection
                          normalizedLoad={normalizedLoad}
                          carrierEmails={pendingOutboxEmails?.carrierEmails}
                          setTab={setTab}
                        />
                      </ErrorBoundary>
                    </TabsContent>
                  )}
              </div>
            </Tabs>
          </LoadContextProvider>
        </TMSProvider>
      )}
    </>
  );
}
