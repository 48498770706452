import { ValueType } from 'components/DebounceSelect';
import { searchCarriers } from 'lib/api/searchCarriers';
import { searchCustomers } from 'lib/api/searchCustomers';
import { searchLocations } from 'lib/api/searchLocations';
import {
  CompanyCoreInfo,
  TMSCarrier,
  TMSCustomer,
  TMSLocation,
} from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

export type GenericCompanySearchableFields = keyof Pick<
  CompanyCoreInfo,
  'name' | 'addressLine1'
>;
export type CarrierSearchableFields =
  | GenericCompanySearchableFields
  | 'dotNumber';

export const locationSearchHandler = async ({
  field,
  value,
  tmsID,
  setLocations,
  locations,
}: {
  tmsID: number;
  locations: Maybe<TMSLocation[]>;
  setLocations: React.Dispatch<React.SetStateAction<Maybe<TMSLocation[]>>>;
  field: GenericCompanySearchableFields;
  value: string;
}) => {
  // require at least 3 characters to search
  if (value.length > 2) {
    const searchRes = await searchLocations(tmsID, field, value);

    if (searchRes.isOk()) {
      const { locationList: searchedLocations } = searchRes.value;
      setLocations(searchedLocations);

      return searchedLocations && searchedLocations.length
        ? mapLocationsToAntdOptions(searchedLocations)
        : [];
    }
  }

  // Filter from already loaded locations for searches less than 3 characters
  return mapLocationsToAntdOptions(locations ?? []).filter((loc) =>
    loc.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );
};

export const customerSearchHandler = async ({
  field,
  value,
  tmsID,
  setCustomers,
  customers,
}: {
  tmsID: number;
  customers: Maybe<TMSCustomer[]>;
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
  field: GenericCompanySearchableFields;
  value: string;
}) => {
  // require at least 2 characters to search
  if (value.length > 1) {
    const searchRes = await searchCustomers(tmsID, field, value);

    if (searchRes.isOk()) {
      const { customerList: searchedCustomers } = searchRes.value;
      setCustomers(searchedCustomers);

      return searchedCustomers && searchedCustomers.length
        ? mapCustomerToAntdOptions(searchedCustomers)
        : [];
    }
  }

  /**
   * Searches that are less than 2 characters dont provide enough context to search amongst
   * all customers. In that case, apply that filter to the entries that are already loaded.
   */
  return mapCustomerToAntdOptions(customers ?? []).filter((c) =>
    c.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );
};

export const carrierSearchHandler = async ({
  field,
  value,
  tmsID,
  setCarriers,
  carriers,
}: {
  tmsID: number;
  carriers: Maybe<TMSCarrier[]>;
  setCarriers: React.Dispatch<React.SetStateAction<Maybe<TMSCarrier[]>>>;
  field: CarrierSearchableFields;
  value: string;
}) => {
  // require at least 3 characters to search
  if (value.length > 2) {
    const searchRes = await searchCarriers(tmsID, field, value);

    if (searchRes.isOk()) {
      const { carrierList: searchedCarriers } = searchRes.value;
      setCarriers(searchedCarriers);

      return searchedCarriers && searchedCarriers.length
        ? mapCarriersToAntdOptions(searchedCarriers)
        : [];
    }
  }

  /**
   * Searches that are less than 3 characters dont provide enough context to search amongst
   * all carriers. In that case, apply that filter to the entries that are already loaded.
   */
  return mapCarriersToAntdOptions(carriers ?? []).filter((c) =>
    c.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );
};

export const searchParamOptions: {
  key: keyof CompanyCoreInfo;
  label: string;
}[] = [
  { key: 'name', label: 'Name' },
  { key: 'addressLine1', label: 'Street' },
];

export const mapLocationsToAntdOptions = (
  locations: Maybe<TMSLocation[]>
): ValueType[] => {
  if (!locations) return [];

  return locations.map((option: TMSLocation) => ({
    value: option.externalTMSID,
    name: option.name,
    addressLine1: option.addressLine1,
    addressLine2: option.addressLine2,
    city: option.city,
    state: option.state,
    label: option.externalTMSID,
  }));
};

export const mapCustomerToAntdOptions = (
  customers: Maybe<TMSCustomer[]>
): ValueType[] => {
  if (!customers) return [];

  return customers.map((option: TMSCustomer) => ({
    value: option.externalTMSID,
    name: option.name,
    addressLine1: option.addressLine1,
    addressLine2: option.addressLine2,
    city: option.city,
    state: option.state,
    label: `${option.name}`,
  }));
};

export const mapCarriersToAntdOptions = (
  carriers: Maybe<TMSCarrier[]>
): ValueType[] => {
  if (!carriers) return [];

  return carriers.map((option: TMSCarrier) => ({
    value: option.externalTMSID,
    name: option.name,
    addressLine1: option.addressLine1,
    addressLine2: option.addressLine2,
    city: option.city,
    state: option.state,
    label: option.name,
  }));
};

type WithExternalTMSID = {
  externalTMSID: string;
};

/**
 * Check if the selected object exists in the array, if not, inject the defaultObject
 */
export function injectSelectedObject<T extends WithExternalTMSID>(
  selected: T,
  array: T[]
): T[] {
  const exists = array.some(
    (obj) => obj.externalTMSID === selected.externalTMSID
  );

  // If it doesn't exist, inject the selected object
  if (!exists) {
    return [selected, ...array];
  }

  return array;
}
