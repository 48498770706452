import { FieldValues } from 'react-hook-form';

import dayjs from 'dayjs';

type TimepickerParsedTime =
  | {
      hours: number;
      minutes: number;
    }
  | undefined;

export const timepickerChangeHandler = (
  dayjsTime: dayjs.Dayjs,
  field: FieldValues
) => {
  const hours = dayjsTime?.hour() ?? '00';
  const minutes = dayjsTime?.minute() ?? '00';

  const currentDate = dayjs.isDayjs(field.value)
    ? field.value.toDate()
    : new Date(field.value);
  const newDate = new Date(currentDate.getTime());

  newDate.setHours(hours);
  newDate.setMinutes(minutes);

  field.onChange(newDate);
};

export const timepickerParseTimeWithoutColon = (
  elem: HTMLInputElement
): TimepickerParsedTime => {
  if (!elem.value || elem.value.length != 4) {
    return;
  }

  const parsedTime = elem.value.replace(/\b(\d{1,2})(\d{2})/g, '$1:$2');
  if (/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(parsedTime)) {
    const [hours, minutes] = parsedTime.split(':');
    return {
      hours: Number(hours),
      minutes: Number(minutes),
    };
  }
  return;
};
