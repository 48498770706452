import { useContext } from 'react';

import { LoadContext } from '../contexts/load';

export function useLoadContext() {
  return useContext(LoadContext);
}

export function useFieldAttributes() {
  const { fieldAttributes } = useContext(LoadContext);
  return fieldAttributes;
}

export function useLoadInvalidation(): () => Promise<void> {
  const { invalidateLoad } = useContext(LoadContext);
  return invalidateLoad;
}
