import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import AmplitudeEvent from 'types/enums/AmplitudeEvent';
import getAmplitude from 'utils/amplitude/getAmplitude';
import captureException from 'utils/captureException';

export default function useLogPageView(props?: {
  [key: string]: string;
}): void {
  useEffect(() => {
    if (!isProd()) {
      return () => {};
    }

    async function logPageView() {
      try {
        const amplitude = await getAmplitude();
        amplitude.logEvent(AmplitudeEvent.PageView, {
          origin: window.self.origin,
          pathname: window.location.pathname,
          ...props,
        });
      } catch (e) {
        captureException(e, { functionName: 'useLogPageView' });
      }
    }

    logPageView();
    return () => {};
  }, [props]);
}
