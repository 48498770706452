// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { Client } from '@utils/mailClient';

import { DrumkitPlatform } from 'contexts/sidebarStateContext';

export interface DraftReplyProps {
  threadItemId: string;
  draftBody: string;
}

export interface MailClientInterface {
  draftReply({ threadItemId, draftBody }: DraftReplyProps): Promise<void>;
}

export function createMailClientInstance(
  client: DrumkitPlatform
): MailClientInterface {
  // whitelist of supported mail client implementations
  if (client !== DrumkitPlatform.Outlook) {
    throw new Error('Invalid mail client type: ' + client);
  }

  return new Client();
}
