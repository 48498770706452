import { Dispatch, SetStateAction, createContext } from 'react';

import SidebarView from 'types/enums/SidebarView';

type SidebarViewContextType = {
  currentView: SidebarView;
  setCurrentView: Dispatch<SetStateAction<SidebarView>>;
};

export const SidebarViewContext = createContext<SidebarViewContextType>({
  currentView: SidebarView.Loads,
  setCurrentView: async () => {},
});
