import React, { ErrorInfo } from 'react';

import { isAxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import Login from '@auth/Login';

import ReloadExtension from 'components/ReloadExtension';
import { Maybe } from 'types/UtilityTypes';
import captureException from 'utils/captureException';

interface Props {
  children: React.ReactNode;
}

interface State {
  error: Maybe<Error>;
}

// NOTE: ErrorBoundary can only be defined as a class component
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, _info: ErrorInfo) {
    if (!error) {
      return;
    }

    if (isAxiosError(error) && error.response?.status === 401) {
      return;
    }

    if (error.message === 'Extension context invalidated.') {
      return;
    }

    if (
      error.message ===
      "Cannot read properties of undefined (reading 'sendMessage')"
    ) {
      return;
    }

    captureException(error);
  }

  render() {
    const error = this.state.error;

    if (error && isAxiosError(error) && error.response?.status === 401) {
      return <Login />;
    }

    if (error && error.message === 'Extension context invalidated.') {
      return <ReloadExtension />;
    }

    if (
      error &&
      error.message ===
        "Cannot read properties of undefined (reading 'sendMessage')"
    ) {
      return <ReloadExtension />;
    }

    if (error) {
      // TODO: Pretty raw error message. We can do a better message later
      return (
        <div>An error has ocurred. Refresh this tab to use Drumkit again.</div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
