import React, { useState } from 'react';
import { Controller, FieldError, FieldErrors } from 'react-hook-form';

import { DatePicker, Divider, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import dayjs, { Dayjs } from 'dayjs';
import {
  CheckCircleIcon,
  FolderInputIcon,
  PlusIcon,
  Trash2Icon,
  TruckIcon,
} from 'lucide-react';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';
import { FormTruck } from 'types/Truck';

import { TruckTextInput } from '../../TruckListFormFields';
import { TruckType } from '../../TruckListTab';
import { RedwoodTruckListInputs } from './../RedwoodTruckListForm';

type RedwoodTruckFieldsetProps = {
  control: any;
  errors: FieldErrors<RedwoodTruckListInputs>;
  trucksInGroup: any[];
  trucksArrayByPickupDate: any[];
  recreateTruck: (arrayIdx: number, newDate: string) => void;
  handleRemoveTruck: (idx: number) => void;
};

export default function RedwoodTruckFieldset({
  control,
  errors,
  trucksInGroup,
  trucksArrayByPickupDate,
  recreateTruck,
  handleRemoveTruck,
}: RedwoodTruckFieldsetProps) {
  const [dropdownNewDate, setDropdownNewDate] = useState<Dayjs>();

  const moveDestinationItems = (t: FormTruck): ItemType[] => [
    ...Object.keys(trucksArrayByPickupDate)
      .sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
      .map((date) => ({
        label: dayjs(date).format('MMM D - dddd'),
        key: `trucks-dropdown-${date}`,
        disabled: dayjs(date).isSame(t.pickupDate),
        onClick: () =>
          t.arrayIdx !== undefined && recreateTruck(t.arrayIdx, date),
      })),
  ];

  return trucksInGroup
    .sort((a, b) => a.id - b.id)
    .map((t, localIdx) => (
      <TooltipProvider key={t.tmpId}>
        <div className='relative mb-2 flex justify-between'>
          <h2 className='flex items-center gap-2 font-semibold'>
            <TruckIcon className='h-5 w-5' />
            {`Truck ${localIdx + 1}`}
          </h2>
          <div className='flex gap-3'>
            <Tooltip>
              <TooltipTrigger asChild>
                <Dropdown
                  menu={{ items: moveDestinationItems(t) }}
                  dropdownRender={(menu) => (
                    <div className='bg-white rounded shadow-lg'>
                      {React.cloneElement(menu as React.ReactElement, {
                        style: { boxShadow: 'none' },
                      })}
                      <Divider className='my-2' />
                      <div className='flex items-center gap-2 px-3 py-2'>
                        <DatePicker
                          onChange={(date) => setDropdownNewDate(date)}
                          suffixIcon={null}
                          className='w-28'
                          placement='bottomLeft'
                          popupAlign={{
                            points: ['bc', 'tc'], // Align calendar bottom-center with trigger's top-center
                            offset: [-20, -20], // Offset by 20 pixels from the trigger for breathing room
                          }}
                        />
                        <Button
                          variant='titlebarActionIcon'
                          size='sm'
                          className='h-7 w-7 p-0 border-0 bg-orange-hover text-white'
                          onClick={() =>
                            recreateTruck(
                              t.arrayIdx,
                              dayjs(dropdownNewDate).toISOString()
                            )
                          }
                        >
                          <PlusIcon className='h-4 w-4' />
                        </Button>
                      </div>
                    </div>
                  )}
                  trigger={['click']}
                >
                  <FolderInputIcon className='h-[18px] w-4 cursor-pointer stroke-grayscale-content-2' />
                </Dropdown>
              </TooltipTrigger>
              <TooltipContent>Move Truck To</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Trash2Icon
                  className='h-4 w-4 cursor-pointer stroke-grayscale-content-2'
                  onClick={() => handleRemoveTruck(t.arrayIdx)}
                />
              </TooltipTrigger>
              <TooltipContent>Delete Truck</TooltipContent>
            </Tooltip>
          </div>
        </div>

        <div>
          <Label name={`trucks.${t.arrayIdx}.type`} required={true}>
            Truck Type
          </Label>
          <Controller
            name={`trucks.${t.arrayIdx}.type`}
            control={control}
            rules={{ required: 'Required' }}
            render={({ field }) => {
              const truckTypeError = errors?.trucks?.[t.arrayIdx]
                ?.type as FieldError;

              return (
                <>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value as string}
                  >
                    <SelectTrigger className='w-full mt-1'>
                      <SelectValue placeholder={'Choose'} />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(TruckType).map((option) => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  {truckTypeError ? (
                    <p className='text-red-500 text-xs mb-2'>
                      {truckTypeError.message}
                    </p>
                  ) : null}
                </>
              );
            }}
          />
        </div>

        <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
          <div className='col-span-2'>
            <TruckTextInput
              name={`trucks.${t.arrayIdx}.pickupLocation.city`}
              label='Origin City'
              required
            />
          </div>

          <div className='col-span-1'>
            <TruckTextInput
              name={`trucks.${t.arrayIdx}.pickupLocation.state`}
              label='State'
              required
            />
          </div>
        </div>

        <div className='grid grid-cols-3 gap-4 my-2 mx-0 w-full'>
          <div className='col-span-2 relative'>
            <TruckTextInput
              name={`trucks.${t.arrayIdx}.dropoffLocation.city`}
              label='Dropoff City'
            />
            {t.dropoffIsCarrierDomicile && <CarrierDomicileTooltip />}
          </div>
          <div className='col-span-1 relative'>
            <TruckTextInput
              name={`trucks.${t.arrayIdx}.dropoffLocation.state`}
              label='State'
            />
            {t.dropoffIsCarrierDomicile && <CarrierDomicileTooltip />}
          </div>
        </div>

        <TruckTextInput name={`trucks.${t.arrayIdx}.length`} label='Length' />

        <Divider className='my-4 after:border-b-[1px] after:w-[90%] after:mx-auto after:border-grayscale-content-3' />
      </TooltipProvider>
    ));
}

const CarrierDomicileTooltip = () => (
  <div className='absolute top-[3px] right-0'>
    <Tooltip delayDuration={10}>
      <TooltipTrigger asChild>
        <CheckCircleIcon className='h-[14px] w-[14px] text-blue-main' />
      </TooltipTrigger>
      <TooltipContent className='w-60 rounded-[4px]'>
        This address has been populated automatically with the carrier's
        domicile.
      </TooltipContent>
    </Tooltip>
  </div>
);
