import { useContext, useEffect, useRef, useState } from 'react';

import { CircleDollarSign, HammerIcon, ListChecksIcon } from 'lucide-react';

import ErrorBoundary from 'components/ErrorBoundary';
import SuggestionsCarousel from 'components/SuggestionsCarousel';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsTriggerVariants,
} from 'components/Tabs';
import { Toaster } from 'components/ToasterProvider';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import useLogPageView from 'hooks/useLogPageView';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { getLoadBuildingSuggestion } from 'lib/api/getLoadBuildingSuggestion';
import { getQuickQuoteSuggestion } from 'lib/api/getQuickQuoteSuggestion';
import { TruckListResponse } from 'lib/api/getTruckList';
import getTruckList from 'lib/api/getTruckList';
import { Email } from 'types/Email';
import { Maybe } from 'types/UtilityTypes';
import { EmailLabels } from 'types/enums/EmailLabels';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';
import captureException from 'utils/captureException';

import LoadBuildingSection from './LoadBuilding/LoadBuildingTab';
import QuickQuoteSection from './Quoting/RequestQuickQuote';
import TruckListSection from './TruckList/TruckListTab';

enum AvailableTabs {
  QuickQuote = 'quickQuote',
  TrucksList = 'trucksList',
  LoadBuilding = 'loadBuilding',
}

type QuoteSidebarProps = {
  email: Maybe<Email>;
};

export default function QuoteSidebar({ email }: QuoteSidebarProps) {
  useLogPageView();

  const [quoteSuggestions, setQuoteSuggestions] = useState<
    SuggestedQuoteChange[]
  >([]);
  const [isZipMissing, setIsZipMissing] = useState<boolean>(false);

  const [loadBuildingSuggestions, setLoadBuildingSuggestions] = useState<
    LoadBuildingSuggestions[]
  >([]);

  const [allSuggestions, setAllSuggestions] = useState<GenericSuggestion[]>([]);
  const [truckListResult, setTruckListResult] =
    useState<Maybe<TruckListResponse>>();

  const {
    serviceFeaturesEnabled: {
      isQuickQuoteEnabled,
      isTruckListEnabled,
      isLoadBuildingEnabled,
    },
  } = useServiceFeatures();

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  const fetchQuoteSuggestions = async () => {
    const res = await getQuickQuoteSuggestion(email?.threadID || '');
    if (res.isOk()) {
      setQuoteSuggestions(res.value);
    }
  };

  const fetchLoadBuildingSuggestions = async () => {
    const res = await getLoadBuildingSuggestion(email?.threadID || '');
    if (res.isOk()) {
      setLoadBuildingSuggestions(res.value);
    }
  };

  const fetchTruckList = async () => {
    if (!email?.id || !email?.threadID) {
      return;
    }

    const res = await getTruckList(email.id, email.threadID);
    if (res.isOk()) {
      setTruckListResult(res.value);
    }

    setTab(AvailableTabs.TrucksList);
  };

  useEffect(() => {
    setAllSuggestions([
      ...(quoteSuggestions || []),
      ...(loadBuildingSuggestions || []),
    ]);
  }, [quoteSuggestions, loadBuildingSuggestions]);

  useEffect(() => {
    // Don't check most recent email's labels in case there are load building suggestions earlier in the thread
    if (isLoadBuildingEnabled) {
      // name fields in load building form are buggy if suggestions are applied before tab is set
      // so we try to default to the load building tab if it's enabled.
      // TODO: remove this hack and fix the bug with the name fields.
      setTab(AvailableTabs.LoadBuilding);
      fetchLoadBuildingSuggestions();
    }

    if (
      email?.labels.includes(EmailLabels.QuoteRequest) &&
      isQuickQuoteEnabled
    ) {
      fetchQuoteSuggestions();
    }

    if (isTruckListEnabled) {
      fetchTruckList();
    }
  }, [email, isQuickQuoteEnabled, isLoadBuildingEnabled, isTruckListEnabled]);

  // Redirecting to correct tab after Email AI card is clicked
  useEffect(() => {
    if (!clickedSuggestion) return;

    switch (clickedSuggestion.pipeline) {
      case SuggestionPipelines.QuickQuote:
        if (
          !clickedSuggestion.suggested.pickupZip ||
          !clickedSuggestion.suggested.deliveryZip
        ) {
          setIsZipMissing(true);
        } else {
          setIsZipMissing(false);
        }
        setTab(AvailableTabs.QuickQuote);
        break;
      case SuggestionPipelines.LoadBuilding:
        setTab(AvailableTabs.LoadBuilding);
        break;
      default:
        captureException(
          new Error('Invalid suggestion pipeline: ' + clickedSuggestion)
        );
    }
  }, [clickedSuggestion]);

  // Initialize tab refs
  const tabRefs = Object.fromEntries(
    Object.values(AvailableTabs).map((tab) => [
      tab,
      useRef<HTMLButtonElement>(null),
    ])
  ) as Record<AvailableTabs, React.RefObject<HTMLButtonElement>>;

  const [tab, setTab] = useState(
    isQuickQuoteEnabled ? AvailableTabs.QuickQuote : AvailableTabs.LoadBuilding
  );

  return (
    <div style={{ flex: '1' }}>
      <div className='mt-4'>
        <SuggestionsCarousel suggestions={allSuggestions} />

        <Tabs
          value={tab}
          onValueChange={(tab) => setTab(tab as AvailableTabs)}
          className='w-full h-full flex-1 shrink-0 flex flex-col'
        >
          <TabsList className='w-full overflow-x-auto flex gap-0'>
            {isQuickQuoteEnabled && (
              <TabsTrigger
                value={AvailableTabs.QuickQuote}
                ref={tabRefs.quickQuote}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <CircleDollarSign
                  className='min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px]'
                  strokeWidth={1}
                />
                <p className='overflow-x-hidden'>Quick Quote</p>
              </TabsTrigger>
            )}
            {isLoadBuildingEnabled && (
              <TabsTrigger
                value={AvailableTabs.LoadBuilding}
                ref={tabRefs.loadBuilding}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <HammerIcon
                  className='min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px]'
                  strokeWidth={1}
                />
                <p className='overflow-x-hidden'>Load Building</p>
              </TabsTrigger>
            )}
            {isTruckListEnabled && (
              <TabsTrigger
                value={AvailableTabs.TrucksList}
                ref={tabRefs.trucksList}
                variant={TabsTriggerVariants.LoadTabs}
              >
                <ListChecksIcon
                  className='min-h-[20px] max-h-[20px] min-w-[20px] max-w-[20px]'
                  strokeWidth={1}
                />
                <p className='overflow-x-hidden'>Truck List</p>
              </TabsTrigger>
            )}
          </TabsList>
          <div className='bg-grayscale-bg-secondary px-4 flex-1 shrink-0'>
            {isQuickQuoteEnabled && (
              <TabsContent value={AvailableTabs.QuickQuote}>
                <ErrorBoundary>
                  <QuickQuoteSection
                    email={email}
                    isZipMissing={isZipMissing}
                  />
                </ErrorBoundary>
              </TabsContent>
            )}
            {isLoadBuildingEnabled && (
              <TabsContent value={AvailableTabs.LoadBuilding}>
                <ErrorBoundary>
                  <LoadBuildingSection />
                </ErrorBoundary>
              </TabsContent>
            )}
            {isTruckListEnabled && (
              <TabsContent value={AvailableTabs.TrucksList}>
                <ErrorBoundary>
                  <TruckListSection email={email} truckList={truckListResult} />
                </ErrorBoundary>
              </TabsContent>
            )}
          </div>
        </Tabs>
      </div>
      <Toaster />
    </div>
  );
}
