export default function ReloadExtension() {
  return (
    <div id='drumkit-content-view-root'>
      {/* eslint-disable-next-line custom/no-grid-without-explicit-margin-and-width -- verified in Gmail and Relay ¯\_(ツ)_/¯ */}
      <div className='h-screen grid grid-cols-1 gap-4 place-items-center place-content-center text-center mx-8'>
        <p className='font-bold text-center'>
          A new version of Drumkit was installed.
        </p>
        <p className='text-sm text-center'>
          Refresh this tab to continue using Drumkit.
        </p>
      </div>
    </div>
  );
}
