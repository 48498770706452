import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type DeleteCarrierEmailResponse = {
  message: string;
};

export async function deleteCarrierEmail(
  emailId: number
): Promise<Result<DeleteCarrierEmailResponse, ApiError>> {
  try {
    const response = await axios.delete<DeleteCarrierEmailResponse>(
      `/outbox/email/${emailId}`
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'deleteCarrierEmail' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}
