import { ReactNode, useEffect, useState } from 'react';

import { Button } from 'components/Button';

interface ExpandableContentProps {
  showText: string;
  hideText: string;
  forceOpen?: boolean;
  children: ReactNode;
}

export default function ExpandableContent({
  showText,
  hideText,
  forceOpen,
  children,
}: ExpandableContentProps) {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  useEffect(() => {
    if (forceOpen) setShowContent(true);
  }, [forceOpen]);

  return (
    <div className='grid gap-6 grid-cols-1 mb-4 w-full mx-0'>
      <Button
        className='w-full col-span-6'
        type='button'
        variant='outline'
        onClick={toggleContent}
      >
        {showContent ? hideText : showText}
      </Button>
      <div
        className='w-full col-span-6'
        style={{ display: showContent ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  );
}
