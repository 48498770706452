import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';

import dayjs from 'dayjs';
import {
  BadgeInfoIcon,
  CalendarIcon,
  MapPinIcon,
  TriangleAlert,
} from 'lucide-react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useToast } from 'hooks/useToaster';
import addTruckToTruckList from 'lib/api/addTruckToTruckList';
import deleteTruckFromTruckList from 'lib/api/deleteTruckFromTruckList';
import { TruckListResponse } from 'lib/api/getTruckList';
import { submitTruckList } from 'lib/api/submitTruckList';
import validateTruckListCarrier from 'lib/api/validateTruckListCarrier';
import { Email } from 'types/Email';
import {
  CarrierInformation,
  FormTruck,
  Truck,
  TruckListErrors,
} from 'types/Truck';
import { TruckListCarrier } from 'types/TruckList';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { EmailLabels } from 'types/enums/EmailLabels';

import {
  TruckDateTimeInput,
  TruckListSectionAccordionItem,
} from '../TruckListFormFields';
import { RedwoodCarrierFieldset } from './Components/RedwoodCarrierFieldset';
import { RedwoodDefaultDropoffFieldset } from './Components/RedwoodDefaultDropoffFieldset';
import { RedwoodNewGroupFieldset } from './Components/RedwoodNewGroupFieldset';
import { NoCarrierAvailable } from './Components/RedwoodNoCarrierAvailable';
import RedwoodTruckFieldset from './Components/RedwoodTruckFieldset';
import {
  getCarrierForSubmit,
  getParsedErrors,
  getToastForCarrierErrors,
  getToastForFailedSubmission,
  getToastForGenericError,
  getToastForInvalidFields,
  getToastForSuccessSubmission,
  getToastForTruckErrors,
  getTrucksForSubmit,
  parseCarrierContactErrors,
  parseCarrierErrors,
  parseTruckErrors,
} from './RedwoodUtils';

type RedwoodTruckListFormProps = {
  email: Maybe<Email>;
  truckList: TruckListResponse;
};

export default function RedwoodTruckListForm({
  email,
  truckList,
}: RedwoodTruckListFormProps) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [dateEditing, setDateEditing] = useState<string>('');

  const [carriers, setCarriers] = useState<CarrierInformation[]>([]);
  const [showCarrierSelector, setShowCarrierSelector] =
    useState<boolean>(false);
  const [showNoCarrierMessage, setShowNoCarrierMessage] =
    useState<boolean>(false);

  const [showInvalidPickupDate, setShowInvalidPickupDate] = useState<
    { dateGroup: string; err: string }[]
  >([]);

  const [formValidationErrors, setFormValidationErrors] = useState<any>({});
  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const [truckListCarrier, setTruckListCarrier] = useState<TruckListCarrier>();
  const [truckListErrors, setTruckListErrors] =
    useState<Maybe<TruckListErrors>>();
  const [truckListServiceName, setTruckListServiceName] = useState<string>('');

  const memoizedDefaultValues: any = useMemo(() => {
    const { trucks } = truckList || {};

    /**
     * Truck Length is typed as a number in the BE but as a string in the FE in order
     * to allow for an empty value without confusing the user with a 0 length.
     *
     * Here we map the length from number to string, and then map them back when submitting.
     */
    const formTrucks = trucks?.map((t) => ({
      ...t,
      length: t.length ? String(t.length) : '',
    }));

    return {
      carrier: {
        name: truckListCarrier?.name,
        contact: {
          name: truckListCarrier?.contactName,
          email: truckListCarrier?.contactEmail,
        },
        mc: truckListCarrier?.mc,
        dot: truckListCarrier?.dot,
      },
      trucks: formTrucks,
    };
  }, [truckListCarrier, truckList]);

  // Set default values based on the grouped trucks
  const formMethods = useForm<RedwoodTruckListInputs>({
    defaultValues: memoizedDefaultValues,
  });

  useEffect(() => {
    if (memoizedDefaultValues) {
      formMethods.reset(memoizedDefaultValues);
    }
  }, [memoizedDefaultValues]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    setValue,
    setError,
  } = formMethods;

  const {
    fields: trucksArray,
    append: appendTruck,
    remove: removeTruck,
  } = useFieldArray({
    control,
    name: 'trucks',
    keyName: 'rhfId',
  });

  const truckArrayWatcher = useWatch({
    control,
    name: 'trucks',
  });

  useEffect(() => {
    if (!truckList) return;

    setTruckListErrors(truckList.errors);
    setTruckListCarrier(truckList.carrier);

    setTruckListServiceName(truckList.serviceName);
  }, [truckList]);

  const trucksArrayByPickupDate: any = useMemo(() => {
    if (!truckArrayWatcher) return;

    return truckArrayWatcher.reduce(
      (acc, t, idx) => {
        if (!t.pickupDate || !dayjs(t.pickupDate).isValid()) return acc;

        const date = dayjs(t.pickupDate).format('YYYY-MM-DD');
        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push({ ...t, arrayIdx: idx, length: String(t.length) });
        return acc;
      },
      {} as { [key: string]: FormTruck[] }
    );
  }, [truckArrayWatcher]);

  const recreateTruck = (arrayIdx: number, newDate: string) => {
    const truckFields = trucksArray[arrayIdx];

    removeTruck(arrayIdx);
    appendTruck({ ...truckFields, pickupDate: newDate });

    if (!activeTabs.includes(`truck-group-${newDate}`)) {
      setActiveTabs((oldActiveTabs) => [
        ...oldActiveTabs,
        `truck-group-${newDate}`,
      ]);
    }
  };

  // We map the errors with known error messages and update the form state accordingly.
  useEffect(() => {
    parseCarrierErrors({
      shouldActivateTabs: true,
      carrierErrors: truckListErrors?.carrierErrors,
      activeTabs,
      setActiveTabs,
      setShowNoCarrierMessage,
      setShowCarrierSelector,
      setCarriers,
    });

    parseTruckErrors({
      shouldActivateTabs: true,
      truckErrors: truckListErrors?.truckErrors,
      activeTabs,
      setActiveTabs,
      truckArrayWatcher,
      trucksArrayByPickupDate,
      setError,
      setShowInvalidPickupDate,
    });

    parseCarrierContactErrors({
      shouldActivateTabs: true,
      carrierContactErrors: truckListErrors?.carrierContactErrors,
      activeTabs,
      setActiveTabs,
      setError,
    });
  }, [truckListErrors]);

  const updatedCarrierName = useWatch({
    control,
    name: 'update.carrier',
  });

  useEffect(() => {
    if (updatedCarrierName) {
      const selectedCarrier = carriers.find(
        (carrier) => carrier.name === updatedCarrierName
      );

      if (selectedCarrier) {
        setValue('carrier.name', selectedCarrier.name);
        setValue('carrier.contact.name', selectedCarrier.contactName);
        setValue('carrier.contact.email', selectedCarrier.contactEmail);
        setValue('carrier.mc', selectedCarrier.mc);
        setValue('carrier.dot', selectedCarrier.dot);
      }
    }
  }, [updatedCarrierName, carriers, setValue]);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setLoading(true);

    if (email?.id == undefined || email?.threadID == undefined) {
      toast(getToastForFailedSubmission());
      setLoading(false);
      return;
    }

    const trucks: Truck[] = getTrucksForSubmit(truckArrayWatcher);
    const carrierInformation = getCarrierForSubmit(data);

    const res = await submitTruckList(
      email.id,
      email.threadID,
      truckListServiceName,
      carrierInformation,
      trucks
    );

    if (res.isOk()) {
      toast(getToastForSuccessSubmission());

      setFormValidationErrors({});
      setTruckListErrors(undefined);
    } else {
      if (!res.error.truckListErrors) {
        toast(getToastForFailedSubmission());
        setLoading(false);
        return;
      }

      setActiveTabs([]);
      setTruckListErrors(res.error.truckListErrors);

      const {
        carrierErrorsCount,
        carrierContactErrorsCount,
        postedByErrorsCount,
        truckErrorsCount,
      } = getParsedErrors(res.error.truckListErrors);

      if (carrierErrorsCount || carrierContactErrorsCount) {
        toast(getToastForCarrierErrors());
      } else {
        toast(getToastForTruckErrors(truckErrorsCount, postedByErrorsCount));
      }
    }

    setLoading(false);
  };

  const handleValidateCarrier = async () => {
    if (!email) return;

    setLoading(true);

    const carrier = {
      name: getValues('carrier.name') ?? '',
      contactName: getValues('carrier.contact.name') ?? '',
      contactEmail: getValues('carrier.contact.email') ?? '',
      mc: getValues('carrier.mc') ?? '',
      dot: getValues('carrier.dot') ?? '',
    };

    const res = await validateTruckListCarrier(
      email.id,
      email.threadID,
      carrier
    );
    if (res.isOk()) {
      toast({
        title: 'Carrier is valid!',
        variant: 'success',
      });

      setShowNoCarrierMessage(false);
      setShowCarrierSelector(false);
    } else {
      toast({
        title: 'Invalid carrier, please review before submitting truck list.',
        variant: 'destructive',
      });

      const { carrierErrors, carrierContactErrors } = res.error;

      parseCarrierErrors({
        shouldActivateTabs: false,
        carrierErrors,
        setShowNoCarrierMessage,
        setShowCarrierSelector,
        setCarriers,
      });

      parseCarrierContactErrors({
        shouldActivateTabs: false,
        carrierContactErrors,
        setError,
      });
    }

    setLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Record<string, any>> = async (errors) => {
    setActiveTabs([]);
    setFormValidationErrors(errors);

    if (errors?.carrier) {
      setActiveTabs(['carrier-details']);
    }

    toast(getToastForInvalidFields());
  };

  const handleAddTruckToGroup = async (date: string) => {
    if (!email) {
      toast({
        title: 'Error',
        description: 'Something went wrong',
        variant: 'destructive',
      });
      return;
    }

    const res = await addTruckToTruckList(
      email.id,
      email.threadID,
      dayjs(date).toISOString()
    );
    if (res.isOk()) {
      toast({ title: 'Truck added to Truck List', variant: 'success' });
      appendTruck({ ...res.value.createdTruck, length: '' });
    } else {
      toast({ title: 'Error adding new Truck', variant: 'destructive' });
    }
  };

  const handleRemoveTruck = async (idx: number) => {
    const { id } = truckArrayWatcher[idx];
    if (!email || !id) {
      toast(getToastForGenericError());
      return;
    }

    const res = await deleteTruckFromTruckList(email.id, email.threadID, id);
    if (res.isOk()) {
      toast({ title: 'Truck deleted from Truck List', variant: 'success' });
      removeTruck(idx);
    } else {
      toast({ title: 'Error deleting Truck', variant: 'destructive' });
    }
  };

  const handleApplyDefaultDropoff = () => {
    trucksArray.forEach((_, idx) => {
      setValue(
        `trucks.${idx}.dropoffLocation.city`,
        getValues(`default.city`) ?? ''
      );
      setValue(
        `trucks.${idx}.dropoffLocation.state`,
        getValues(`default.state`) ?? ''
      );
      setValue(
        `trucks.${idx}.dropoffDate`,
        dayjs(getValues('default.dropoffDate')).toISOString()
      );
    });

    resetField('default.city');
    resetField('default.state');
    resetField('default.dropoffDate');
  };

  const handleUpdateGroupDate = (currentDate: string) => {
    const updatedDate = dayjs(getValues('update.pickupDate')).toISOString();

    if (!updatedDate) return;

    trucksArrayByPickupDate[currentDate].forEach((t: any) => {
      setValue(`trucks.${t.arrayIdx}.pickupDate`, updatedDate);
    });

    if (!activeTabs.includes('truck-group-' + updatedDate)) {
      setActiveTabs((oldActiveTabs) => [
        ...oldActiveTabs,
        'truck-group-' + updatedDate,
      ]);
    }

    setDateEditing('');
  };

  const handleAddNewGroup = () => {
    const createdDate = dayjs(getValues('create.pickupDate')).toISOString();

    handleAddTruckToGroup(createdDate);
    if (!activeTabs.includes('truck-group-' + createdDate)) {
      setActiveTabs((oldActiveTabs) => [
        ...oldActiveTabs,
        'truck-group-' + dayjs(createdDate).format('YYYY-MM-DD'),
      ]);
    }

    resetField('create.pickupDate');
  };

  useEffect(() => {
    if (!trucksArrayByPickupDate) return;

    setActiveTabs([
      'carrier-details',
      ...Object.keys(trucksArrayByPickupDate).map(
        (pickupDate) => `truck-group-${pickupDate}`
      ),
    ]);
  }, []);

  const carrierValidationErrors = formValidationErrors?.carrier
    ? Object.keys(formValidationErrors.carrier).length
    : null;

  return (
    <ExtendedFormProvider
      aiDefaultValues={email?.labels.includes(EmailLabels.TruckList) ?? false}
      aiIconOnly={true}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          {showCarrierSelector ? (
            <div className='rounded-md bg-yellow-50 mt-8 mb-8 p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0 pt-1.5'>
                  <TriangleAlert
                    aria-hidden='true'
                    className='h-4 w-4 text-yellow-500'
                  />
                </div>
                <div className='ml-3'>
                  <h3 className='leading-8 text-sm font-medium text-yellow-600'>
                    Multiple carriers were found, please select one below:
                  </h3>
                  <div>
                    <Controller
                      name={`update.carrier`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value as string}
                        >
                          <SelectTrigger className='w-full mt-1'>
                            <SelectValue placeholder={'Choose'} />
                          </SelectTrigger>
                          <SelectContent>
                            {Object.values(carriers).map((carrier) => (
                              <SelectItem
                                key={carrier.name}
                                value={carrier.name}
                              >
                                {carrier.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showNoCarrierMessage ? <NoCarrierAvailable /> : null}

          <Accordion
            type='multiple'
            value={activeTabs}
            onValueChange={setActiveTabs}
            className='w-full'
          >
            <TruckListSectionAccordionItem
              key={'carrier-details'}
              icon={<BadgeInfoIcon />}
              name={`carrier-details`}
              label={`Carrier Details`}
              contentClassName={`px-2`}
              triggerClassName={`
                ${carrierValidationErrors ? 'bg-red-200 -mx-4 px-4 mb-4' : ''}`}
              showEditIcon={false}
            >
              <RedwoodCarrierFieldset
                handleValidateCarrier={handleValidateCarrier}
                loading={loading}
              />
            </TruckListSectionAccordionItem>

            <TruckListSectionAccordionItem
              key={'default-dropoff'}
              icon={<MapPinIcon />}
              name={`default-dropoff`}
              label={`Default Dropoff`}
              contentClassName='px-2'
              showEditIcon={false}
            >
              <RedwoodDefaultDropoffFieldset
                handleApplyDefaultDropoff={handleApplyDefaultDropoff}
              />
            </TruckListSectionAccordionItem>

            {trucksArrayByPickupDate
              ? Object.entries(trucksArrayByPickupDate)
                  ?.sort((a, b) => dayjs(a[0]).unix() - dayjs(b[0]).unix())
                  .map(([pickupDate, trucksInGroup]: any) => {
                    if (!trucksInGroup.length) return;

                    const hasPickupDateError = showInvalidPickupDate.find(
                      (invalidPickupDate) =>
                        invalidPickupDate.dateGroup === pickupDate
                    );
                    const trucksIndex = trucksInGroup.map(
                      (t: any) => t.arrayIdx
                    );
                    const trucksIds = trucksInGroup.map((t: any) => t.id);
                    const hasFormValidationError = formValidationErrors?.trucks
                      ? Object.keys(formValidationErrors.trucks).some((key) =>
                          trucksIndex.includes(Number(key))
                        )
                      : null;
                    const hasSubmissionError = trucksIds.some((id: any) =>
                      truckListErrors?.truckErrors
                        ? Object.keys(truckListErrors.truckErrors).includes(
                            String(id)
                          )
                        : null
                    );

                    return (
                      <TruckListSectionAccordionItem
                        key={pickupDate}
                        icon={<CalendarIcon />}
                        name={`truck-group-${pickupDate}`}
                        label={`${dayjs(pickupDate).format('MMM D - dddd')}`}
                        contentClassName={`truck-group-${pickupDate} px-2`}
                        triggerClassName={`
                          ${hasFormValidationError || hasSubmissionError ? 'bg-red-200 -mx-4 px-4 mb-4' : ''}
                        `}
                        showEditIcon={true}
                        dateEditing={dateEditing}
                        setDateEditing={setDateEditing}
                      >
                        {dateEditing === `truck-group-${pickupDate}` ||
                        hasPickupDateError ? (
                          <div className='mb-6'>
                            <TruckDateTimeInput
                              name={`update.pickupDate`}
                              label='Updated Pickup'
                            />

                            <p className='text-red-500 text-xs mb-2'>
                              {hasPickupDateError
                                ? `${hasPickupDateError.err}, please update Pickup`
                                : ''}
                            </p>

                            <Button
                              className='w-full h-8 text-[14px] mt-2'
                              onClick={() => handleUpdateGroupDate(pickupDate)}
                              disabled={!getValues('update.pickupDate')}
                            >
                              {`Update ${trucksInGroup.length} truck${trucksInGroup.length > 1 ? 's' : ''}`}
                            </Button>
                          </div>
                        ) : null}

                        <RedwoodTruckFieldset
                          control={control}
                          errors={errors}
                          trucksInGroup={trucksInGroup}
                          trucksArrayByPickupDate={trucksArrayByPickupDate}
                          handleRemoveTruck={handleRemoveTruck}
                          recreateTruck={recreateTruck}
                        />

                        <Button
                          className='w-full h-8 text-[14px] text-orange-main border border-orange-main hover:border-orange-main'
                          onClick={() => handleAddTruckToGroup(pickupDate)}
                          type='button'
                          variant='outline'
                        >
                          Add new Truck
                        </Button>
                      </TruckListSectionAccordionItem>
                    );
                  })
              : null}
          </Accordion>

          <RedwoodNewGroupFieldset
            handleAddNewGroup={handleAddNewGroup}
            getValues={getValues}
          />

          <Button
            buttonName={ButtonName.SubmitTruckList}
            buttonNamePosthog={ButtonNamePosthog.SubmitTruckList}
            type='submit'
            className='w-full mt-8'
          >
            {loading ? (
              <ButtonLoader />
            ) : truckListServiceName != '' ? (
              `Submit to ${truckListServiceName}`
            ) : (
              'Submit'
            )}
          </Button>
        </form>
      </FormProvider>
    </ExtendedFormProvider>
  );
}

export interface RedwoodTruckListInputs {
  carrier: {
    name: MaybeUndef<string>;
    dot: MaybeUndef<string>;
    mc: MaybeUndef<string>;
    contact: {
      name: MaybeUndef<string>;
      email: MaybeUndef<string>;
    };
  };
  default: {
    city: MaybeUndef<string>;
    state: MaybeUndef<string>;
    dropoffDate: MaybeUndef<string>;
  };
  update: {
    pickupDate: MaybeUndef<string>;
    carrier: MaybeUndef<string>;
  };
  create: {
    pickupDate: MaybeUndef<string>;
  };
  trucks: FormTruck[];
}
