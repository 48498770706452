import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export function normalizeUTCDate(date: Date) {
  const localOffset = date.getTimezoneOffset();
  return new Date(date.getTime() + localOffset * 60_000);
}

export function denormalizeUTCDate(date: Date) {
  const localOffset = date.getTimezoneOffset();
  return new Date(date.getTime() - localOffset * 60_000);
}

export function formatDateForTimeInput(date: Date) {
  if (!date) {
    return;
  }
  let hours = `${date.getHours()}`;
  let minutes = `${date.getMinutes()}`;

  if (hours.length < 2) {
    hours = `0${hours}`;
  }

  if (minutes.length < 2) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
}

/**
 * Converts timestamp to `targetTimezone` and formats using Dayjs. If no timezone provided, Dayjs defaults to user's locale.
 *
 * @example

 * const date = dayjs('2024-03-30T15:30:00Z');
 * const formattedStr = formatTimestamp(date, 'MM/DD HH:mm z', 'America/Los_Angeles'); // Output: '03/30 08:30 PDT'
 * const noTZParam = formatTimestamp(date, 'MM/DD HH:mm z'); // Output: '03/30 11:30 EDT' if user client's locale is America/New_York
 * const noTZFormatOrParam = formatTimestamp(date, 'MM/DD HH:mm'); // Output: '03/30 11:30' if user client's locale is America/New_York
 * const noTZInFormat = formatTimestamp(date, 'MM/DD HH:mm', 'America/Los_Angeles'); // Output: '03/30 08:30'
 *
 * @param {date} Dayjs - The timestamp to be formatted.
 * @param {format} string - Format using dayjs syntax. Docs: https://day.js.org/docs/en/display/format. 
 * Most relevant to know is `'z'` adds the abbreviated named offset (aka EST/EDT, PST/PDT, etc)
 * @param {targetTimezone} string - The target IANA timezone, e.g. America/New_York to convert to. 
 * If left empty, then `date` is formatted in user's locale (see examples above)
 */
export function formatTimestamp(
  date: Dayjs,
  format: string,
  targetTimezone?: string
): string {
  if (targetTimezone) {
    return date.tz(targetTimezone).format(format);
  }

  return date.format(format);
}
