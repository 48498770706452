import { MaybeUndef } from 'types/UtilityTypes';

export type LoadAttributes = {
  ID: FieldAttributes;
  CreatedAt: FieldAttributes;
  UpdatedAt: FieldAttributes;
  DeletedAt: FieldAttributes;
  freightTrackingID: FieldAttributes;
  isPlaceholder: FieldAttributes;
  status: FieldAttributes;
  operator: FieldAttributes;
  poNums: FieldAttributes;
  rateData: {
    collectionMethod: FieldAttributes;
    revenueCode: FieldAttributes;
    customerRateType: FieldAttributes;
    customerRateNumUnits: FieldAttributes;
    customerLineHaulRate: FieldAttributes;
    customerLineHaulUnit: FieldAttributes;
    customerLineHaulCharge: FieldAttributes;
    customerTotalCharge: FieldAttributes;
    fscPercent: FieldAttributes;
    fscPerMile: FieldAttributes;
    carrierRateType: FieldAttributes;
    carrierRateNumUnits: FieldAttributes;
    carrierLineHaulRate: FieldAttributes;
    carrierLineHaulUnit: FieldAttributes;
    carrierLineHaulCharge: FieldAttributes;
    carrierTotalCost: FieldAttributes;
    carrierMaxRate: FieldAttributes;
    netProfitUSD: FieldAttributes;
    profitPercent: FieldAttributes;
  };
  customer: {
    externalTMSID: FieldAttributes;
    name: FieldAttributes;
    addressLine1: FieldAttributes;
    addressLine2: FieldAttributes;
    city: FieldAttributes;
    state: FieldAttributes;
    zipCode: FieldAttributes;
    country: FieldAttributes;
    contact: FieldAttributes;
    phone: FieldAttributes;
    email: FieldAttributes;
    refNumber: FieldAttributes;
  };
  billTo: {
    externalTMSID: FieldAttributes;
    name: FieldAttributes;
    addressLine1: FieldAttributes;
    addressLine2: FieldAttributes;
    city: FieldAttributes;
    state: FieldAttributes;
    zipCode: FieldAttributes;
    contact: FieldAttributes;
    phone: FieldAttributes;
    email: FieldAttributes;
  };
  pickup: {
    externalTMSID: FieldAttributes;
    name: FieldAttributes;
    addressLine1: FieldAttributes;
    addressLine2: FieldAttributes;
    city: FieldAttributes;
    state: FieldAttributes;
    zipCode: FieldAttributes;
    country: FieldAttributes;
    contact: FieldAttributes;
    phone: FieldAttributes;
    email: FieldAttributes;
    businessHours: FieldAttributes;
    refNumber: FieldAttributes;
    readyTime: FieldAttributes;
    apptTime: FieldAttributes;
    apptNote: FieldAttributes;
  };
  consignee: {
    externalTMSID: FieldAttributes;
    name: FieldAttributes;
    addressLine1: FieldAttributes;
    addressLine2: FieldAttributes;
    city: FieldAttributes;
    state: FieldAttributes;
    zipCode: FieldAttributes;
    country: FieldAttributes;
    contact: FieldAttributes;
    phone: FieldAttributes;
    email: FieldAttributes;
    businessHours: FieldAttributes;
    refNumber: FieldAttributes;
    mustDeliver: FieldAttributes;
    apptTime: FieldAttributes;
    apptNote: FieldAttributes;
  };
  carrier: {
    mcNumber: FieldAttributes;
    dotNumber: FieldAttributes;
    name: FieldAttributes;
    phone: FieldAttributes;
    notes: FieldAttributes;
    dispatcher: FieldAttributes;
    sealNumber: FieldAttributes;
    scac: FieldAttributes;
    firstDriverName: FieldAttributes;
    firstDriverPhone: FieldAttributes;
    secondDriverName: FieldAttributes;
    secondDriverPhone: FieldAttributes;
    email: FieldAttributes;
    dispatchCity: FieldAttributes;
    dispatchState: FieldAttributes;
    truckNumber: FieldAttributes;
    trailerNumber: FieldAttributes;
    rateConfirmationSent: FieldAttributes;
    confirmationSentTime: FieldAttributes;
    confirmationReceivedTime: FieldAttributes;
    dispatchedTime: FieldAttributes;
    expectedPickupTime: FieldAttributes;
    pickupStart: FieldAttributes;
    pickupEnd: FieldAttributes;
    expectedDeliveryTime: FieldAttributes;
    deliveryStart: FieldAttributes;
    deliveryEnd: FieldAttributes;
    signedBy: FieldAttributes;
  };
  specifications: {
    totalInPalletCount: FieldAttributes;
    totalOutPalletCount: FieldAttributes;
    totalPieces: FieldAttributes;
    commodities: FieldAttributes;
    numCommodities: FieldAttributes;
    totalWeight: FieldAttributes;
    billableWeight: FieldAttributes;
    totalDistance: FieldAttributes;

    minTempFahrenheit: FieldAttributes;
    maxTempFahrenheit: FieldAttributes;
  };
  notes: [FieldAttributes];
};

export type FieldAttributes = {
  isNotSupported: boolean;
  isReadOnly: boolean;
  maxLength: number;
};

export const initFieldAttributes = {
  isNotSupported: false,
  isReadOnly: false,
  maxLength: 0,
};

// Re-format attributes as a keyed array so React form components can use same
// TPath convention to access field attributes
export type FieldAttributesArray = { [x: string]: FieldAttributes }[];

export function getFieldAttribute(
  attrs: FieldAttributesArray,
  fieldName: string
): MaybeUndef<FieldAttributes> {
  const fieldObj = attrs.find((obj) => fieldName in obj);

  return fieldObj ? fieldObj[fieldName] : undefined;
}
