import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type LaneHistoryRequest = {
  destinationCity: string;
  destinationState: string;
  destinationZip: string;
  originCity: string;
  originState: string;
  originZip: string;
  transportType: string;
};

export type LaneHistoryResponse = {
  week: string;
  averageRate: number;
  lowestRate: number;
  quotes: number;
  lowestCarrierName: string;
};

export async function getLaneHistory(
  request: LaneHistoryRequest
): Promise<Result<LaneHistoryResponse[], ApiError>> {
  try {
    const response = await axios.post<LaneHistoryResponse[]>(
      `quote/private/laneHistory`,
      request
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getLaneHistory' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get lane history' });
    }

    return err({
      message: error.response?.data.message || 'Failed to get lane history',
    });
  }
}
