import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';

import AuthService from '@auth/AuthService';
import APP_VERSION from '@constants/AppVersion';
import DRUMKIT_API_URL from '@constants/DrumkitApiUrl';

import isProd from './isProd';

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  _retry?: boolean;
  _bypassInterceptor?: boolean;
}

const instance = axios.create({
  baseURL: isProd() ? DRUMKIT_API_URL : '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

instance.interceptors.request.use(async (config) => {
  const authHeader = await AuthService.getAuthHeader();
  config.headers['Vesta-Version'] = APP_VERSION;
  console.log('axios isProd: ', isProd());

  config.headers = {
    ...config.headers,
    ...authHeader,
  } as AxiosRequestHeaders;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalConfig = error.config as AxiosRequestConfigExtended;

    // checks if bypassInterceptor is true.
    if (originalConfig._bypassInterceptor) {
      return Promise.reject(error);
    }

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      //dynamically import AuthService to avoid circular dependency
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      const AuthService = require('../auth/AuthService').default;
      try {
        const newConfig = await AuthService.refreshToken();

        if (originalConfig.headers) {
          originalConfig.headers.Authorization = `Bearer ${newConfig}`;
        } else {
          originalConfig.headers = { Authorization: `Bearer ${newConfig}` };
        }

        return instance(originalConfig);
      } catch (err) {
        AuthService.logOut();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
