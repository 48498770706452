import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export default async function deleteTruckFromTruckList(
  emailID: number,
  threadID: string,
  truckID: number
): Promise<Result<null, ApiError>> {
  const params = [
    `threadID=${encodeURIComponent(threadID)}`,
    `emailID=${emailID}`,
  ];

  try {
    const response = await axios.delete(
      `/trucklist/truck/delete/${truckID}?${params.join('&')}`
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'deleteTruckFromTruckList' });

    return err({
      message: 'Oops, something went wrong!',
    });
  }
}
