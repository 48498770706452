import { FormStorageServiceInterface } from './types';

/* Full key format: formState_{tmsName}_{threadId} */

export const FormStorageService: FormStorageServiceInterface = {
  storageKey: 'formState',

  saveFormState<T, U>(
    key: string,
    formState: { values: T; dirtyFields: U }
  ): void {
    try {
      const stateToSave = JSON.stringify(formState);
      localStorage.setItem(`${this.storageKey}_${key}`, stateToSave);
    } catch (error) {
      throw new Error('Error saving form state:', error as Error);
    }
  },

  getFormState<T, U>(key: string): { values: T; dirtyFields: U } | undefined {
    try {
      const storedState = localStorage.getItem(`${this.storageKey}_${key}`);
      if (storedState) {
        return JSON.parse(storedState) as { values: T; dirtyFields: U };
      }
    } catch (error) {
      throw new Error('Error getting form state:', error as Error);
    }
    return undefined;
  },

  clearFormState(key: string): void {
    try {
      localStorage.removeItem(`${this.storageKey}_${key}`);
    } catch (error) {
      throw new Error('Error clearing form state:', error as Error);
    }
  },
};
