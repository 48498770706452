import ContentLoader from 'react-content-loader';

export default function SidebarLoader(): JSX.Element {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={600}
      backgroundColor='#f0f0f0'
      foregroundColor='#e0e0e0'
    >
      {/* PRO # */}
      <rect x='20' y='20' rx='5' ry='5' width='130' height='20' />

      {/* additional space */}
      <rect x='20' y='60' rx='0' ry='0' width='0' height='20' />

      {/* Overview */}
      <rect x='20' y='80' rx='5' ry='5' width='260' height='20' />

      {/* Labels */}
      <rect x='20' y='120' rx='5' ry='5' width='65' height='20' />
      <rect
        x='20'
        y='155'
        rx='0.375rem'
        ry='0.375rem'
        width='260'
        height='60'
      />

      {/* additional space */}
      <rect x='20' y='200' rx='0' ry='0' width='0' height='20' />

      {/* Status */}
      <rect x='20' y='240' rx='5' ry='5' width='65' height='20' />
      <rect
        x='20'
        y='275'
        rx='0.375rem'
        ry='0.375rem'
        width='260'
        height='60'
      />

      {/* additional space */}
      <rect x='20' y='350' rx='0' ry='0' width='0' height='30' />

      {/* Appointment Scheduling */}
      <rect x='20' y='390' rx='5' ry='5' width='260' height='20' />

      {/* Load Information */}
      <rect x='20' y='430' rx='5' ry='5' width='260' height='20' />
    </ContentLoader>
  );
}
