import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Warehouse } from 'types/Warehouse';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type WarehouseSearchResponse = {
  warehouses: Warehouse[];
};

export async function getWarehouseSearch(
  search: string
): Promise<Result<WarehouseSearchResponse, ApiError>> {
  try {
    const url = `/warehouse/search?search=${encodeURIComponent(search)}`;
    const { data } = await axios.get<WarehouseSearchResponse>(url);

    return ok(data);
  } catch (error) {
    captureException(error, { functionName: 'getWarehouseSearch' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to search warehouses' });
    }

    return err({
      message: error.response?.data.message || 'Failed to search warehouses',
    });
  }
}
