import { Button } from 'components/Button';

import { TruckTextInput } from '../../TruckListFormFields';

type RedwoodDefaultDropoffFieldsetProps = {
  handleApplyDefaultDropoff: () => void;
};

export const RedwoodDefaultDropoffFieldset = ({
  handleApplyDefaultDropoff,
}: RedwoodDefaultDropoffFieldsetProps) => (
  <>
    <div className='grid grid-cols-3 gap-4 mt-2 mx-0 w-full'>
      <div className='col-span-2'>
        <TruckTextInput name={`default.city`} label='City' />
      </div>

      <div className='col-span-1'>
        <TruckTextInput
          name={`default.state`}
          label='State'
          aiIconOnly={true}
        />
      </div>
    </div>

    <Button
      className='w-full h-8 text-[14px] mt-2'
      onClick={handleApplyDefaultDropoff}
      type='button'
    >
      Apply to all Trucks
    </Button>
  </>
);
