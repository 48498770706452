import * as React from 'react';

import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';

import RequiredHint from 'components/RequiredHint';
import { cn } from 'utils/shadcn';

import AIHintLabel, { AIHintProps } from './AIHint';

const labelVariants = cva(
  'leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> &
    AIHintProps & { required?: boolean } // AIHintProps.name can be empty string to skip AI label
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className, 'text-grayscale-content-3')}
    {...props}
  >
    <span className='flex flex-row items-center text-sm whitespace-nowrap'>
      {props.children}
      {props.required && <RequiredHint />}
      {props.name && <AIHintLabel {...props} />}
    </span>
  </LabelPrimitive.Root>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
