import { createContext } from 'react';

import { initLoadAttributes } from 'types/Email';
import { Load } from 'types/Load';
import { FieldAttributes, LoadAttributes } from 'types/LoadAttributes';
import { MaybeUndef } from 'types/UtilityTypes';

// Re-format attributes as a keyed array so React form components can use same
// TPath convention to access field attributes
export type FieldAttributesArray = { [x: string]: FieldAttributes }[];

export function getFieldAttribute(
  attrs: FieldAttributesArray,
  fieldName: string
): MaybeUndef<FieldAttributes> {
  return attrs.find((obj) => fieldName in obj)
    ? attrs.find((obj) => fieldName in obj)![fieldName]
    : undefined;
}

export type LoadContextType = {
  fieldAttributes: FieldAttributesArray;
  loadAttrsObj: LoadAttributes;
  invalidateLoad: (
    load?: Load,
    loadAttributes?: LoadAttributes
  ) => Promise<void>;
};

export const LoadContext = createContext<LoadContextType>({
  fieldAttributes: [],
  loadAttrsObj: initLoadAttributes,
  invalidateLoad: () => Promise.resolve(),
});

export function isUnsupportedSection(
  attrs: FieldAttributesArray,
  subsection: string
): boolean {
  return attrs
    .filter((item) => Object.keys(item)[0].startsWith(subsection))
    .every((item) => Object.values(item)[0].isNotSupported);
}
