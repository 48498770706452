// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { ChartConfig } from '@components/ui/chart';

export const chartConfig = {
  averageRate: {
    label: 'Average Rate',
    color: '#f98600', // tailwind config orange-main
  },
  lowestRate: {
    label: 'Lowest Rate',
    color: '#e92c2c', // tailwind config red-main
  },
} satisfies ChartConfig;

// reference: QuoteView/Quoting/RequestQuickQuote.tsx
