import * as Sentry from '@sentry/browser';

import APP_VERSION from '@constants/AppVersion';
import getEmailAddress from '@utils/getEmailAddress';

export default async function captureException(
  error: unknown,
  tags?: { [key: string]: any }
) {
  const email = await getEmailAddress();

  Sentry.withScope((scope) => {
    if (email) {
      scope.setUser({ email });
    }

    if (tags) {
      Object.entries(tags).forEach(([key, value]) => {
        scope.setTag(key, value);
      });
    }

    scope.setTag('version', APP_VERSION);
    Sentry.captureException(error);
  });
}
