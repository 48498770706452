import React from 'react';

import dayjs from 'dayjs';
import { NotebookPenIcon } from 'lucide-react';

import useTMSContext from 'hooks/useTMSContext';
import { Note } from 'types/Load';
import TMS from 'types/enums/Integrations';

type NoteProps = Note;

type NoteTimelineProps = {
  events: NoteProps[];
};

const NoteTimeline: React.FC<NoteTimelineProps> = ({ events }) => {
  if (!events) {
    return null;
  }

  const isTZAgnostic = useTMSContext().tmsName === TMS.Aljex;
  const shortTZ = dayjs().tz().format('z');
  const normalizedTZ = isTZAgnostic ? '' : ` ${shortTZ}`;

  return (
    <ol className='relative border-s ms-4 border-violet-blue-st-patrick dark:border-violet-400 px-2'>
      {events
        .sort(
          (a, b) =>
            // Sort in descending order (most recent first)
            new Date(b.updatedBy).getTime() - new Date(a.updatedBy).getTime()
        )
        .map((event) => (
          <li className='mb-10 ms-3' key={null}>
            <span className='absolute flex items-center justify-center w-6 h-6 mr-2 bg-white rounded-full -start-3 ring-1 ring-offset-4 ring-violet-blue-st-patrick dark:ring-gray-900 dark:bg-blue-900'>
              <NotebookPenIcon size={'sm'} strokeWidth={1} />
            </span>
            <div className='flex items-start font-normal  text-gray-500 dark:text-gray-500 text-xs leading-none mt-6 justify-between'>
              <span className='flex text-left text-sm'>{event.updatedBy}</span>
              <span>
                <time className='flex text-right text-xs font-normal leading-none text-gray-500 dark:text-gray-500 pl-6'>
                  {(() => {
                    // Handle if somehow no timestamp provided
                    if (!event.createdAt) {
                      return <p>N/A</p>;
                    }

                    return (
                      <>
                        {dayjs(event.createdAt)
                          .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                          .format('M/D/YY')}
                        <br />
                        {`${dayjs(event.createdAt)
                          .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                          .format('H:mm')} ${normalizedTZ}`}
                      </>
                    );
                  })()}
                </time>
              </span>
            </div>

            {event.isException !== null && (
              <div>
                <span
                  className={`text-neutral-400 text-xs leading-5 inline ${event.isException ? 'bg-red-bg text-red-main' : ''}`}
                >
                  Issue:{' '}
                </span>
                <span
                  className={`text-gray-500 text-xs leading-5 inline ${event.isException ? 'text-red-main bg-red-bg px-2' : ''}`}
                >
                  {`${event.isException}`}
                </span>
              </div>
            )}

            {event.isOnTime !== null && (
              <div>
                <span
                  className={`text-neutral-400 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg' : ''}`}
                >
                  On Time:{' '}
                </span>
                <span
                  className={`text-gray-500 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg px-2' : ''}`}
                >
                  {`${event.isOnTime}`}
                </span>
              </div>
            )}

            <div>
              <span className='text-neutral-400 text-xs leading-5 inline'>
                Notes:{' '}
              </span>
              <span className='text-gray-500 text-xs leading-5 inline'>
                {event.note}
              </span>
            </div>
          </li>
        ))}
    </ol>
  );
};

export { NoteTimeline, NoteProps, NoteTimelineProps };
