import { AvailableTabs } from 'constants/SidebarTabs';
import { HostInterface } from 'lib/hosts/interface';
import { Dropoff, NormalizedLoad, Pickup } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

export class McleodEnterprise implements HostInterface {
  getFieldMoniker(key: keyof NormalizedLoad): string {
    switch (key) {
      case 'externalTMSID':
        return '';
      case 'freightTrackingID':
        return 'Load ID';
      default:
        return '';
    }
  }
  parseFreightTrackingID(): string {
    return '';
  }

  // ExternalTMSID = FreightTrackingID for Aljex
  parseExternalTMSID(): string {
    return this.parseFreightTrackingID();
  }

  parsePickup(): Maybe<Pickup> {
    return null;
  }

  parseDropoff(): Maybe<Dropoff> {
    return null;
  }

  determineDefaultLoadTab(): AvailableTabs {
    return AvailableTabs.LoadInformation;
  }

  shouldAutomaticallyOpenDrumkit(): boolean {
    return false;
  }
}
