import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import captureException from 'utils/captureException';

type PutViewedLoadHistoryResponse = {
  viewedLoadsHistory: string[];
};

type PutViewedLoadHistoryError = {
  message: string;
};

export default async function putViewedLoadHistory(
  loadID: string
): Promise<Result<PutViewedLoadHistoryResponse, PutViewedLoadHistoryError>> {
  try {
    const resp = await axios.put<PutViewedLoadHistoryResponse>(
      `load/viewed/${loadID}`
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'putViewedLoadHistory' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to put load on history' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message:
        error.response?.data.message ||
        error.response?.data ||
        'Failed to put load on history',
    });
  }
}
