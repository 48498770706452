import { useEffect } from 'react';
import { FieldPath, UseFormReturn } from 'react-hook-form';

import { RHFDebounceSelect } from 'components/input/RHFDebounceSelect';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { getCustomers } from 'lib/api/getCustomers';
import { NormalizedLoad, TMSCustomer } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericCompanySearchableFields,
  customerSearchHandler,
  mapCustomerToAntdOptions,
} from 'utils/loadInfoAndBuilding';

import { LoadBuildingTextInput } from '../McleodLoadBuildingForm';

export type CustomerSectionFormProps = {
  formMethods: UseFormReturn<NormalizedLoad>;
  customers: Maybe<TMSCustomer[]>;
  setCustomers: React.Dispatch<React.SetStateAction<Maybe<TMSCustomer[]>>>;
  isLoadingCustomers: boolean;
  setIsLoadingCustomers: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CustomerSectionForm({
  formMethods,
  customers,
  setCustomers,
  isLoadingCustomers,
  setIsLoadingCustomers,
}: CustomerSectionFormProps): JSX.Element {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;
  const { toast } = useToast();

  const { tmsIntegrations } = useServiceFeatures();

  const handleRefreshCustomers = async () => {
    setIsLoadingCustomers(true);

    const res = await getCustomers(tmsIntegrations[0]?.id, true);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
    setIsLoadingCustomers(false);
  };

  const handleCustomerSearch = async (
    field: GenericCompanySearchableFields,
    value: string
  ) => {
    return customerSearchHandler({
      tmsID: tmsIntegrations?.[0]?.id,
      customers,
      setCustomers,
      field,
      value,
    });
  };

  const watchedCustomer = watch('customer');
  const watchedAddress2 = watch('customer.addressLine2');

  useEffect(() => {
    if (watchedCustomer?.externalTMSID) {
      const selectedCustomer = customers?.find(
        (c) => c.externalTMSID === watchedCustomer?.externalTMSID
      );
      if (!selectedCustomer) {
        return;
      }

      Object.entries(selectedCustomer).forEach(([key, value]) => {
        setValue(`customer.${key}` as FieldPath<NormalizedLoad>, value, {
          shouldDirty: true, // this ensures the AI-label is handled correctly
        });
      });
    }
  }, [watchedCustomer?.externalTMSID]);

  return (
    <>
      <RHFDebounceSelect
        required
        name={`customer.externalTMSID`}
        label='Name'
        control={control}
        errors={errors}
        data={customers}
        isLoading={isLoadingCustomers}
        refreshHandler={handleRefreshCustomers}
        fetchOptions={handleCustomerSearch}
        mapOptions={mapCustomerToAntdOptions}
      />

      <LoadBuildingTextInput
        name='customer.addressLine1'
        label='Address Line 1'
        readOnly={true}
      />

      {!!watchedAddress2 && (
        <LoadBuildingTextInput
          name='customer.addressLine2'
          label='Address Line 2'
          readOnly={true}
        />
      )}
      <LoadBuildingTextInput
        name='customer.city'
        label='City'
        readOnly={true}
      />

      <div className='grid grid-cols-2 w-full m-0 gap-2'>
        <LoadBuildingTextInput
          name='customer.state'
          label='State'
          readOnly={true}
        />
        <LoadBuildingTextInput
          name='customer.zipCode'
          label='Zip Code'
          placeholder='29838'
          readOnly={true}
        />
      </div>

      <LoadBuildingTextInput name='customer.refNumber' label='Ref #/ BOL' />

      <LoadBuildingTextInput
        name={'poNums'}
        label='PO #'
        placeholder='Comma-separated list (e.g. 123,456,789)'
      />
    </>
  );
}
