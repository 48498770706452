import { MaybeUndef } from 'types/UtilityTypes';

export const isElementInView = (
  drumkitAppContainer: MaybeUndef<Element>,
  suggestionMustScrollUntil: MaybeUndef<HTMLElement>
) => {
  if (!drumkitAppContainer || !suggestionMustScrollUntil) return false;

  const windowTop = drumkitAppContainer.scrollTop;
  const windowBottom = windowTop + drumkitAppContainer.clientHeight;

  const elemTop = suggestionMustScrollUntil.offsetTop;
  const elemBottom = elemTop + suggestionMustScrollUntil.clientHeight;

  return elemBottom <= windowBottom && elemTop >= windowTop;
};
