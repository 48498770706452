import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type ValidatedPONumber = {
  poNumber: string;
  isValid: boolean;
  error: string;
};

type ValidateApptResponse = {
  validatedPONumbers: ValidatedPONumber[];
};

export async function validateAppt(
  warehouseId: string,
  source: string,
  poNumbers: string[]
): Promise<Result<ValidateApptResponse, ApiError>> {
  try {
    const response = await axios.post<ValidateApptResponse>(`appt/validate`, {
      warehouseId,
      source,
      poNumbers,
    });
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'validateAppt' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to validate appointment' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to validate appointment',
    });
  }
}
