import Section, { DataElement } from 'components/Section';
import { useLoadContext } from 'hooks/useLoadContext';
import useTMSContext from 'hooks/useTMSContext';
import { NormalizedLoad } from 'types/Load';
import TMS from 'types/enums/Integrations';

interface MiniLoadInfoProps {
  load: NormalizedLoad;
}

export function MiniLoadInfo({ load }: MiniLoadInfoProps) {
  const { loadAttrsObj: attrs } = useLoadContext();
  const { tmsName } = useTMSContext();
  const pickup = load.pickup;
  const dropoff = load.consignee;
  const specs = load.specifications;
  const specsAttrs = attrs.specifications;

  const specsArray: DataElement[] = [
    ...(specsAttrs.commodities.isNotSupported
      ? []
      : [
          {
            field: 'commodities',
            label: 'Commodities',
            value: specs.commodities,
          },
        ]),
    ...(specsAttrs.totalWeight.isNotSupported
      ? []
      : [
          {
            field: 'totalWeight',
            label: `Weight${specs.totalWeight?.unit ? ' (' + specs.totalWeight?.unit + ')' : ''}`,
            value: specs.totalWeight?.val ?? '',
            className: tmsName === TMS.Relay ? 'col-span-1' : '',
          },
        ]),
    ...(specsAttrs.totalDistance.isNotSupported
      ? []
      : [
          {
            field: 'totalDistance',
            label: `Distance${specs.totalDistance?.unit ? ' (' + specs.totalDistance?.unit + ')' : ''}`,
            value: specs.totalDistance?.val ?? '',
            className: tmsName === TMS.Relay ? 'col-span-1' : '',
          },
        ]),
    ...(specsAttrs.totalOutPalletCount.isNotSupported
      ? []
      : [
          {
            field: 'pallets',
            label: 'Pallets',
            value: specs.totalOutPalletCount,
            className: tmsName === TMS.Relay ? 'col-span-1' : '',
          },
        ]),
    ...(specsAttrs.totalPieces.isNotSupported
      ? []
      : [
          {
            field: 'pieces',
            label: `Pieces${specs.totalPieces?.unit ? ' (' + specs.totalPieces?.unit + ')' : ''}`,
            value: specs.totalPieces?.val ?? '',
            className: tmsName === TMS.Relay ? 'col-span-1' : '',
          },
        ]),
  ];

  return (
    <Section
      sectionPrefix={'specs'}
      objectData={[
        {
          field: 'pickup',
          label: `Pickup Street`,
          value: `${pickup.addressLine1}${pickup.addressLine2 && ', ' + pickup.addressLine2}`,
        },
        {
          field: 'pickup',
          label: `Pickup City & State`,
          value: `${pickup.city}${pickup.state && ', ' + pickup.state?.toUpperCase()} ${pickup.zipCode}`,
        },
        {
          field: 'dropoff',
          label: `Dropoff Street`,
          value: `${dropoff.addressLine1}${dropoff.addressLine2 && ', ' + dropoff.addressLine2}`,
        },
        {
          field: 'dropoff',
          label: `Dropoff City & State`,
          value: `${dropoff.city}${dropoff.state && ', ' + dropoff.state?.toUpperCase()} ${dropoff.zipCode}`,
        },
        ...specsArray,
      ]}
    />
  );
}
