import { AvailableTabs } from 'constants/SidebarTabs';
import { Dropoff, NormalizedLoad, Pickup } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

import { HostInterface } from './interface';

export class DelegatedGmail implements HostInterface {
  getFieldMoniker(_: keyof NormalizedLoad): string {
    return '';
  }

  parseFreightTrackingID = (): string => '';
  parseExternalTMSID = (): string => '';

  determineDefaultLoadTab = (): AvailableTabs => AvailableTabs.LoadInformation;

  shouldAutomaticallyOpenDrumkit(): boolean {
    // Automatically open when viewing an email
    return document.querySelector('[data-message-id]') !== null;
  }

  parsePickup = (): Maybe<Pickup> => null;
  parseDropoff = (): Maybe<Dropoff> => null;
}
