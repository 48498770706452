import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type GetCarrierQualificationResponse = {
  carrierExternalTMSID: string;
  isQualified: boolean;
};

export async function getCarrierQualification(
  tmsID: number,
  freightTrackingID: string,
  carrierExternalTMSID: string
): Promise<Result<GetCarrierQualificationResponse, ApiError>> {
  const params = [
    `tmsID=${encodeURIComponent(tmsID)}`,
    `freightTrackingID=${encodeURIComponent(freightTrackingID)}`,
    `carrierExternalTMSID=${encodeURIComponent(carrierExternalTMSID)}`,
  ];

  try {
    const response = await axios.get<GetCarrierQualificationResponse>(
      `/carriers/checkQualification?${params.join('&')}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getCarrierQualification' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get carrier qualification from TMS' });
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to get carrier qualification from TMS',
    });
  }
}
