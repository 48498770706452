import { AuthUser } from '@src/alexandria/types/AuthUser';
import { Maybe } from '@src/alexandria/types/UtilityTypes';
import captureException from '@utils/captureException';

// AuthService uses Office.roamingSettings to persist a user's Drumkit creds to all their Outlook sessions
export default class AuthService {
  // Callback function after saving custom roaming settings.
  static saveMyAddInSettingsCallback(asyncResult: Office.AsyncResult<void>) {
    if (asyncResult.status == Office.AsyncResultStatus.Failed) {
      captureException(asyncResult.error);
    }
  }

  static setCurrentUser(user: AuthUser) {
    Office.context.roamingSettings.set('currentUser', user);
    // Save roaming settings to the mailbox, so that they'll be available in the next session.
    Office.context.roamingSettings.saveAsync(
      AuthService.saveMyAddInSettingsCallback
    );
  }

  static async getCurrentUser(): Promise<Maybe<AuthUser>> {
    const currentUserValue = Office.context.roamingSettings.get('currentUser');

    if (currentUserValue) {
      return currentUserValue;
    }

    return null;
  }

  static async hasValidUserAuth(): Promise<boolean> {
    const currentUserValue = Office.context.roamingSettings.get('currentUser');

    // if user value is found, return true
    if (currentUserValue) return true;

    return false;
  }

  // Remove JWT from add-in settings to log user out from Drumkit API (not from Outlook itself)
  static async logOut() {
    Office.context.roamingSettings.remove('currentUser');
    // Save changes to the roaming settings for the mailbox, so that they'll be available in the next session.
    Office.context.roamingSettings.saveAsync(
      AuthService.saveMyAddInSettingsCallback
    );
  }

  static async getAuthHeader(): Promise<{
    Authorization: string;
  }> {
    const user = await AuthService.getCurrentUser();

    if (!user || !user.access_token) {
      AuthService.logOut();

      return { Authorization: 'Bearer ' };
    }

    return { Authorization: 'Bearer ' + user.access_token };
  }
}
