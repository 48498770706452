import { useEffect, useState } from 'react';

import { Circle, CircleCheck, Mail } from 'lucide-react';

import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Textarea } from 'components/Textarea';
import { Input } from 'components/input';
import { CarrierNetworkEmail, Quote } from 'types/QuoteRequest';
import { MaybeUndef } from 'types/UtilityTypes';
import { cn } from 'utils/shadcn';

interface mappedQuote {
  carrierEmail: string;
  bounced: boolean;
  responded: boolean;
  available: MaybeUndef<boolean>;
  price: number;
  notes: MaybeUndef<string>;
  url: string; // URL-encoded
}

type ReviewCarrierQuotesSectionProps = {
  emails: CarrierNetworkEmail[];
  quotes: Quote[];
};

export default function ReviewCarrierNetworkQuotesSection({
  emails,
  quotes,
}: ReviewCarrierQuotesSectionProps) {
  const [sortedQuotes, setSortedQuotes] = useState<mappedQuote[]>([]);
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState<number | null>(
    0
  );
  const [carrierCost, setCarrierCost] = useState(0);
  const [markup, setMarkup] = useState(10);
  const [finalPrice, setFinalPrice] = useState(
    carrierCost * (1 + markup / 100)
  );
  const [isCarrierButtonClicked, setIsCarrierButtonClicked] = useState(true);
  const [draftResponseBody, setDraftResponseBody] = useState('');

  useEffect(() => {
    // Map quotes based on email recipients and sort them
    const unsortedQuotes: mappedQuote[] = emails?.map((email) => {
      const q = quotes.find(
        (q) => q.emailAddress.toLowerCase() === email.recipients
      );
      return {
        carrierEmail: email.recipients,
        bounced: email.bounced,
        responded: !!q, // Check if q is truthy (i.e., if quote is found)
        available: q?.available || false,
        price: q?.totalCostUSD || 0,
        notes: q?.notes,
        url: email.url,
      };
    });

    if (!unsortedQuotes) {
      return;
    }

    // Sort the Quotes array by availability and price
    const sortedQuotes = unsortedQuotes?.sort((a, b) => {
      // If one of the emails bounced, place it at the bottom
      if (a.bounced && !b.bounced) return 1;
      if (!a.bounced && b.bounced) return -1;

      // If one of the carriers did not respond, place it at the bottom
      if (!a.responded && b.responded) return 1;
      if (a.responded && !b.responded) return -1;

      // If one of the carriers is unavailable, place it at the bottom
      if (!a.available && b.available) return 1;
      if (a.available && !b.available) return -1;

      // If both carriers responded and are available, sort by price
      return a.price - b.price;
    });

    setSortedQuotes(sortedQuotes);
    setCarrierCost(sortedQuotes[0]?.price);
  }, [emails, quotes]);

  // Set body first time sidebar is opened to this section
  useEffect(() => {
    if (isCarrierButtonClicked) {
      draftMessage();
    }
  }, []);

  // Update final price when carrier cost or markup changes
  useEffect(() => {
    setFinalPrice(carrierCost * (1 + markup / 100));
  }, [carrierCost, markup]);

  // NOTE: finalPrice useEffect logic is intentionally separated from carrierCost/markup useEffect because if
  // consolidated into 1, draftMessage doesn't show the latest price when user selects carriers but
  // updates too quickly when the user changes equation variables
  useEffect(() => {
    if (isCarrierButtonClicked) {
      draftMessage();
    }
  }, [finalPrice]);

  const onSelectCarrier = (index: number) => {
    setSelectedQuoteIndex(index);
    setIsCarrierButtonClicked(true);
    setCarrierCost(sortedQuotes[index].price ?? 0);
  };

  const onBlur = () => {
    draftMessage();
  };

  // TODO: Use Gmail/Outlook compose mode
  const draftMessage = async () => {
    setDraftResponseBody(
      `Hey,\n\nThanks for reaching out to us! We're able to do this shipment for ${formatCurrency(finalPrice, 'USD')}.`
    );
  };

  const handleViewThread = (url: MaybeUndef<string>) => {
    if (!url) {
      return;
    }

    window.open(url, '_blank');
  };

  return (
    <div className='mb-6'>
      {/* Show carrier responses */}
      <div className='mb-6'>
        {sortedQuotes.map((quote, index) => (
          <div key={`quote-${quote.carrierEmail}-${index}`} className='my-2'>
            <Label name={''} className='text-base'>
              {quote.carrierEmail}
            </Label>

            <div className='flex w-full items-center my-1 space-x-2'>
              <Button
                disabled={!quote.responded || !quote.available}
                onClick={() => onSelectCarrier(index)}
                className='flex flex-grow items-center space-x-2'
              >
                <div
                  className={cn(
                    `flex-grow rounded text-sm p-2 text-center bg-white ${selectedQuoteIndex === index ? 'border-2 border-green-pressed' : ''}`,
                    {
                      'bg-red-100': !quote.responded || !quote.available,
                      // 'bg-green-100': quote.responded && quote.available,
                    }
                  )}
                >
                  {quote.responded &&
                    quote.available &&
                    quote.price &&
                    formatCurrency(quote.price, 'USD')}

                  {quote.bounced && 'Email bounced'}

                  {!quote.bounced &&
                    quote.responded &&
                    !quote.available &&
                    'Not available'}

                  {!quote.bounced && !quote.responded && 'N/A'}
                </div>
              </Button>
              {quote.responded && quote.available && (
                <Button
                  disabled={!quote.responded || !quote.available}
                  onClick={() => onSelectCarrier(index)}
                >
                  {selectedQuoteIndex === index ? (
                    <CircleCheck className='w-4 h-4' color={'#00952A'} />
                  ) : (
                    <Circle className='w-4 h-4' color={'#E92C2C'} />
                  )}
                </Button>
              )}
              <Button
                title='View thread'
                onClick={() => handleViewThread(quote.url)}
                className='h-10 flex items-center justify-center'
              >
                <Mail color='#E92C2C' className='w-4 h-4' />
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* Calculate price to shipper */}
      <h2 className='my-2'>Calculate Final Price:</h2>

      <div className='flex justify-center mb-4'>
        {/* Carrier Cost */}
        <div className='flex flex-col items-c'>
          <Label name='' className='text-grayscale-content-3 text-xs'>
            Carrier Cost
          </Label>
          <div className='flex items-center'>
            <Input
              type='number'
              value={carrierCost}
              onChange={(e) => {
                setSelectedQuoteIndex(null);
                setCarrierCost(parseFloat(e.target.value));
                setIsCarrierButtonClicked(false);
              }}
              onBlur={onBlur}
            />
            <div className='mx-2'>+</div>
          </div>
        </div>

        {/* Markup */}
        <div className='flex flex-col'>
          <Label name='' className='text-grayscale-content-3 text-xs'>
            Markup
          </Label>
          <div className='flex items-center'>
            <Input
              type='number'
              step={1}
              value={markup}
              onChange={(e) => {
                setMarkup(parseFloat(e.target.value));
              }}
              onBlur={onBlur}
              className='w-1/2'
            />
            <div className='text-sm pl-1 pr-2'>%</div>
            <div className='mx-2'>=</div>
          </div>
        </div>

        {/* Final Price */}
        <div className='flex flex-col w-1/2'>
          <Label name='' className='text-grayscale-content-3 text-xs'>
            Final Price
          </Label>
          <Input
            className='read-only:bg-white read-only:focus-visible:read-only:border-grayscale-border
            read-only:text-grayscale-content-2 p-1'
            type='number'
            value={
              finalPrice % 1 === 0
                ? finalPrice.toFixed(0)
                : finalPrice.toFixed(2)
            }
            readOnly
          />
        </div>
      </div>

      <Label name='' className='mb-4 text-base'>
        Draft Response
      </Label>
      <Textarea
        name='draftResponse'
        className='p-2 h-36'
        value={draftResponseBody}
        onChange={(e) => {
          setDraftResponseBody(e.target.value);
        }}
      />
    </div>
  );
}

export function formatCurrency(
  amount: number,
  currencyCode: string,
  maxFractionDigits = 0
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });

  return formatter.format(amount);
}
