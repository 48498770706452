import React from 'react';

import { LoadContext, LoadContextType } from 'contexts/load';

type LoadContextProviderProps = React.PropsWithChildren<LoadContextType>;

export default function LoadContextProvider({
  children,
  ...props
}: LoadContextProviderProps) {
  return <LoadContext.Provider value={props}>{children}</LoadContext.Provider>;
}
