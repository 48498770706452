import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import captureException from 'utils/captureException';

type DeleteStarredLoadResponse = {
  starredLoads: string[];
};

type DeleteStarredLoadError = {
  message: string;
};

export default async function deleteStarredLoad(
  loadID: string
): Promise<Result<DeleteStarredLoadResponse, DeleteStarredLoadError>> {
  try {
    const resp = await axios.delete<DeleteStarredLoadResponse>(
      `load/starred/${loadID}`
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'deleteStarredLoad' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to remove starred load' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message:
        error.response?.data.message ||
        error.response?.data ||
        'Failed to remove starred load',
    });
  }
}
