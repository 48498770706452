import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

interface EmailBody {
  recipient: string;
  subject: string;
  body: string;
  scheduleSend: Date;
}

type UpdateCarrierEmailResponse = {
  message: string;
};

export async function updateCarrierEmail(
  emailId: number,
  data: EmailBody
): Promise<Result<UpdateCarrierEmailResponse, ApiError>> {
  try {
    const response = await axios.patch<UpdateCarrierEmailResponse>(
      `/outbox/email/${emailId}`,
      data
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'updateCarrierEmail' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}
