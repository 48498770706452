import { ReactNode, useState } from 'react';

import {
  Service,
  ServiceContext,
  initialServiceValues,
} from 'contexts/serviceContext';

export default function ServiceProvider({ children }: { children: ReactNode }) {
  const [service, setService] = useState<Service>(initialServiceValues);

  return (
    <ServiceContext.Provider
      value={{
        ...service,
        setService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}
