import { Load } from 'types/Load';
import { LoadAttributes } from 'types/LoadAttributes';

export enum TMSOperation {
  UpdateLoad = 'UpdateLoad',
  AssignOperator = 'AssignOperator',
  UpdateOperator = 'UpdateOperator',
  RemoveOperator = 'RemoveOperator',
}

export type UpdateTMSRequest = {
  operation: TMSOperation;
  load: Pick<
    Load,
    'customer' | 'billTo' | 'pickup' | 'consignee' | 'carrier' | 'rateData'
  > & {
    operator: string;
    freightTrackingID: string;
  };
};

export type UpdateTMSResult = {
  load: Load;
  loadAttributes: LoadAttributes;

  message: string;
};
