import { Maybe } from 'types/UtilityTypes';

let relayObserver: MutationObserver;

/**
 * @description
 * Relay uses JavaScript to dynamically change the styling of the <body> element, which causes
 * the sidebar to overlap with the webpage content. We watch for these changes and enforce our
 * container's styling to prevent the sidebar from overlapping.
 * TODO: Switch to using Chrome's built-in sidePanel.
 */
export function enforceStyles(pageContainer: Maybe<HTMLElement>) {
  if (!pageContainer) {
    return;
  }

  pageContainer.classList.add('drumkit-sidebar-modifier');
  pageContainer.style.width = 'calc(100vw - 300px)';
  pageContainer.style.overflowX = 'scroll';
}

export function removeStyles(pageContainer: Maybe<HTMLElement>) {
  if (!pageContainer) {
    return;
  }
  pageContainer.classList.remove('drumkit-sidebar-modifier');
  pageContainer.style.width = '';
  pageContainer.style.overflowX = '';
}

export function startObserving(pageContainer: Maybe<HTMLElement>) {
  if (!pageContainer) {
    return;
  }

  relayObserver = new MutationObserver(() => {
    enforceStyles(pageContainer);
  });

  relayObserver.observe(pageContainer, {
    attributes: true,
    attributeFilter: ['style'],
  });
}

export function stopObserving() {
  relayObserver?.disconnect();
}

/**
 * @description
 * Observe when Relay adds or removes 'phxModal' to DOM to trigger a refresh of OpenDock form.
 */
export function registerRelayModalListener(url: string) {
  let elementID = '';
  switch (true) {
    case url?.includes('relaytms.com/planning_board'):
      elementID = 'phxModal';
      break;

    case url?.includes('relaytms.com/hub'):
      elementID = 'slide-over';
      break;
  }
  // Function to execute when mutations are observed
  const callback: MutationCallback = function (mutationsList) {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as HTMLElement).id === elementID
          ) {
            const event = new CustomEvent('loadModalEvent', {
              detail: { elementMutation: 'added' },
            });
            document.dispatchEvent(event);
          }
        });

        mutation.removedNodes.forEach((node) => {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as HTMLElement).id === elementID
          ) {
            const event = new CustomEvent('loadModalEvent', {
              detail: { elementMutation: 'removed' },
            });
            document.dispatchEvent(event);
          }
        });
      }
    });
  };

  const observer = new MutationObserver(callback);

  const config: MutationObserverInit = {
    childList: true,
    subtree: true,
  };

  const targetNode = document.querySelector('#planning-board') || document.body;

  observer.observe(targetNode, config);
}
