import { KeyedMutator } from 'swr';

import { ExternalLinks } from 'types/ExternalLinks';
import { Load } from 'types/Load';
import { LoadAttributes } from 'types/LoadAttributes';
import { PendingOutboxEmails } from 'types/PendingOutboxEmail';
import { Maybe, MaybeUndef } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';
import { useThrowableSWR } from 'utils/fetcher';
import { hashCode } from 'utils/hashCode';

type LoadFetchResult = {
  tmsID: number;
  tmsName: string;
  load: Maybe<Load>;
  loadAttributes: Maybe<LoadAttributes>;
  externalLinks: MaybeUndef<ExternalLinks>;
  invalidateKey: number;
  invalidate: KeyedMutator<LoadResponse>;
  isLoading: boolean;
  pendingOutboxEmails: Maybe<PendingOutboxEmails>;
  pickupWarehouse: Maybe<Warehouse>;
  dropoffWarehouse: Maybe<Warehouse>;
  error: Maybe<Error>;
};

type LoadResponse = {
  load: Maybe<Load>;
  loadAttributes: Maybe<LoadAttributes>;
  tmsID: number;
  tmsName: string;
  externalLinks: MaybeUndef<ExternalLinks>;
  pendingOutboxEmails: Maybe<PendingOutboxEmails>;
  pickupWarehouse: Maybe<Warehouse>;
  dropoffWarehouse: Maybe<Warehouse>;
};

export default function useFetchLoadByFreightTrackingID(
  freightTrackingId?: string
): LoadFetchResult {
  const { data, isLoading, mutate, error } = useThrowableSWR<LoadResponse>(
    freightTrackingId ? `freight/${freightTrackingId}/load` : null
  );

  const invalidateKey = data ? hashCode(JSON.stringify(data)) : 0;

  return {
    isLoading,
    load: data ? data.load : null,
    loadAttributes: data ? data.loadAttributes : null,
    externalLinks: data ? data.externalLinks : null,
    tmsID: data ? data.tmsID : 0,
    tmsName: data ? data.tmsName : '',
    pickupWarehouse: data ? data.pickupWarehouse : null,
    dropoffWarehouse: data ? data.dropoffWarehouse : null,
    invalidate: mutate,
    invalidateKey,
    pendingOutboxEmails: data ? data.pendingOutboxEmails : null,
    error,
  };
}
