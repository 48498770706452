import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import captureException from 'utils/captureException';

type PutStarredLoadResponse = {
  starredLoads: string[];
};

type PutStarredLoadError = {
  message: string;
};

export default async function putStarredLoad(
  loadID: string
): Promise<Result<PutStarredLoadResponse, PutStarredLoadError>> {
  try {
    const resp = await axios.put<PutStarredLoadResponse>(
      `load/starred/${loadID}`
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'putStarredLoad' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to star load', countSuccess: 0 });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message:
        error.response?.data.message ||
        error.response?.data ||
        'Failed to star load',
    });
  }
}
