import { createQuickQuoteReplyDraft } from '@src/alexandria/lib/api/createQuickQuoteReplyDraft';
import {
  DraftReplyProps,
  MailClientInterface,
} from '@src/alexandria/lib/mailclient/interface';

const handleReplyDraftCreation = async ({
  threadItemId,
  draftBody,
}: DraftReplyProps) => {
  const result = await createQuickQuoteReplyDraft({ threadItemId, draftBody });

  if (result.isErr()) {
    throw result.error;
  }
};

export class Client implements MailClientInterface {
  draftReply({ threadItemId, draftBody }: DraftReplyProps): Promise<void> {
    return new Promise((resolve, reject) => {
      Office.onReady((info: any) => {
        if (info.host !== Office.HostType.Outlook) {
          const error = new Error(
            `Office host is ${info.host}, not ${Office.HostType.Outlook} as expected`
          );
          reject(error);
          return;
        }
      });

      const item = Office.context.mailbox.item;
      if (!item || item === undefined) {
        const error = new Error(`Compose item is falsy (${item})`);
        reject(error);
        return;
      }

      // If we're not in reply view, create reply draft on the backend
      if (!item.body.getTypeAsync) {
        handleReplyDraftCreation({ threadItemId, draftBody })
          .then(() => resolve())
          .catch((error) => {
            reject(error);
          });
        return;
      }

      // Identify the body type of the mail item
      item.body.getTypeAsync((asyncResult: any) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          reject(asyncResult.error);
          return;
        }

        // Set data of the appropriate type to the body
        item?.body.setAsync(
          draftBody,
          {
            coercionType: Office.CoercionType.Html,
            //   asyncContext: { optionalVariable1: 1, optionalVariable2: 2 },
          },
          (asyncResult: any) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              reject(asyncResult.error);
              return;
            }
            resolve();
          }
        );
      });
    });
  }
}
