import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import { Button } from 'components/Button';
import { useToast } from 'hooks/useToaster';
import { NormalizedLoad } from 'types/Load';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import { cn } from 'utils/shadcn';

type AppointmentPreviewProps = {
  normalizedLoad: NormalizedLoad;
};

export default function AppointmentPreview({
  normalizedLoad: load,
}: AppointmentPreviewProps) {
  const { toast } = useToast();
  const tableDivRef = useRef<HTMLDivElement>(null);

  function copyTableToClipboard() {
    if (!tableDivRef.current) {
      return;
    }

    window.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(tableDivRef.current);
    window.getSelection()?.addRange(range);
    // TODO: Sadly this API is deprecated, but it preserves the styles
    // eventually, try to find a better way to copy the table preserving the styles
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();

    toast({
      description: 'Copied to clipboard.',
      variant: 'default',
    });
  }

  return (
    <div className='flex flex-col gap-1'>
      <p className='text-sm'>Preview E-mail</p>
      <div className='w-full mt-2' ref={tableDivRef}>
        <RequestTable normalizedLoad={load} />
      </div>
      <Button
        buttonName={ButtonName.ShowSmartReply}
        buttonNamePosthog={ButtonNamePosthog.ShowSmartReply}
        className='w-full mt-3'
        onClick={copyTableToClipboard}
        logProperties={{
          loadID: load.ID,
          freightTrackingID: load.freightTrackingID,
          serviceID: load.serviceID,
        }}
      >
        Copy to clipboard
      </Button>
    </div>
  );
}

type RequestTableProps = {
  normalizedLoad: NormalizedLoad;
};

function RequestTable({ normalizedLoad: load }: RequestTableProps) {
  const { watch } = useFormContext<RequestInputs>();
  const values = watch();
  return (
    <table
      cellSpacing={0}
      cellPadding={0}
      className='table-fixed w-0 border-black'
    >
      <colgroup>
        <col width={200} />
        <col width={400} />
      </colgroup>
      <tbody>
        <Row>
          <Data colspan={2} className='text-center font-bold font-serif'>
            NFI USE
          </Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>Customer PO</Data>
          <Data className='text-right'>{values.customerPO}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>ASN #</Data>
          <Data className='text-right'>{values.asn}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>PRO #</Data>
          <Data className='text-right'>{load.freightTrackingID}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>NFI Order #</Data>
          <Data className='text-right'>{values.nfiOrder}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>
            Dry, Chilled or Frozen Product
          </Data>
          <Data className='text-right'>{values.dryChilledOrFrozen}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>LTL or TL</Data>
          <Data className='text-right'>{values.LTLorTL}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>Vendor</Data>
          <Data className='text-right'>{values.vendor}</Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>Appointment Date Request</Data>
          <Data className='text-right'>
            {dayjs(values.dateTimeRequested).format('MM/DD')}
          </Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>Appointment Time Request</Data>
          <Data className='text-right'>
            {dayjs(values.dateTimeRequested).format('HH:mm')}
          </Data>
        </Row>
        <Row>
          <Data className='italic font-serif'>Pallet Count</Data>
          <Data className='text-right'>{values.palletCount}</Data>
        </Row>
      </tbody>
    </table>
  );
}

const Row = ({ children }: { children?: React.ReactNode }) => (
  <tr style={{ height: 20 }}>{children}</tr>
);

const Data = ({
  children,
  colspan,
  className,
}: {
  children?: React.ReactNode;
  colspan?: number;
  className?: string;
}) => (
  <td
    className={cn(
      'border border-black overflow-hidden px-1 align-middle text-sm border-l-black border-r-black',
      className
    )}
    style={{
      backgroundColor: 'rgb(204, 255, 204)',
      borderLeftColor: 'black',
      borderTopColor: 'black',
      borderRightColor: 'black',
      borderBottomColor: 'black',
    }}
    colSpan={colspan}
  >
    {children}
  </td>
);

type RequestInputs = {
  customerPO: string;
  asn: string;
  nfiOrder: string;
  dryChilledOrFrozen: string;
  LTLorTL: string;
  vendor: string;
  dateTimeRequested: Date;
  palletCount: string;
  trailerType?: string;
  loadType?: string;
};
