import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import get from 'lodash/get';
import { SparklesIcon } from 'lucide-react';

import { useExtendedFormContext } from 'contexts/extendedFormContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { LoadChanges } from 'types/suggestions/LoadSuggestions';
import { cn } from 'utils/shadcn';

import EmailAIIcon from '../assets/email-ai-icon-64.png';

export type AIHintProps = {
  // Form input name
  name: string;
  // Do not show AI sparkle icon or text. For example, we often default dates to now() so that should not be considered AI-filled
  hideAIHint?: boolean;
  // If container not wide enough to display both icon and text, show icon only
  // Should not be needed given `ai-hint-span` and `ai-hint-text` custom classes, bu
  aiIconOnly?: boolean;
  // If true, applies a transparent color to match read-only fields
  readOnly?: boolean;
};

// Adds ~AI sparkle~ to the right of an input's label if there's a defaultValue.
// Visual cue that demonstrates Drumkit's usefulness to the user.
export default function AIHintLabel({
  name,
  hideAIHint,
  aiIconOnly,
  readOnly,
}: AIHintProps): JSX.Element {
  const { aiDefaultValues, aiIconOnly: formAIIconOnly } =
    useExtendedFormContext();

  const {
    currentState: { clickedSuggestion },
  } = useContext(SidebarStateContext);

  const {
    formState: { defaultValues, dirtyFields },
    getFieldState,
  } = useFormContext();

  let isFieldFromEmailAISuggestion = false;

  if (clickedSuggestion?.suggested) {
    if (clickedSuggestion.pipeline === SuggestionPipelines.CarrierInfo) {
      isFieldFromEmailAISuggestion = Object.keys(
        clickedSuggestion?.suggested as LoadChanges
      )
        .map((s) => `carrier.${s}`)
        .includes(name);
    } else if (
      clickedSuggestion.pipeline === SuggestionPipelines.ApptConfirmation
    ) {
      isFieldFromEmailAISuggestion = name.includes('apptStartTime');
    }
  }

  const isAutofillAndValid =
    aiDefaultValues &&
    !hideAIHint &&
    get(defaultValues, name) && // Empty strings are not shown as AI-filled
    !getFieldState(name).isDirty && // If the user has edited the field, then it's no longer AI-filled
    !get(dirtyFields, name); // Don't ask me why getFieldState works for some but not all inputs, namely select dropdowns ¯\_(ツ)_/¯,

  return (
    <>
      {(isAutofillAndValid || isFieldFromEmailAISuggestion) && (
        <span
          className={cn(
            'flex flex-row whitespace-nowrap ai-hint-span items-center text-xs font-medium text-sky-500',
            readOnly ? 'opacity-40' : 'opacity-75',
            aiIconOnly ? 'w-[20px]' : 'w-[70px]'
          )}
        >
          {isFieldFromEmailAISuggestion ? (
            <img
              src={EmailAIIcon}
              loading='lazy'
              alt='Email AI'
              width={16}
              className='mx-1'
            />
          ) : (
            <SparklesIcon
              color='#0ea5e9'
              className='h-3 w-3 mx-1'
              strokeWidth={2}
            />
          )}
          {!aiIconOnly && !formAIIconOnly && (
            <span className='whitespace-nowrap text-xs ai-hint-text'>
              {isFieldFromEmailAISuggestion ? 'Email AI' : 'AI-filled'}
            </span>
          )}
        </span>
      )}
    </>
  );
}
