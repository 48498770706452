import { ReactNode, useMemo, useState } from 'react';

import {
  DrumkitPlatform,
  SidebarState,
  SidebarStateContext,
  defaultSidebarState,
} from 'contexts/sidebarStateContext';
import { getDrumkitAppContainer } from 'utils/getDrumkitAppContainer';

export default function SidebarStateProvider({
  inboxEmailAddress,
  drumkitPlatform,
  threadId,
  threadItemId,
  isOutlookReply,
  isChromeSidePanel,
  children,
}: {
  inboxEmailAddress?: string;
  drumkitPlatform?: DrumkitPlatform;
  threadId?: string;
  threadItemId?: string;
  isOutlookReply?: boolean;
  isChromeSidePanel?: boolean;
  children: ReactNode;
}) {
  const [currentState, setCurrentState] =
    useState<SidebarState>(defaultSidebarState);

  useMemo(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      inboxEmailAddress: inboxEmailAddress ?? prevState.inboxEmailAddress,
      drumkitPlatform: drumkitPlatform ?? prevState.drumkitPlatform,
      drumkitAppContainer: getDrumkitAppContainer(
        drumkitPlatform ?? prevState.drumkitPlatform
      ),
      threadId: threadId ?? prevState.threadId,
      threadItemId: threadItemId ?? prevState.threadItemId,
      isOutlookReply: isOutlookReply ?? prevState.isOutlookReply,
      isChromeSidePanel: isChromeSidePanel ?? prevState.isChromeSidePanel,
    }));
  }, []);

  return (
    <SidebarStateContext.Provider
      value={{
        currentState: currentState,
        setCurrentState: setCurrentState,
      }}
    >
      {children}
    </SidebarStateContext.Provider>
  );
}
