import * as amplitude from '@amplitude/analytics-browser';
import { BrowserClient } from '@amplitude/analytics-types';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore constants is in the parent dir
import AMPLITUDE_API_KEY from '@constants/AmplitudeApiKey';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore constants is in the parent dir
import APP_VERSION from '@constants/AppVersion';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { Maybe } from 'types/UtilityTypes';
import getEmailAddress from 'utils/getEmailAddress';

let amplitudeInstance: Maybe<BrowserClient> = null;

export default async function getAmplitude(): Promise<BrowserClient> {
  if (amplitudeInstance !== null) {
    return amplitudeInstance;
  }

  amplitudeInstance = amplitude.createInstance();
  const emailAddress = await getEmailAddress();
  // we need to return a unique user id here so it's either the email address or a uuid
  const userId = emailAddress || uuidv4();
  // NOTE: this can't be a short string or it results in a 400 bad request
  const deviceId = isProd() ? 'production' : 'development';

  await amplitudeInstance!.init(AMPLITUDE_API_KEY, userId, {
    appVersion: APP_VERSION,
    defaultTracking: true,
    deviceId: deviceId,
  }).promise;

  return amplitudeInstance;
}
