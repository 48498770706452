import dayjs from 'dayjs';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Maybe } from 'types/UtilityTypes';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type LoadQueryResponse = {
  loadIDs: Maybe<string[]>;
};

export default async function fetchLoadBySearchQuery(
  search: BuildSearchQuery
): Promise<Result<LoadQueryResponse, ApiError>> {
  const params = [
    search.customerName &&
      `customerName=${encodeURIComponent(search.customerName)}`,
    search.routePickupCity &&
      `pickupCity=${encodeURIComponent(search.routePickupCity)}`,
    search.routePickupState &&
      `pickupState=${encodeURIComponent(search.routePickupState)}`,
    search.routeDropoffCity &&
      `dropoffCity=${encodeURIComponent(search.routeDropoffCity)}`,
    search.routeDropoffState &&
      `dropoffState=${encodeURIComponent(search.routeDropoffState)}`,
    search.pickupDate &&
      `pickupDate=${encodeURIComponent(
        dayjs.isDayjs(search.pickupDate) ? search.pickupDate.toISOString() : ''
      )}`,
    search.dropoffDate &&
      `dropoffDate=${encodeURIComponent(
        dayjs.isDayjs(search.dropoffDate)
          ? search.dropoffDate.toISOString()
          : ''
      )}`,
  ].filter(Boolean);

  if (!params.length) return err({ message: 'Empty parameters' });

  try {
    const response = await axios.get(`load/?${params.join('&')}`);
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'fetchLoadBySearchQuery' });

    return err({
      message: 'Oops, something went wrong!',
    });
  }
}

export type BuildSearchQuery = {
  customerName: string;
  routePickupCity: string;
  routePickupState: string;
  routeDropoffCity: string;
  routeDropoffState: string;
  pickupDate: Maybe<dayjs.Dayjs | string>;
  dropoffDate: Maybe<dayjs.Dayjs | string>;
};
