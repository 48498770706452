import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { TMSLocation } from 'types/Load';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';
import { GenericCompanySearchableFields } from 'utils/loadInfoAndBuilding';

type GetLocationsResponse = {
  locationList: TMSLocation[];
  tmsTenant: string;
};

export async function searchLocations(
  tmsID: number,
  key: GenericCompanySearchableFields,
  value: string
): Promise<Result<GetLocationsResponse, ApiError>> {
  const params = [
    `tmsID=${encodeURIComponent(tmsID)}`,
    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  ];

  try {
    const response = await axios.get<GetLocationsResponse>(
      `/locations/search?${params.join('&')}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'searchLocations' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to search locations' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to search locations',
    });
  }
}
