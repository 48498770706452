import React, { useEffect, useState } from 'react';
import {
  FieldErrors,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Cog } from 'lucide-react';

import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { ToastAction } from 'components/Toast';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { AvailableTabs } from 'constants/SidebarTabs';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import useFetchEmailTemplates, {
  EmailTemplatesResponse,
} from 'hooks/useFetchEmailTemplates';
import { useLoadInvalidation } from 'hooks/useLoadContext';
import { useToast } from 'hooks/useToaster';
import { updateCarrierCheckInSOPs } from 'lib/api/updateCarrierCheckInSOPs';
import { EmailTemplate } from 'types/EmailTemplates';
import { NormalizedLoad } from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';

import { LoadSectionAccordionItem } from '../LoadInformationTab';
import CarrierCheckInDetailsForm, {
  TimeUnits,
} from './CarrierCheckInDetailsForm';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatNormalizedTimestamp = (
  date: Dayjs,
  format: string,
  targetTimezone?: string
): string => {
  if (targetTimezone) {
    return date.format(format) + ' ' + dayjs().tz(targetTimezone).format('z');
  }
  return date.format(format);
};

type CarrierCheckInSectionProps = {
  normalizedLoad: NormalizedLoad;
  setTab: React.Dispatch<React.SetStateAction<AvailableTabs>>;
};

interface CheckInDetails {
  checkIn: boolean;
  sendTime: Date;
  duration: number;
  timeUnit: TimeUnits;
  recipient: string;
  cc: string;
  subject: string;
  emailDraft: string;
}

export interface CarrierCheckInInputs {
  freightTrackingId: string;
  dispatch: CheckInDetails;
  pickup: CheckInDetails;
  afterPickup: CheckInDetails;
  inTransit: Omit<CheckInDetails, 'duration' | 'sendTime'> & {
    frequency: number;
  };
  dropoff: CheckInDetails;
  afterDropoff: CheckInDetails;
}

type CarrierCheckInTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<CarrierCheckInInputs>;
};

export const CarrierSOPTextInput = (props: CarrierCheckInTextInputProps) => (
  <RHFTextInput {...props} />
);

export default function CarrierCheckInSection({
  normalizedLoad: load,
  setTab,
}: CarrierCheckInSectionProps) {
  const { toast } = useToast();
  const invalidateLoad = useLoadInvalidation();
  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>([
    'updateCarrierCheckInSOPs',
  ]);

  const { emailTemplates, isLoading } = useFetchEmailTemplates(load.ID);

  const isPickupInPast = dayjs().isAfter(dayjs(load.pickup.apptStartTime));
  const isPickupDisabled = dayjs()
    .subtract(2, 'weeks')
    .isAfter(dayjs(load.pickup.apptStartTime));
  const isDropoffInPast = dayjs().isAfter(dayjs(load.consignee.apptStartTime));
  const isDropoffDisabled = dayjs()
    .subtract(2, 'weeks')
    .isAfter(dayjs(load.consignee.apptStartTime));
  const isInTransitDisabled = dayjs().isAfter(
    dayjs(load.consignee.apptStartTime)
  );

  const generateDefaultValues = (templates: Maybe<EmailTemplatesResponse>) => {
    const createDefaultDetails = (template: Undef<EmailTemplate>) => {
      return {
        checkIn: false,
        cc: template?.cc[0] ?? '',
        recipient: load.carrier.email,
        subject: template?.subject ?? '',
        emailDraft: template?.body.replace(/\\n/g, '\n') ?? '',
      };
    };

    const defaultDispatchDetails = {
      ...createDefaultDetails(templates?.dispatchTemplate),
      duration: 0,
      timeUnit: TimeUnits.Minutes,
    };
    const defaultPickupDetails = {
      ...createDefaultDetails(templates?.pickupTemplate),
      duration: 0,
      timeUnit: TimeUnits.Minutes,
    };
    const defaultAfterPickupDetails = {
      ...createDefaultDetails(templates?.afterPickupTemplate),
      duration: 2,
      timeUnit: TimeUnits.Hours,
    };
    const defaultInTransitDetails = {
      ...createDefaultDetails(templates?.inTransitTemplate),
      frequency: 12,
      timeUnit: TimeUnits.Hours,
    };
    const defaultDropoffDetails = {
      ...createDefaultDetails(templates?.dropoffTemplate),
      duration: 0,
      timeUnit: TimeUnits.Minutes,
    };
    const defaultAfterDropoffDetails = {
      ...createDefaultDetails(templates?.afterDropoffTemplate),
      duration: 2,
      timeUnit: TimeUnits.Hours,
    };

    return {
      freightTrackingId: load.freightTrackingID,
      dispatch: defaultDispatchDetails,
      pickup: defaultPickupDetails,
      afterPickup: defaultAfterPickupDetails,
      inTransit: defaultInTransitDetails,
      dropoff: defaultDropoffDetails,
      afterDropoff: defaultAfterDropoffDetails,
    };
  };

  const formMethods = useForm<CarrierCheckInInputs>({
    defaultValues: generateDefaultValues(emailTemplates),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods;

  useEffect(() => {
    if (!isLoading) {
      formMethods.reset(generateDefaultValues(emailTemplates));
    }
  }, [emailTemplates, formMethods]);

  const onSubmitUpdateSOPs: SubmitHandler<CarrierCheckInInputs> = async (
    data
  ) => {
    function hasAnyCheckIn(data: CarrierCheckInInputs): boolean {
      return Object.values(data).some(
        (value) =>
          typeof value === 'object' &&
          'checkIn' in value &&
          value.checkIn === true
      );
    }

    if (!hasAnyCheckIn(data)) {
      toast({
        description: 'No emails configured.',
        variant: 'destructive',
      });
      return;
    }

    setLoading(true);

    const newApptTime = dayjs().toDate();

    const pickupTime = isPickupInPast
      ? newApptTime
      : (load.pickup.apptStartTime ?? newApptTime);
    const dropoffTime = isDropoffInPast
      ? newApptTime
      : (load.consignee.apptStartTime ?? newApptTime);

    const carrierCheckInData = {
      freightTrackingId: data.freightTrackingId,
      dispatch: { ...data.dispatch, sendTime: pickupTime },
      pickup: { ...data.pickup, sendTime: pickupTime },
      afterPickup: { ...data.afterPickup, sendTime: pickupTime },
      inTransit: data.inTransit,
      dropoff: { ...data.dropoff, sendTime: dropoffTime },
      afterDropoff: { ...data.afterDropoff, sendTime: dropoffTime },
    };

    const res = await updateCarrierCheckInSOPs(load.ID!, carrierCheckInData);
    if (res.isOk()) {
      toast({
        description: 'Successfully scheduled emails.',
        variant: 'success',
        action: (
          <ToastAction
            altText={ButtonName.GoToOutboxTab}
            onClick={() => setTab(AvailableTabs.Outbox)}
          >
            ButtonName.GoToOutboxTab
          </ToastAction>
        ),
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    invalidateLoad();
    setLoading(false);
  };

  const handleOnInvalid = (formErrors: FieldErrors<CarrierCheckInInputs>) => {
    if (Object.keys(formErrors).length > 0) {
      setError('root', {
        type: 'manual',
        message:
          'There are errors in the form. Please correct them before submitting.',
      });
    }
  };

  return (
    <Accordion type='multiple' value={activeTabs} onValueChange={setActiveTabs}>
      <LoadSectionAccordionItem
        label='Contact Carrier'
        icon={<Cog className='h-6 w-6' strokeWidth={1} />}
        name='configureCarrierCheckInSOPs'
        activeTabs={activeTabs}
      >
        <div>
          <ExtendedFormProvider aiDefaultValues={true}>
            <FormProvider {...formMethods}>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmitUpdateSOPs, handleOnInvalid)}
                  className='grid gap-4 grid-cols-1 mt-4 mx-0 w-full'
                >
                  <div className='grid gap-6 grid-cols-1 mb-4 w-full mx-0'>
                    <div>
                      <h2 className='text-base font-semibold leading-7 text-gray-900'>
                        Event Triggers
                      </h2>
                      <p className='mt-1 text-sm leading-6 text-gray-600'>
                        Select specific check-in points in the load process.
                      </p>
                    </div>
                    <div>
                      <Label name=''>
                        Pickup: &nbsp;
                        {formatNormalizedTimestamp(
                          dayjs(load.pickup.apptStartTime),
                          'MM/DD HH:mm',
                          load.pickup.timezone
                        )}
                      </Label>
                    </div>
                    <CarrierCheckInDetailsForm
                      name='dispatch'
                      label='Confirm dispatch'
                      control={control}
                      disabled={isPickupDisabled}
                      isApptInPast={isPickupInPast}
                      load={load}
                    />
                    <CarrierCheckInDetailsForm
                      name='pickup'
                      label='At pickup appointment'
                      control={control}
                      disabled={isPickupDisabled}
                      isApptInPast={isPickupInPast}
                      load={load}
                    />
                    <CarrierCheckInDetailsForm
                      name='afterPickup'
                      label='Confirm loaded'
                      control={control}
                      disabled={isPickupDisabled}
                      isApptInPast={isPickupInPast}
                      load={load}
                    />
                    <CarrierCheckInDetailsForm
                      name='inTransit'
                      label='In-Transit'
                      control={control}
                      disabled={isInTransitDisabled}
                      isApptInPast={isDropoffInPast}
                      load={load}
                    />
                    <div>
                      <Label name=''>
                        Dropoff: &nbsp;
                        {formatNormalizedTimestamp(
                          dayjs(load.consignee.apptStartTime),
                          'MM/DD HH:mm',
                          load.consignee.timezone
                        )}
                      </Label>
                    </div>
                    <CarrierCheckInDetailsForm
                      name='dropoff'
                      label='At dropoff appointment'
                      control={control}
                      disabled={isDropoffDisabled}
                      isApptInPast={isDropoffInPast}
                      load={load}
                    />
                    <CarrierCheckInDetailsForm
                      name='afterDropoff'
                      label='Confirm unloaded'
                      control={control}
                      disabled={isDropoffDisabled}
                      isApptInPast={isDropoffInPast}
                      load={load}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name={'root'}
                    render={({ message }: { message: string }) => (
                      <p className='text-red-500 text-xs text-center'>
                        {message}
                      </p>
                    )}
                  />
                  <Button
                    buttonName={ButtonName.ScheduleCarrierEmails}
                    buttonNamePosthog={ButtonNamePosthog.ScheduleCarrierEmails}
                    type='submit'
                    className='w-full mt-4'
                    disabled={loading}
                    logProperties={{
                      loadID: load.ID,
                      freightTrackingID: load.freightTrackingID,
                      serviceID: load.serviceID,
                    }}
                  >
                    {loading ? (
                      <ButtonLoader />
                    ) : (
                      ButtonName.ScheduleCarrierEmails
                    )}
                  </Button>
                </form>
              </div>
            </FormProvider>
          </ExtendedFormProvider>
        </div>
      </LoadSectionAccordionItem>
    </Accordion>
  );
}
