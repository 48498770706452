import DRUMKIT_AUTH_URL from '@constants/DrumkitAuthUrl';
import { getPageHeight } from '@utils/getPageHeight';

import Intro from '../components/DrumkitIntro';

export default function Signup(): JSX.Element {
  const pageHeight = getPageHeight();

  return (
    <div id='drumkit-content-view-root' style={{ height: `${pageHeight}px` }}>
      <div className='h-screen grid place-items-center place-content-start'>
        <Intro />
        <a
          type='button'
          href={`${DRUMKIT_AUTH_URL}/signup`}
          target='_blank'
          className='bg-orange-main mt-4 mb-2 rounded-full shadow-md text-white cursor-pointer py-2 px-8 grid place-items-center hover:bg-orange-hover'
          rel='noreferrer'
        >
          Sign up for Drumkit
        </a>
        <span className='text-center text-grayscale-content-2 my-2 mx-4'>
          Looks like you don't have a Drumkit account yet, sign up above.
        </span>
        <span className='text-center text-grayscale-content-2 my-2 mx-2'>
          Refresh this the page when you&apos;re done.
        </span>
      </div>
    </div>
  );
}
