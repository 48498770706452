import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { TMSLocation } from 'types/Load';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type GetLocationsResponse = {
  locationList: TMSLocation[];
  tmsTenant: string;
};

export async function getLocations(
  tmsID: number,
  forceRefresh?: boolean
): Promise<Result<GetLocationsResponse, ApiError>> {
  try {
    const response = await axios.get<GetLocationsResponse>(
      `/locations?tmsID=${tmsID}${forceRefresh ? '&forceRefresh=true' : ''}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'getLocations' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get list of locations from TMS' });
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to get list of locations from TMS',
    });
  }
}
