import { DrumkitPlatform } from 'contexts/sidebarStateContext';

/**
 * Now that we have interactive behaviors for the sidebar such as scrolling, we need to know
 * which wrapper Drumkit is being loaded on - such as inboxsdk's or outlook's containers.
 *
 * That is entirely dependent on where the sidebar is being loaded.
 */
export const getDrumkitAppContainer = (platform: DrumkitPlatform) => {
  return platform === DrumkitPlatform.Gmail
    ? document.querySelector('.thread_app_sidebar')
    : document.getElementById('container');
};
