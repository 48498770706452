import * as React from 'react';
import { createRoot } from 'react-dom/client';

import isProd from '@utils/isProd';
import { initializeSentry } from '@utils/sentry/getSentry';

import App from './app';

/* global document, Office */

let isOfficeInitialized = false;

const title = 'Drumkit';

const render = (Component: React.FC<any>) => {
  const container = document.getElementById('container');
  const root = createRoot(container as HTMLElement);
  root.render(
    <Component title={title} isOfficeInitialized={isOfficeInitialized} />
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;

  if (isProd()) {
    initializeSentry();
  }

  render(App);
});
