import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from 'utils/shadcn';

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'flex gap-8 h-10 min-h-10 items-stretch !px-4 border-b border-grayscale-border',
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    className?: string;
    onClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    variant?: string;
  }
>(({ className, onClick, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex gap-2 items-center justify-center whitespace-nowrap relative rounded-sm border-b border-transparent ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-orange-main data-[state=active]:[&_[variant:sparkle-ai]]:text-blue-main data-[state=active]:border-orange-main data-[state=active]:font-medium',
      className,
      variant === TabsTriggerVariants.LoadTabs
        ? 'flex-initial justify-center basis-[34px] min-w-0 px-[8px] rounded-none gap-0 data-[state=active]:grow data-[state=active]:gap-[6px]'
        : ''
    )}
    onClick={(ev) => {
      // ev.currentTarget.scrollIntoView(true);
      onClick && onClick(ev);
    }}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

enum TabsTriggerVariants {
  LoadTabs = 'LoadTabs',
}

export { Tabs, TabsList, TabsTrigger, TabsContent, TabsTriggerVariants };
