import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore auth is in the parent dir
import AuthService from '@auth/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

type PostIngestEmailResponse = {
  message: string;
};

export async function ingestEmail(
  threadId: string
): Promise<Result<PostIngestEmailResponse, ApiError>> {
  try {
    const user = await AuthService.getCurrentUser();
    if (!user?.email) {
      return err({ message: 'Unable to ingest email' });
    }

    const resp = await axios.post<PostIngestEmailResponse>(
      `/onprem/thread/${encodeURIComponent(threadId)}/ingest`,
      {
        emailAddress: user.email,
      }
    );

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'ingestEmail' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to ingest email' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data?.message || 'Failed to ingest email',
    });
  }
}
