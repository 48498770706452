import { useContext, useEffect, useMemo, useState } from 'react';
import {
  FieldPath,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { Accordion } from '@radix-ui/react-accordion';
import { get } from 'lodash';
import {
  BoxIcon,
  Building2,
  CircleDollarSignIcon,
  CircleUserRound,
  Info,
  WarehouseIcon,
  Weight,
} from 'lucide-react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { Button } from 'components/Button';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { applyLoadSuggestion } from 'lib/api/applyLoadSuggestion';
import { buildLoad } from 'lib/api/buildLoad';
import { createLoad } from 'lib/api/createLoad';
import { getCustomers } from 'lib/api/getCustomers';
import { getLocations } from 'lib/api/getLocations';
import { FormStorageService } from 'lib/services/FormStorage/service';
import { LoadSectionAccordionItem } from 'pages/LoadView/LoadInformationTab';
import { Email, initLoad } from 'types/Email';
import {
  Load,
  NormalizedLoad,
  TMSCustomer,
  TMSLocation,
  normalizeLoad,
} from 'types/Load';
import { Maybe, Undef } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import TMS from 'types/enums/Integrations';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestedLoad } from 'types/suggestions/LoadBuildingSuggestions';
import { concatAddress } from 'utils/formatStrings';
import { injectSelectedObject } from 'utils/loadInfoAndBuilding';
import { denormalizeDatesForTMSForm } from 'utils/parseDatesForTMSForm';

import { CustomerSectionForm } from './McleodSectionForms/Customer';
import { OperatorSectionForm } from './McleodSectionForms/Operator';
import { RatesForm } from './McleodSectionForms/Rates';
import { SpecificationsForm } from './McleodSectionForms/Specifications';
import { StopForm, hasNoErrorsInSubsection } from './McleodSectionForms/Stop';

enum AvailableTabs {
  customer = 'customer',
  billTo = 'billTo',
  specifications = 'specifications',
  pickup = 'pickup',
  consignee = 'consignee',
  carrier = 'carrier',
  operator = 'operator',
  rates = 'rates',
}

export const devDisableRequiredFields = !isProd() && false;

type LoadBuildingTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & { name: FieldPath<NormalizedLoad> };

export const LoadBuildingTextInput = (props: LoadBuildingTextInputProps) => (
  <RHFTextInput {...props} />
);

// TODO: Generalize form across all TMS integrations and use LoadAttributes, similar to LoadInformation tab
export default function McleodEnterpriseLoadBuildingForm() {
  const { serviceID, tmsIntegrations } = useServiceFeatures();
  const { toast } = useToast();
  const tmsName = TMS.McleodEnterprise;
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(
    Object.values(AvailableTabs)
  );

  const [isLoadingLocations, setIsloadingLocations] = useState(true);
  const [isLoadingCustomers, setIsloadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [locations, setLocations] = useState<Maybe<TMSLocation[]>>(null);
  // NOTE: Mcleod requires a location ID for pickup, but not dropoff.
  // Thus, we create a new location only if pickup ID is missing.
  const [createNewLocForPickup, setCreateNewLocForPickup] = useState(false);
  const [builtLoad, setBuiltLoad] = useState<Maybe<Load>>(null);

  const {
    currentState: { clickedSuggestion, threadId },
    setCurrentState,
  } = useContext(SidebarStateContext);

  const fetchCustomers = async () => {
    setIsloadingCustomers(true);

    const res = await getCustomers(tmsIntegrations[0]?.id);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
    setIsloadingCustomers(false);
  };

  // Optimization: Defined here instead of in the component to avoid multiple API lookups
  const fetchLocations = async () => {
    setIsloadingLocations(true);

    const res = await getLocations(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setLocations(res.value.locationList);
    } else {
      toast({
        description: 'Error while fetching location list.',
        variant: 'destructive',
      });
    }
    setIsloadingLocations(false);
  };

  const handleRefreshLocations = async () => {
    setIsloadingLocations(true);

    const res = await getLocations(tmsIntegrations?.[0]?.id, true);
    if (res.isOk()) {
      setLocations(res.value.locationList);
      toast({
        description: 'Successfully refreshed location list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing location list.',
        variant: 'destructive',
      });
    }
    setIsloadingLocations(false);
  };

  useEffect(() => {
    fetchLocations();
    fetchCustomers();
  }, []);

  const memoizedDefaultValues: NormalizedLoad = useMemo(() => {
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
    ) {
      const collectionMethods = ['prepaid', 'collect', 'third-party', 'cod'];
      const castedClickedSuggestion =
        clickedSuggestion.suggested as SuggestedLoad;
      const transportType =
        castedClickedSuggestion.specifications?.transportType?.toLowerCase();

      const suggestedFields = {
        ...castedClickedSuggestion,
        mode: 'Truckload',
        specifications: {
          ...castedClickedSuggestion.specifications,
          transportType:
            transportType === 'reefer'
              ? 'Reefer (DAT)'
              : transportType === 'flatbed'
                ? 'Flatbed (DAT)'
                : 'Van (DAT)',
        },
        rateData: {
          ...castedClickedSuggestion.rateData,
          collectionMethod: collectionMethods.includes(
            castedClickedSuggestion.rateData?.collectionMethod?.toLowerCase()
          )
            ? castedClickedSuggestion.rateData.collectionMethod
            : null,
        },
      };
      return {
        ...suggestedFields,
        mode: 'Truckload',
      } as NormalizedLoad;
    }

    if (builtLoad) {
      return normalizeLoad(tmsName, builtLoad);
    }

    return {
      mode: 'Truckload',
      specifications: { transportType: 'Van (DAT)' },
      pickup: {},
      consignee: {},
      carrier: {},
      rateData: {},
    } as NormalizedLoad;
  }, [clickedSuggestion, buildLoad]);

  useEffect(() => {
    if (!memoizedDefaultValues) {
      return;
    }
    formMethods.reset(memoizedDefaultValues);
    // After resetting the form, ensure that options arrays include the necessary data
    const customerID = memoizedDefaultValues.customer?.externalTMSID;
    if (customerID) {
      setCustomers((prevCustomers) =>
        injectSelectedObject(
          memoizedDefaultValues.customer,
          prevCustomers ?? []
        )
      );
    }

    const addtlLocs: TMSLocation[] = [];
    if (memoizedDefaultValues.pickup?.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.pickup);
    }

    if (memoizedDefaultValues.consignee?.externalTMSID) {
      addtlLocs.push(memoizedDefaultValues.consignee);
    }

    setLocations((prevLocations) => {
      let updatedLocations = prevLocations ?? [];

      addtlLocs.forEach((loc) => {
        updatedLocations = injectSelectedObject(loc, updatedLocations);
      });

      return updatedLocations;
    });

    // Show validation errors (the setTimeout ensures validation happens after the form state stabilizes)
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
    ) {
      setTimeout(() => {
        console.debug('triggering validation');
        trigger();
      }, 0);
    }
  }, [memoizedDefaultValues]);

  const formMethods = useForm<NormalizedLoad>({
    defaultValues: memoizedDefaultValues,
  });

  const {
    handleSubmit,
    formState,
    watch,
    trigger,
    formState: { errors },
  } = formMethods;

  const watchedPickup = watch('pickup');

  function handleClearForm() {
    FormStorageService.clearFormState(`${tmsName}_${threadId}`);
    formMethods.reset(normalizeLoad(tmsName, initLoad));
  }

  function flattenValues(
    values: any,
    parentKey = '',
    result: string[] = []
  ): string[] {
    for (const key in values) {
      const value = values[key];
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        flattenValues(value, newKey, result);
      } else {
        result.push(newKey);
      }
    }
    return result;
  }

  useEffect(() => {
    if (!watchedPickup?.externalTMSID) {
      setCreateNewLocForPickup(true);
    }
  }, [watchedPickup?.externalTMSID]);

  useEffect(() => {
    const savedState = FormStorageService.getFormState<
      NormalizedLoad,
      NormalizedLoad
    >(`${tmsName}_${threadId}`);

    if (savedState && !isLoadingLocations && !isLoadingCustomers) {
      // Reset the form to its initial default values or an empty state
      // Removed this line because it was causing a bug where resetField() wasn't working as expected
      // formMethods.reset({});

      // Flatten savedState.values to get all field names
      const fieldNames = flattenValues(savedState.values);

      // For each field, set the value and mark as dirty if necessary - this ensures the AI-label is handled correctly
      fieldNames.forEach((fieldName) => {
        const isDirty: Undef<boolean> = get(savedState.dirtyFields, fieldName);

        if (isDirty === undefined || !isDirty) {
          formMethods.resetField(fieldName as FieldPath<NormalizedLoad>, {
            defaultValue: get(savedState.values, fieldName),
          });
        } else {
          /* "setValue() always triggers a re-render of the form, even when shouldDirty is false. This re-render can cause React Hook Form to update its internal state,
           potentially marking fields as dirty even when they shouldn't be. On the other hand, resetField() is specifically designed 
           to reset a field to its default value without marking it as dirty. This preserves the AI-filled icon state for fields that weren't dirty in the saved state." */
          formMethods.setValue(
            fieldName as FieldPath<NormalizedLoad>,
            get(savedState.values, fieldName),
            { shouldDirty: isDirty }
          );
        }
      });

      // After resetting the form, ensure that options arrays include the necessary data
      const customerID = savedState.values.customer?.externalTMSID;
      if (customerID) {
        setCustomers((prevCustomers) =>
          injectSelectedObject(savedState.values.customer, prevCustomers ?? [])
        );
      }

      const addtlLocs: TMSLocation[] = [];
      if (savedState.values.pickup?.externalTMSID) {
        addtlLocs.push(savedState.values.pickup);
      }

      if (savedState.values.consignee?.externalTMSID) {
        addtlLocs.push(savedState.values.consignee);
      }

      setLocations((prevLocations) => {
        let updatedLocations = prevLocations ?? [];

        addtlLocs.forEach((loc) => {
          updatedLocations = injectSelectedObject(loc, updatedLocations);
        });

        return updatedLocations;
      });
    }

    const subscription = formMethods.watch((value) => {
      FormStorageService.saveFormState(`${tmsName}_${threadId}`, {
        values: value,
        dirtyFields: formMethods.formState.dirtyFields,
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [formMethods, !isLoadingLocations, !isLoadingCustomers]);

  const onSubmit: SubmitHandler<NormalizedLoad> = async (data) => {
    setIsLoading(true);

    const reqData = {
      load: {
        ...data,
        tmsID: tmsIntegrations[0]?.id,
        operator: data.operator,
        mode: data.mode,
        customer: denormalizeDatesForTMSForm(tmsName, data.customer),
        billTo: denormalizeDatesForTMSForm(tmsName, data.billTo),
        specifications: data.specifications,
        pickup: {
          ...denormalizeDatesForTMSForm(tmsName, data.pickup),
        },
        consignee: {
          ...denormalizeDatesForTMSForm(tmsName, data.consignee),
        },
        carrier: {
          ...denormalizeDatesForTMSForm(tmsName, data.carrier),
          rateConfirmationSent: data.carrier?.rateConfirmationSent
            ? true
            : false,
        },
        rateData: denormalizeDatesForTMSForm(tmsName, data.rateData),
      } as unknown as Load,
    };

    const res = await createLoad(reqData);

    if (res.isOk()) {
      setBuiltLoad(res.value.load);
      setCreateNewLocForPickup(false);
      FormStorageService.clearFormState(`${tmsName}_${threadId}`);
      handleAcceptedSuggestion(data);
      toast({
        title: res.value.message,
        description:
          'Load ID: ' +
          (res.value.load.externalTMSID ?? res.value.load.externalTMSID),
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setIsLoading(false);
  };

  const onInvalid: SubmitErrorHandler<Email> = async () => {
    toast({
      description: 'Some fields are invalid.',
      variant: 'destructive',
    });
  };

  const handleAcceptedSuggestion = async (data: SuggestedLoad) => {
    if (clickedSuggestion) {
      await applyLoadSuggestion(clickedSuggestion.id, {
        newLoadSuggestion: data,
      });

      setCurrentState((prevState) => {
        const filteredList = prevState.curSuggestionList.filter(
          ({ id }) => id !== clickedSuggestion.id
        );
        return {
          ...prevState,
          clickedSuggestion: null,
          curSuggestionList: filteredList,
        };
      });
    }
  };

  return (
    <div className='mb-5'>
      <ExtendedFormProvider
        aiDefaultValues={
          clickedSuggestion &&
          clickedSuggestion.pipeline === SuggestionPipelines.LoadBuilding
            ? true
            : false
        }
        aiIconOnly={true}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <Accordion
              type='multiple'
              value={activeTabs}
              onValueChange={setActiveTabs}
            >
              <LoadSectionAccordionItem
                label='Customer'
                icon={<Building2 className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.customer}
                activeTabs={activeTabs}
              >
                <CustomerSectionForm
                  formMethods={formMethods}
                  customers={customers}
                  setCustomers={setCustomers}
                  isLoadingCustomers={isLoadingCustomers}
                  setIsLoadingCustomers={setIsloadingCustomers}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Specs'
                icon={<Weight className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.specifications}
                activeTabs={activeTabs}
              >
                <SpecificationsForm formMethods={formMethods} />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Rates'
                icon={
                  <CircleDollarSignIcon className='h-6 w-6' strokeWidth={1} />
                }
                name={AvailableTabs.rates}
                activeTabs={activeTabs}
              >
                <RatesForm
                  formMethods={formMethods}
                  showCarrierFields={false}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Pickup'
                icon={<BoxIcon className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.pickup}
                activeTabs={activeTabs}
              >
                <StopForm
                  stop='pickup'
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Consignee'
                icon={<WarehouseIcon className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.consignee}
                activeTabs={activeTabs}
              >
                <StopForm
                  stop='consignee'
                  formMethods={formMethods}
                  isLoadingLocations={isLoadingLocations}
                  locations={locations}
                  handleRefreshLocations={handleRefreshLocations}
                  setLocations={setLocations}
                />
              </LoadSectionAccordionItem>

              <LoadSectionAccordionItem
                label='Operator'
                icon={<CircleUserRound className='h-6 w-6' strokeWidth={1} />}
                name={AvailableTabs.operator}
                activeTabs={activeTabs}
              >
                <OperatorSectionForm formMethods={formMethods} />
              </LoadSectionAccordionItem>
            </Accordion>

            <section className='w-full mt-4'>
              {/* NOTE: Mcleod requires a location ID for pickup, but not dropoff.
            Thus, we create a new location only if pickup ID is missing. */}
              {createNewLocForPickup &&
                hasNoErrorsInSubsection('pickup', errors) && ( // TODO: Don't show if empty
                  <span className='flex flex-row bg-violet-blue-bg rounded-lg space-x-1 py-1 mb-4'>
                    <div>
                      <Info
                        className='h-4 w-4 pl-1'
                        color='#969696'
                        strokeWidth={3}
                      />
                    </div>
                    <span className='text-xs text-grayscale-content-3 font-medium'>
                      {`Upon submission, Drumkit will also create a new location ID for pickup (${concatAddress(watchedPickup)}).`}
                    </span>
                  </span>
                )}

              <Button
                buttonName={ButtonName.BuildLoad}
                buttonNamePosthog={ButtonNamePosthog.BuildLoad}
                type='submit'
                className='w-full'
                disabled={isLoading}
                logProperties={{ serviceID }}
              >
                {isLoading ? <ButtonLoader /> : ButtonName.BuildLoad}
              </Button>

              {formState.isDirty && (
                <div className='flex flex-row justify-center align-center'>
                  <Button
                    buttonName={ButtonName.ClearForm}
                    buttonNamePosthog={ButtonNamePosthog.ClearForm}
                    type='button'
                    className='w-50% mt-4 h-8 text-sm text-grayscale-content-2'
                    disabled={isLoading}
                    variant='outline'
                    onClick={handleClearForm}
                    logProperties={{ serviceID }}
                  >
                    {ButtonName.ClearForm}
                  </Button>
                </div>
              )}
              <div className='flex-col justify-center'>
                {(builtLoad?.freightTrackingID || builtLoad?.externalTMSID) && (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-1 px-4 bg-green-bg'>
                      <p className='mb-2'>Load Created 🎉</p>
                      <p className='mb-2 text-sm'>
                        <b className='text-[14px]'>Load ID #: </b>
                        {builtLoad?.freightTrackingID ||
                          builtLoad?.externalTMSID}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </section>
          </form>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}

export enum RateType {
  Flat = 'flat',
  Distance = 'distance',
  CWT = 'cwt',
  Tons = 'tons',
}
