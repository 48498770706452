enum ButtonNamePosthog {
  AddCarrierNetworkQuoteDraft = 'add_carrier_network_quote_draft',
  AddNewTruck = 'add_new_truck',
  AddQuickQuoteDraft = 'add_quick_quote_draft',
  ApptConfirmationSuggestionClick = 'appointment_confirmation_suggestion_click',
  BuildLoad = 'build_load',
  CarrierInfoSuggestionClick = 'carrier_info_suggestion_click',
  CheckCallSuggestionClick = 'check_call_suggestion_click',
  ClearForm = 'clear_form',
  ConfirmSlotApptSchedulingLoadAssociated = 'confirm_slot_appt_scheduling_load_associated',
  ConfirmSlotApptSchedulingLoadIndependent = 'confirm_slot_appt_scheduling_load_independent',
  CyleCheckCallSuggestion = 'cycle_check_call_suggestion',
  DeleteCarrierEmail = 'delete_carrier_email',
  EmailCarriers = 'email_carriers',
  FindOpenApptSlotsLoadAssociated = 'find_open_appt_slots_load_associated',
  FindOpenApptSlotsLoadIndependent = 'find_open_appt_slots_load_independent',
  GetQuickQuote = 'get_quick_quote',
  GoToOutboxTab = 'go_to_outbox',
  GoToTrackAndTraceTab = 'go_to_track_and_trace',
  IngestEmail = 'ingest_email',
  LoadBuildingDateInput = 'load_building_date_input',
  LoadBuildingLocationNameVsStreet = 'load_building_location_name_vs_street',
  LoadBuildingSuggestionClick = 'load_building_suggestion_click',
  LoadBuildingTimeInput = 'load_building_time_input',
  QuickQuoteSuggestionClick = 'quick_quote_suggestion_click',
  RefreshCustomers = 'refresh_customers',
  RefreshLocations = 'refresh_locations',
  RefreshOperators = 'refresh_operators',
  ScheduleCarrierEmails = 'schedule_carrier_emails',
  ScheduleShipperEmails = 'schedule_shipper_emails',
  SearchLoad = 'search_load',
  ShowCarrierSuggestion = 'show_carrier_suggestion',
  ShowLoadIndependentApptSchedulingForm = 'show_load_independent_appt_scheduling_form',
  ShowSmartReply = 'show_smart_reply',
  SkipApptSchedulingSuggestion = 'skip_appt_scheduling_suggestion',
  SkipCarrierInfoSuggestion = 'skip_carrier_info_suggestion',
  SkipCheckCallSuggestion = 'skip_check_call_suggestion',
  SubmitCheckCall = 'submit_check_call',
  SubmitException = 'submit_exception',
  SubmitNote = 'submit_note',
  SubmitTruckList = 'submit_truck_list',
  TruckListSuggestionClick = 'truck_list_suggestion_click',
  UndoDeleteCarrierEmail = 'undo_delete_carrier_email',
  UpdateCarrierEmail = 'update_carrier_email',
  UpdateTMS = 'update_tms',
  ValidateRetalixPONumbers = 'validate_retalix_po_numbers',
  ValidateTruckListCarrier = 'validate_truck_list_carrier',
  SubmitRetalixAppt = 'submit_retalix_appointment',
}

export default ButtonNamePosthog;
