import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CustomApptFieldsTemplate, StopTypes } from 'types/Appointment';
import { ApiError } from 'types/api/ApiError';
import { ConfirmSlotApptResult } from 'types/api/ConfirmSlotAppt';
import captureException from 'utils/captureException';

interface ConfirmSlotApptProps {
  isTMSLoad: boolean;
  isV2: boolean;
  stopType: StopTypes;
  start: Date;
  loadTypeId: string;
  warehouseId: string;
  warehouseTimezone: string;
  dockId: string;
  loadID: number;
  freightTrackingId: string;
  poNums?: string;
  refNumber?: string; // OpenDock-specific, may be 1) FreightTrackingID, 2) PO nums, 3) Load.Consignee.RefNumber
  trailerType?: string; // Required for 7/11 VA, optional for others
  customApptFieldsTemplate?: CustomApptFieldsTemplate[];
  subscribedEmail?: string;
  notes?: string;
}

export async function confirmSlotAppt(
  props: ConfirmSlotApptProps
): Promise<Result<ConfirmSlotApptResult, ApiError>> {
  const requestBody = {
    dryRun: false,
    ...props,
  };

  // Exists on both forms, but not required for non-load
  if (props.freightTrackingId) {
    Object.assign(requestBody, {
      freightTrackingId: props.freightTrackingId,
    });
  }

  // if we have a trailer type from default form, we're working with 7-Eleven's load info case
  if (props.trailerType) {
    Object.assign(requestBody, {
      trailerType: props.trailerType,
    });
  }

  try {
    const response = await axios.post<ConfirmSlotApptResult>(
      `/appt${props.isV2 ? '/v2' : ''}`,
      requestBody
    );
    return ok({
      ID: response.data.ID,
      ExternalID: response.data.ExternalID,
      ConfirmationNo: response.data.ConfirmationNo,
      Message: response.data.Message,
    });
  } catch (error) {
    captureException(error, { functionName: 'confirmSlotAppt' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to set appointment' });
    }

    if (error && isAxiosError(error) && error.response?.status === 409) {
      return err({ message: 'Conflicting Appointments' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      ID: error.response?.data.ID,
      ExternalID: error.response?.data.ExternalID,
      ConfirmationNo: error.response?.data.ConfirmationNo,
      message: error.response?.data.message || 'Failed to set appointment',
    });
  }
}
