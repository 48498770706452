import { ReactNode, useContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import AuthService from '@auth/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { getSentry } from '@utils/sentry/getSentry';

import { AuthContext } from 'contexts/authContext';
import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { AuthUser } from 'types/AuthUser';
import { Maybe } from 'types/UtilityTypes';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [currentUser, setCurrentUser] = useState<Maybe<AuthUser>>();
  const [error, setError] = useState<Maybe<Error>>(null);
  const { currentState, setCurrentState } = useContext(SidebarStateContext);

  const sentryScope = getSentry();

  const fetchUser = async () => {
    try {
      const user = await AuthService.getCurrentUser();

      // As soon as user authenticates, add sentry tag so it will be included on crashes/panics
      if (user?.email) {
        sentryScope.setTag('authenticated_email', user.email);
        setCurrentState({ ...currentState, inboxEmailAddress: user.email });
      }

      setCurrentUser(user);
    } catch (e) {
      setCurrentUser(null);
      setError(e as Error);
    }
  };

  const handleLogout = async () => {
    try {
      await AuthService.logOut();
      setCurrentUser(null);
    } catch (e) {
      setError(e as Error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (error && error.message === 'Extension context invalidated.') {
    throw error;
  }

  return (
    <AuthContext.Provider
      value={{
        loading: typeof currentUser === 'undefined',
        logout: handleLogout,
        user: currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
