import { CarrierVerification } from 'types/CarrierVerification';
import { Maybe } from 'types/UtilityTypes';
import { useThrowableSWR } from 'utils/fetcher';

type CarrierVerificationFetchResult = {
  carrier: Maybe<CarrierVerification>;
  // invalidateKey: number; // QN Do I care about invalidate keys? TBD
  // invalidate: KeyedMutator<LoadResponse>;
  isLoading: boolean;
  error: Maybe<Error>;
};

export default function useFetchCarrierVerificationRequest(
  fromEmail: string
): CarrierVerificationFetchResult {
  const res = useThrowableSWR<CarrierVerification>(
    `carrierverification/getcarrier/?email=${encodeURIComponent(fromEmail)}`,
    false
  );

  return { carrier: res.data ? res.data : null, ...res };
}
