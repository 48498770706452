import FormTextInput from 'components/input/FormTextInput';
import getPlaceholderForField from 'utils/getPlaceholderForField';
import getValidatorForField from 'utils/getValidatorForField';
import { cn } from 'utils/shadcn';

export interface DataElement {
  field: string;
  label: string;
  value: any;
  className?: string;
}

type Props = {
  objectData: DataElement[];
  sectionPrefix: string;
};

export default function Section({
  objectData,
  sectionPrefix,
}: Props): JSX.Element {
  return (
    <div className='grid gap-4 grid-cols-2 mx-0 w-full'>
      {objectData.map((item, idx) => {
        const { field, label, className } = item;
        let value = item.value;

        const cleanedField = field.startsWith('first') ? field.slice(5) : field;
        const inputName = `${sectionPrefix}.${cleanedField}`;
        const labelID = field;

        if (
          value &&
          (field.endsWith('Start') ||
            field.endsWith('End') ||
            field.endsWith('Time'))
        ) {
          if (value !== '0001-01-01T00:00:00Z') {
            // NOTE: Aljex has no timezone data, so the backend defaults datetime conversions to UTC,
            // but the front-end preserves users' current experience by showing the timestamp
            // exactly as it is written in Aljex.
            value = new Date(value).toLocaleString('en-US', {
              timeZone: 'UTC',
            });
          } else {
            value = '';
          }
        }

        return (
          <div className={cn('col-span-2', className)}>
            <FormTextInput
              name={labelID}
              key={idx.toString()}
              canEdit={false}
              label={label}
              labelID={inputName}
              value={value ? value.toString() : ''}
              validator={getValidatorForField(labelID)}
              placeholderText={getPlaceholderForField(labelID)}
            />
          </div>
        );
      })}
    </div>
  );
}
