import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { CarrierInformation } from 'types/Truck';
import captureException from 'utils/captureException';

export default async function validateTruckListCarrier(
  emailID: number,
  threadID: string,
  carrier: CarrierInformation
): Promise<Result<any, any>> {
  const params = [
    `threadID=${encodeURIComponent(threadID)}`,
    `emailID=${emailID}`,
  ];

  try {
    const response = await axios.put(
      `/trucklist/validate/carrier/?${params.join('&')}`,
      { carrier }
    );

    return ok(response.data);
  } catch (error: any) {
    captureException(error, { functionName: 'validateTruckListCarrier' });

    return err({
      carrierErrors: error?.response?.data?.errors?.carrierErrors,
      carrierContactErrors: error?.response?.data?.errors?.carrierContactErrors,
      message: 'Failed to validate carrier',
    });
  }
}
