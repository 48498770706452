import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { GetOperatorsResponse } from 'lib/api/getOperators';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export async function searchOperators(
  tmsID: number,
  search: string
): Promise<Result<string[], ApiError>> {
  const params = [
    `tmsID=${encodeURIComponent(tmsID)}`,
    `search=${encodeURIComponent(search)}`,
  ];

  try {
    const response = await axios.get<GetOperatorsResponse>(
      `/operators/search?${params.join('&')}`
    );
    return ok(response.data.operators);
  } catch (error) {
    captureException(error, { functionName: 'searchOperators' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to search operators' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to search operators',
    });
  }
}
