import { useContext, useEffect, useRef, useState } from 'react';

import { logEvent } from '@amplitude/analytics-browser';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useAuth } from 'hooks/useAuth';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import { skipSuggestion } from 'lib/api/skipSuggestion';
import { Undef } from 'types/UtilityTypes';
import AmplitudeEvent from 'types/enums/AmplitudeEvent';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';
import {
  GenericSuggestion,
  SuggestionChangeRecord,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { LoadBuildingSuggestions } from 'types/suggestions/LoadBuildingSuggestions';
import { SuggestedLoadChange } from 'types/suggestions/LoadSuggestions';
import { SuggestedQuoteChange } from 'types/suggestions/QuoteSuggestions';
import { flattenSuggestionChanges } from 'utils/flattenSuggestionChanges';
import { isValidNonDateObject } from 'utils/isValidObject';
import { getSuggestionFormattedLabel } from 'utils/suggestions/getSuggestionFormattedLabel';

import EmailAIIcon from '../assets/email-ai-icon-64.png';
import { SuggestionCard } from './SuggestionsCard';

export default function SuggestionsCarousel({
  suggestions: initRawSuggestions,
}: {
  suggestions: GenericSuggestion[];
}) {
  const carouselRef = useRef<CarouselRef>(null);

  const { user } = useAuth();
  const { toast } = useToast();
  const posthog = usePostHog();
  const {
    setCurrentState,
    currentState: { curSuggestionList, clickedSuggestion },
  } = useContext(SidebarStateContext);

  const {
    serviceFeaturesEnabled: {
      isCarrierInfoSuggestionsEnabled,
      isAppointmentSuggestionsEnabled,
      isLoadBuildingEnabled,
    },
  } = useServiceFeatures();

  const [optionalSuggestions, setOptionalSuggestions] = useState<
    GenericSuggestion[]
  >([]);
  const [curCarouselSlide, setCurCarouselSlide] = useState<number>(0);

  useEffect(() => {
    const optionalSuggestions = initRawSuggestions
      .filter(
        (suggestion) =>
          !(
            suggestion.pipeline === SuggestionPipelines.CarrierInfo &&
            !isCarrierInfoSuggestionsEnabled
          ) &&
          !(
            suggestion.pipeline === SuggestionPipelines.ApptConfirmation &&
            !isAppointmentSuggestionsEnabled
          ) &&
          !(
            suggestion.pipeline === SuggestionPipelines.LoadBuilding &&
            !isLoadBuildingEnabled
          )
      )
      .map((suggestion) => {
        let truthyFilterSuggestions;
        const isLoadSuggestion =
          suggestion.pipeline === SuggestionPipelines.CarrierInfo ||
          suggestion.pipeline === SuggestionPipelines.ApptConfirmation;

        const isLoadBuildingSuggestion =
          suggestion.pipeline === SuggestionPipelines.LoadBuilding;

        if (suggestion.pipeline === SuggestionPipelines.CheckCall) {
          truthyFilterSuggestions = Object.fromEntries(
            Object.entries(suggestion.suggested.checkCallChanges).filter(
              ([_, v]) => !!v
            )
          );
          return {
            ...suggestion,
            suggested: {
              checkCallChanges: truthyFilterSuggestions,
            },
          };
        }

        truthyFilterSuggestions = filterSuggestions(suggestion.suggested);
        if (isLoadBuildingSuggestion) {
          return {
            ...suggestion,
            suggested: truthyFilterSuggestions,
          } as LoadBuildingSuggestions;
        }
        return isLoadSuggestion
          ? ({
              ...suggestion,
              suggested: truthyFilterSuggestions,
            } as SuggestedLoadChange)
          : ({
              ...suggestion,
              suggested: truthyFilterSuggestions,
            } as SuggestedQuoteChange);
      });

    setOptionalSuggestions(optionalSuggestions);

    // Initialize currentState with filtered suggestions
    setCurrentState((prevState) => ({
      ...prevState,
      curSuggestionList: optionalSuggestions,
    }));
  }, [
    initRawSuggestions,
    isAppointmentSuggestionsEnabled,
    isCarrierInfoSuggestionsEnabled,
    isLoadBuildingEnabled,
  ]);

  useEffect(() => {
    setOptionalSuggestions(curSuggestionList);
  }, [curSuggestionList]);

  const handleGoToSuggestion = async (
    suggestionPipeline: SuggestionPipelines
  ) => {
    const firstSug = optionalSuggestions.findIndex(
      (suggestion) => suggestion.pipeline === suggestionPipeline
    );

    if (firstSug > -1 && carouselRef.current && firstSug !== curCarouselSlide) {
      carouselRef.current.goTo(firstSug);
      // HACK: Antd Carousel has a bug where calling goTo() doesn't trigger the afterChange event
      // so we need to manually set the current slide here
      setCurCarouselSlide(firstSug);
    }
  };

  const handleAfterChange = (current: number) => {
    setCurCarouselSlide(current);
  };

  useEffect(() => {
    // Re-register goToSuggestionInCarousel when optionalSuggestions changes because
    // goToSuggestionInCarousel uses the optionalSuggestions array value when the function is defined/registered,
    // not the current value of optionalSuggestions when it's called
    setCurrentState((prevState) => ({
      ...prevState,
      goToSuggestionInCarousel: handleGoToSuggestion,
    }));
  }, [carouselRef, optionalSuggestions, curCarouselSlide]);

  const handleClearSuggestion = async (
    e: React.MouseEvent<SVGSVGElement>,
    suggestion: GenericSuggestion
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const res = await skipSuggestion(suggestion!.id);

    if (res.isOk()) {
      toast({
        description: 'Suggestion skipped.',
        variant: 'default',
      });

      setOptionalSuggestions(
        optionalSuggestions.filter((s) => s.id !== suggestion.id)
      );
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }
  };

  const handleApplySuggestion = (suggestion: GenericSuggestion): void => {
    setCurrentState((prevState) => ({
      ...prevState,
      clickedSuggestion: suggestion,
    }));

    const eventName = getClickedSuggestionEventName(suggestion.pipeline);
    const eventNamePosthog = getClickedSuggestionEventNamePosthog(
      suggestion.pipeline
    );
    if (suggestion.pipeline) {
      logEvent(AmplitudeEvent.ButtonClick, {
        eventName,
        suggestionID: suggestion.id,
        serviceID: user?.service_id,
      });
      posthog?.capture(eventNamePosthog, {
        suggestionID: suggestion.id,
        serviceID: user?.service_id,
      });
    }
  };

  return (
    <>
      {optionalSuggestions.length > 0 && (
        <div className='w-[calc(100%-32px)] mt-0 mb-8 mx-auto relative'>
          <div className='flex items-start gap-2 mb-2'>
            <img
              src={EmailAIIcon}
              loading='lazy'
              alt='Email AI Icon'
              width={26}
            />
            <h2 className='text-md font-[500] mt-0.5'>Email AI</h2>
          </div>
          <Carousel
            ref={carouselRef}
            afterChange={handleAfterChange}
            arrows={optionalSuggestions.length > 1}
            dots={optionalSuggestions.length > 1}
            className={optionalSuggestions.length > 1 ? '' : 'single-card'}
          >
            {optionalSuggestions.map((suggestion) => {
              const changes =
                suggestion.pipeline === SuggestionPipelines.CheckCall
                  ? suggestion.suggested.checkCallChanges
                  : suggestion.suggested;

              // Flattening changes that have object values
              const flattenedChanges = flattenSuggestionChanges(changes);

              const { elements } = getValidChangeElements(
                flattenedChanges,
                suggestion
              );

              const initialChangeElements = elements.slice(0, 2);

              if (!initialChangeElements || !initialChangeElements.length)
                return;

              return (
                <SuggestionCard
                  key={`${suggestion.pipeline}-${suggestion.id}`}
                  suggestion={suggestion}
                  clickedSuggestion={clickedSuggestion}
                  handleClearSuggestion={handleClearSuggestion}
                  handleApplySuggestion={handleApplySuggestion}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
}

/**
 * Generates a list of JSX elements displaying the valid changes between
 * `changesDisplayed` and `suggestion`, and returns the count of valid changes.
 *
 * A valid change includes:
 * - A label corresponding to a suggestion field.
 * - A valid value for that field.
 *
 * If a value is a date (detected by `dayjs`), it is formatted as 'MMM D, YYYY HH:mm A'.
 *
 * @param {SuggestionChangeRecord} changesDisplayed - The displayed changes object containing key-value pairs of changes.
 * @param {GenericSuggestion} suggestion - The suggestion data used to fetch the change label.
 * @returns {{ elements: (JSX.Element | undefined)[], validChangesCount: number }} An object with:
 *  - `elements`: An array of JSX elements representing valid changes.
 *  - `validChangesCount`: The count of valid change elements which is used in the `Show +X changes`/`Collapse` button.
 */
export const getValidChangeElements = (
  changesDisplayed: SuggestionChangeRecord,
  suggestion: GenericSuggestion
): { elements: Undef<JSX.Element>[]; validChangesCount: number } => {
  const changesDisplayedList = Object.entries(changesDisplayed);

  if (!changesDisplayedList.length)
    return { elements: [], validChangesCount: 0 };

  let validChangesCount = 0;

  const elements = changesDisplayedList
    .map(([name, val]) => {
      const changeLabel = getSuggestionFormattedLabel(
        suggestion.pipeline,
        name
      );

      const changeValue =
        // TODO: add dayjs strict parsing so we can remove .length hacky check
        val && val.toString().length > 8 && dayjs(val).isValid()
          ? dayjs(val).format('MMM D, YYYY HH:mm')
          : val;

      if (!changeLabel || !changeValue) return;

      validChangesCount += 1;

      return (
        <div className='text-[12px] whitespace-nowrap overflow-hidden text-ellipsis'>
          <span className='font-medium'>{changeLabel}</span>
          {`${changeValue}`}
        </div>
      );
    })
    .filter(Boolean); // filter out null values

  return { elements, validChangesCount };
};

const getClickedSuggestionEventName = (pipeline: SuggestionPipelines) => {
  switch (pipeline) {
    case SuggestionPipelines.CarrierInfo:
      return ButtonName.CarrierInfoSuggestionClick;
    case SuggestionPipelines.ApptConfirmation:
      return ButtonName.ApptConfirmationSuggestionClick;
    case SuggestionPipelines.CheckCall:
      return ButtonName.CheckCallSuggestionClick;
    case SuggestionPipelines.QuickQuote:
      return ButtonName.QuickQuoteSuggestionClick;
    case SuggestionPipelines.LoadBuilding:
      return ButtonName.LoadBuildingSuggestionClick;
  }
};

const getClickedSuggestionEventNamePosthog = (
  pipeline: SuggestionPipelines
) => {
  switch (pipeline) {
    case SuggestionPipelines.CarrierInfo:
      return ButtonNamePosthog.CarrierInfoSuggestionClick;
    case SuggestionPipelines.ApptConfirmation:
      return ButtonNamePosthog.ApptConfirmationSuggestionClick;
    case SuggestionPipelines.CheckCall:
      return ButtonNamePosthog.CheckCallSuggestionClick;
    case SuggestionPipelines.QuickQuote:
      return ButtonNamePosthog.QuickQuoteSuggestionClick;
    case SuggestionPipelines.LoadBuilding:
      return ButtonNamePosthog.LoadBuildingSuggestionClick;
  }
};

// util function to handle for nested objects
const filterSuggestions = (obj: any): any =>
  isValidNonDateObject(obj)
    ? Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== '' && !!value) // Filters out falsy values
          .map(([key, value]) => [
            key,
            // Recursively filter if value is a nested object
            isValidNonDateObject(value) ? filterSuggestions(value) : value,
          ])
      )
    : obj;
