// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import { isAxiosError } from 'axios';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { SidebarState } from 'contexts/sidebarStateContext';
import { toast } from 'hooks/useToaster';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { Email } from 'types/Email';
import { Maybe } from 'types/UtilityTypes';
import {
  GenericSuggestion,
  SuggestionPipelines,
} from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';

export enum TransportType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}

export enum SelectedCarrierType {
  NETWORK = 'NETWORK',
  BUYPOWER = 'BUYPOWER',
}

export interface Stop {
  zip: string;
  city: string;
  state: string;
}

export interface QuickQuoteInputs {
  transportType: TransportType;
  pickupDate: Date;
  deliveryDate: Date;
  stops: Array<Stop>;
  isSubmitToTMS: boolean;
  customerName: string;
}

interface QuickQuoteBody extends QuickQuoteInputs {
  emailID?: number;
  threadID?: string;
}

export interface QuickQuoteResponse {
  id: number;
  stops: {
    order: number;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
  selectedRateName: SelectedCarrierType;
  networkLaneRateDistance: any;
  networkLaneRateTargetBuy: any;
  networkLaneRateConfidenceLevel: any;
  laneRateDistance: any;
  laneRateTargetBuy: any;
  laneRateConfidenceLevel: any;
  lowConfidenceThreshold: any;
  mediumConfidenceThreshold: any;
  belowThresholdMessage: any;
}

type ErrorData = {
  error: string;
  threshold: Maybe<number>;
};

// TODO: Refactor to match other API funcs; form updates should be handled in the form
export async function getQuickQuote(
  email: Maybe<Email>,
  clickedSuggestion: Maybe<GenericSuggestion>,
  setSidebarState: React.Dispatch<React.SetStateAction<SidebarState>>,
  formValues: QuickQuoteInputs,
  formMethods: UseFormReturn<QuickQuoteInputs>,
  setQuoteHandler: React.Dispatch<
    React.SetStateAction<Maybe<QuickQuoteResponse>>
  >,
  setQuoteNotConfidentHandler: React.Dispatch<React.SetStateAction<boolean>>,
  setCarrierCostHandler: React.Dispatch<React.SetStateAction<number>>,
  setSelectedCarrierHandler: React.Dispatch<
    React.SetStateAction<SelectedCarrierType>
  >
): Promise<void> {
  setQuoteNotConfidentHandler(false);
  setQuoteHandler(null);

  let body: QuickQuoteBody = { ...formValues };

  if (email !== null) {
    body = { ...body, emailID: email.id, threadID: email.threadID };
  }

  try {
    const { data } = await axios.post(`/quote/private/quickquote`, body);

    setQuoteHandler(data);
    setCarrierCostHandler(
      _.round(
        data.selectedRateName === SelectedCarrierType.NETWORK
          ? data.networkLaneRateTargetBuy * data.networkLaneRateDistance
          : data.laneRateTargetBuy * data.laneRateDistance
      )
    );
    setSelectedCarrierHandler(
      data.selectedRateName === SelectedCarrierType.NETWORK
        ? SelectedCarrierType.NETWORK
        : SelectedCarrierType.BUYPOWER
    );

    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote
    ) {
      const applied: QuoteChanges = {
        transportType: formValues.transportType,
        pickupCity: formValues.stops[0].city,
        pickupState: formValues.stops[0].state,
        pickupZip: formValues.stops[0].zip,
        pickupDate: formValues.pickupDate,
        deliveryCity: formValues.stops[1].city,
        deliveryState: formValues.stops[1].state,
        deliveryZip: formValues.stops[1].zip,
        deliveryDate: formValues.deliveryDate,
      };

      updateQuoteRequestSuggestion(
        clickedSuggestion.id,
        SuggestionStatus.Accepted,
        applied
      );

      setSidebarState((prevState) => ({
        ...prevState,

        curSuggestionList: prevState.curSuggestionList.filter(
          (s) => s.id !== clickedSuggestion?.id
        ),
      }));
    }
  } catch (error) {
    const defaultErrorMessage =
      'Please try again in a few moments or contact our team if the issue persists.';

    if (isAxiosError(error)) {
      const status = error.response?.status;
      const data = error.response?.data;

      switch (status) {
        case 422: {
          const typedData: ErrorData = data;

          // Handle unprocessable entity - possibly low confidence quote
          if (
            typedData.error.includes('unable') &&
            typedData.error.includes('confident')
          ) {
            setQuoteNotConfidentHandler(true);
            toast({
              description: `Greenscreens quote confidence is below
                      ${
                        typedData.threshold
                          ? typedData.threshold + '%.'
                          : 'your minimum threshold.'
                      }
                      Try another quoting approach, then return to complete the form.`,
              variant: 'destructive',
            });
            return;
          }
          formMethods.setError('root', {
            message: data || defaultErrorMessage,
          });
          return;
        }
        case 503: {
          const data: ErrorData = error.response?.data;

          if (data.error.includes('stops.0')) {
            formMethods.setError('root', {
              message:
                "The pickup location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.0.city', {});
            formMethods.setError('stops.0.state', {});
          } else if (data.error.includes('stops.1')) {
            formMethods.setError('root', {
              message:
                "The dropoff location doesn't exist. Please enter a valid city and state pair.",
            });
            formMethods.setError('stops.1.city', {});
            formMethods.setError('stops.1.state', {});
          } else if (data.error.includes('Invalid Zip Code.')) {
            if (data.error.includes('stop 0')) {
              formMethods.setError('root', {
                message:
                  "The pickup location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.0.zip', {});
            } else if (data.error.includes('stop 1')) {
              formMethods.setError('root', {
                message:
                  "The dropoff location doesn't exist. Please enter a valid ZIP code.",
              });
              formMethods.setError('stops.1.zip', {});
            }
          } else if (
            data.error.includes('ZIPCode must be 5 characters') ||
            data.error.includes('is not 5 digits if USA')
          ) {
            if (
              data.error.includes('stop 0') ||
              data.error.includes('stops.0')
            ) {
              formMethods.setError('stops.0.zip', {});
            } else if (
              data.error.includes('stop 1') ||
              data.error.includes('stops.1')
            ) {
              formMethods.setError('stops.1.zip', {});
            }
            formMethods.setError('root', {
              message: 'ZIP code must be 5 characters.',
            });
          }
          return;
        }
        default: {
          formMethods.setError('root', {
            message: data || defaultErrorMessage,
          });
          return;
        }
      }
    }

    // Non-Axios error fallback
    formMethods.setError('root', {
      message: defaultErrorMessage,
    });
  }
}
