import { UseFormGetValues } from 'react-hook-form';

import dayjs from 'dayjs';

import { Button } from 'components/Button';

import { TruckDateTimeInput } from '../../TruckListFormFields';
import { RedwoodTruckListInputs } from '../RedwoodTruckListForm';

type RedwoodNewGroupFieldsetProps = {
  handleAddNewGroup: () => void;
  getValues: UseFormGetValues<RedwoodTruckListInputs>;
};

export const RedwoodNewGroupFieldset = ({
  handleAddNewGroup,
  getValues,
}: RedwoodNewGroupFieldsetProps) => (
  <div className='grid grid-cols-10 items-center gap-2 w-full mx-0 mt-2'>
    <div className='col-span-5'>
      <TruckDateTimeInput label='' name={`create.pickupDate`} />
    </div>

    <Button
      className='w-full mt-2 h-9 col-span-5 text-[13px] text-orange-main border border-orange-main hover:border-orange-main'
      onClick={handleAddNewGroup}
      disabled={!getValues('create.pickupDate')}
      type='button'
      variant='outline'
    >
      {getValues('create.pickupDate')
        ? `Add ${dayjs(getValues('create.pickupDate')).format('MM/DD')} pickup`
        : `Add pickup date`}
    </Button>
  </div>
);
