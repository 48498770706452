import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { TMSCustomer } from 'types/Load';
import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';
import { GenericCompanySearchableFields } from 'utils/loadInfoAndBuilding';

type GetCustomersResponse = {
  customerList: TMSCustomer[];
  tmsTenant: string;
};

export async function searchCustomers(
  tmsID: number,
  key: GenericCompanySearchableFields,
  value: string
): Promise<Result<GetCustomersResponse, ApiError>> {
  const params = [
    `tmsID=${encodeURIComponent(tmsID)}`,
    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  ];

  try {
    const response = await axios.get<GetCustomersResponse>(
      `/customers/search?${params.join('&')}`
    );
    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'searchCustomers' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to search customers' });
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to search customers',
    });
  }
}
