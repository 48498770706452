import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { SuggestedLoad } from 'types/suggestions/LoadBuildingSuggestions';
import { LoadChanges } from 'types/suggestions/LoadSuggestions';
import captureException from 'utils/captureException';

export type LoadSuggestionRequest = {
  existingLoadSuggestion?: LoadChanges;
  newLoadSuggestion?: SuggestedLoad;
};

export async function applyLoadSuggestion(
  suggestionId: number,
  { existingLoadSuggestion, newLoadSuggestion }: LoadSuggestionRequest
): Promise<Result<null, ApiError>> {
  try {
    const response = await axios.patch(
      `/suggestions/${suggestionId}/loadinfo`,
      {
        status: 'accepted',
        ...(existingLoadSuggestion ? { changes: existingLoadSuggestion } : {}),
        ...(newLoadSuggestion ? { loadChanges: newLoadSuggestion } : {}),
      }
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'applyLoadSuggestion' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
    });
  }
}
