import dayjs from 'dayjs';

/**
 * We can do `obj === Object(obj)` because, according to MDN:
 *
 * The Object constructor creates an object wrapper for the given value.
 * If the value is null or undefined, it will create and return an empty object, otherwise,
 * it will return an object of a type that corresponds to the given value.
 * If the value is an object already, it will return the value.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
 *
 * Note that because Date objects are also objects that are empty but contain methods,
 * we need to explicitly check for Date/dayjs objects
 */
export const isValidNonDateObject = (obj: any): boolean =>
  obj === Object(obj) && !(obj instanceof Date) && !dayjs.isDayjs(obj);
