import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/Tooltip';

export default function RequiredHint() {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className='text-red-400 mx-1'>*</span>
        </TooltipTrigger>
        <TooltipContent
          side='top'
          className='rounded-md bg-gray-800 text-white text-xs px-2 py-1 shadow-md opacity-90'
        >
          Required
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
