import { KeyedMutator } from 'swr';

import { EmailTemplate } from 'types/EmailTemplates';
import { Maybe } from 'types/UtilityTypes';
import { useThrowableSWR } from 'utils/fetcher';

export type EmailTemplatesResponse = {
  dispatchTemplate: EmailTemplate;
  pickupTemplate: EmailTemplate;
  afterPickupTemplate: EmailTemplate;
  inTransitTemplate: EmailTemplate;
  dropoffTemplate: EmailTemplate;
  afterDropoffTemplate: EmailTemplate;
};

export type EmailTemplatesFetchResult = {
  emailTemplates: Maybe<EmailTemplatesResponse>;
  invalidate: KeyedMutator<EmailTemplatesResponse>;
  isLoading: boolean;
  error: Error;
};

export default function useFetchEmailTemplates(
  loadID?: Maybe<number>
): EmailTemplatesFetchResult {
  const { data, isLoading, mutate, error } =
    useThrowableSWR<EmailTemplatesResponse>(
      loadID ? `load/${loadID}/sops/carrier` : null
    );

  return {
    emailTemplates: data || null,
    invalidate: mutate,
    isLoading: isLoading,
    error: error,
  };
}
