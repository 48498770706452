import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import dayjs from 'dayjs';
import { CalendarIcon } from 'lucide-react';

import { FieldAttributes } from 'types/LoadAttributes';
import { cn } from 'utils/shadcn';

import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

export interface DatePickerProps {
  field: ControllerRenderProps<any>;
  thisFieldAttr?: FieldAttributes;
  highlightDirtyField?: boolean;
  buttonNamePosthog?: string;
  logProperties?: Record<string, any>;
}

function DatePicker({
  field,
  highlightDirtyField,
  thisFieldAttr,
  buttonNamePosthog,
  logProperties,
}: DatePickerProps) {
  const [isCalendarPopoverOpen, setIsCalendarPopoverOpen] = useState(false);

  return (
    <Popover
      open={isCalendarPopoverOpen}
      onOpenChange={(open) => setIsCalendarPopoverOpen(open)}
    >
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          size='sm'
          className={cn(
            'w-full justify-start text-left font-normal rounded border-orange-border py-3 h-9',
            !field.value && 'text-muted-foreground',
            highlightDirtyField && 'bg-yellow-50'
          )}
          buttonNamePosthog={buttonNamePosthog}
          logProperties={logProperties}
          disabled={thisFieldAttr?.isReadOnly}
        >
          <CalendarIcon className='mr-2 h-4 w-4 shrink-0' />
          <span className='text-sm text-grayscale-content-2'>
            {field.value
              ? dayjs(field.value as Date).format('MM/DD/YY')
              : 'Pick a date'}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0'>
        <Calendar
          mode='single'
          selected={
            field.value
              ? dayjs.isDayjs(field.value)
                ? field.value.toDate()
                : new Date(field.value as string)
              : undefined
          }
          onSelect={(newValue: any) => {
            if (typeof newValue === 'undefined') {
              field.onChange(null);
              return;
            }

            if (field.value && newValue) {
              newValue.setHours(new Date(field.value as string).getHours());
              newValue.setMinutes(new Date(field.value as string).getMinutes());
            }

            field.onChange(newValue);
            setIsCalendarPopoverOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

export { DatePicker };
