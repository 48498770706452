import React from 'react';

import { TMSContext } from 'contexts/tms';

type TMSProviderProps = React.PropsWithChildren<{
  tmsID: number;
  tmsName: string;
}>;

export default function TMSProvider({ children, ...rest }: TMSProviderProps) {
  return <TMSContext.Provider value={rest}>{children}</TMSContext.Provider>;
}
