import { XCircleIcon } from 'lucide-react';

export const NoCarrierAvailable = () => (
  <div className='rounded-md bg-red-50 mt-8 mb-8 p-4'>
    <div className='flex items-start'>
      <div className='flex-shrink-0 pt-0.5'>
        <XCircleIcon aria-hidden='true' className='h-4 w-4 text-red-500' />
      </div>
      <div className='ml-3'>
        <h3 className='text-sm font-medium text-red-600'>
          No carriers were found
        </h3>
        <div className='mt-2 text-base text-red-500'>
          <ul role='list' className='list-disc space-y-1 pl-5'>
            <li>Create a new one on the Redwood Platform first.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
