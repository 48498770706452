import React, { useContext } from 'react';

import { StarIcon } from 'lucide-react';

import { SidebarStateContext } from 'contexts/sidebarStateContext';
import { useToast } from 'hooks/useToaster';
import deleteStarredLoad from 'lib/api/deleteStarredLoad';
import putStarredLoad from 'lib/api/putStarredLoad';

type StarredLoadToggleProps = React.PropsWithChildren<{
  tab: string;
  starredLoads: string[];
}>;

export default function StarredLoadToggle({
  tab,
  starredLoads,
}: StarredLoadToggleProps) {
  const { setCurrentState } = useContext(SidebarStateContext);

  const { toast } = useToast();

  const handleStarLoad = async (tab: string) => {
    const res = await putStarredLoad(tab);

    if (res.isOk()) {
      const {
        value: { starredLoads },
      } = res;

      setCurrentState((prevState) => ({
        ...prevState,
        starredLoads: starredLoads,
      }));
    } else {
      toast({
        description: 'Error starring load.',
        variant: 'destructive',
      });
    }
  };

  const handleRemoveStarredLoad = async (tab: string) => {
    const res = await deleteStarredLoad(tab);

    if (res.isOk()) {
      const {
        value: { starredLoads },
      } = res;

      setCurrentState((prevState) => ({
        ...prevState,
        starredLoads: starredLoads,
      }));
    } else {
      toast({
        description: 'Error removing starred load.',
        variant: 'destructive',
      });
    }
  };

  return tab ? (
    <>
      {starredLoads && starredLoads.includes(tab) ? (
        <StarIcon
          className='absolute h-4 w-4 right-4 top-2 cursor-pointer fill-orange-main stroke-orange-main'
          onClick={() => handleRemoveStarredLoad(tab)}
        />
      ) : (
        <StarIcon
          className='absolute h-4 w-4 right-4 top-2 cursor-pointer'
          onClick={() => handleStarLoad(tab)}
        />
      )}
    </>
  ) : null;
}
