// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import { isAxiosError } from 'axios';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Maybe } from 'types/UtilityTypes';

export enum TransportType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}
export interface Stop {
  zip: string;
}

export interface LoadBuildInputs {
  transportType: TransportType;
  pickupDate: Date;
  deliveryDate: Date;
  pickupLocationZip: string;
  deliveryLocationZip: string;
  customerId: string;
  commodityDescription: string;
  commodityWeight: number;
}

export interface LoadBuildFormValues {
  commodityDescription: string;
  commodityWeight: number;
  customerId: string;
  quotePrice: number;
  transportType: string;
  pickupLocationZip: string;
  pickupDate: string;
  deliveryLocationZip: string;
  deliveryDate: string;
}

export interface LoadBuildResponse {
  quoteId: number;
}

export async function buildLoad(
  formInputs: LoadBuildInputs,
  formMethods: UseFormReturn<LoadBuildInputs>,
  setLoadHandler: React.Dispatch<React.SetStateAction<Maybe<LoadBuildResponse>>>
): Promise<void> {
  setLoadHandler(null);

  const formValues: LoadBuildFormValues = {
    commodityDescription: formInputs.commodityDescription,
    commodityWeight: formInputs.commodityWeight,
    customerId: formInputs.customerId.toString(),
    quotePrice: 0,
    transportType: formInputs.transportType,
    pickupLocationZip: formInputs.pickupLocationZip,
    pickupDate: formInputs.pickupDate.toISOString(),
    deliveryLocationZip: formInputs.deliveryLocationZip,
    deliveryDate: formInputs.deliveryDate.toISOString(),
  };

  try {
    //TODO: using this endpoint as a hack for now to save time.
    // Eventually need a dedicated load building endpoint.
    const { data } = await axios.post(`/quote/private/tms/create`, formValues);
    setLoadHandler(data);
  } catch (error) {
    const defaultErrorMessage =
      'Something went wrong. Please try again in a few moments or contact our team.';

    if (isAxiosError(error) && error.response?.status) {
      // API returns "Invalid email" for invalid domains
      formMethods.setError('root', {
        message: error.response?.data,
      });
      return;
    }

    formMethods.setError('root', {
      message: defaultErrorMessage,
    });
  }
}
