import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AIHintProps } from 'components/AIHint';
import { Label } from 'components/Label';

import { Input } from './Input';

type Props = AIHintProps & {
  canEdit?: boolean;
  label: string;
  labelID: string;
  placeholderText?: string;
  validator?: (value: string) => string | undefined | null;
  value?: string;
};

export default function FormTextInput({
  canEdit = true,
  label,
  labelID,
  placeholderText = '',
  value,
  validator,
}: Props): JSX.Element {
  const [error, setError] = useState<string | undefined | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      setError(null);
    } else if (validator) {
      setError(validator(inputValue));
    }
  };

  const form = useFormContext();

  return (
    <div>
      <Label name={form ? labelID : ''} readOnly={!canEdit}>
        {label}
      </Label>
      <div>
        <Input
          defaultValue={value}
          id={labelID}
          name={labelID}
          onChange={handleInputChange}
          placeholder={placeholderText}
          readOnly={!canEdit}
          type='text'
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </div>
    </div>
  );
}
