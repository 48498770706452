import { MaybeUndef } from 'types/UtilityTypes';

export default function getValidatorForField(field: string) {
  switch (field) {
    case 'zipCode':
      return validateZipCode;
    case 'email':
      return validateEmail;
    case 'phone':
      return validatePhone;
    default:
      if (field.endsWith('Phone')) {
        return validatePhone;
      } else if (
        field.endsWith('Time') ||
        field.endsWith('Start') ||
        field.endsWith('End')
      ) {
        return validateTime;
      }

      return undefined;
  }
}

// Example:
// test@email.com
export const validateEmail = (value: string): MaybeUndef<string> => {
  if (!value) {
    return undefined;
  }

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailPattern.test(value)) {
    return 'Invalid email';
  }

  return undefined;
};

// a combination of numbers and/or letters
export const validateNumber = (value: string): MaybeUndef<string> => {
  if (!value) {
    return undefined;
  }

  const numberPattern = /^[a-zA-Z0-9]+$/;
  if (!numberPattern.test(value)) {
    return 'Invalid input. Only letters and numbers are allowed.';
  }

  return undefined;
};

// Example:
// (555) 555-5555
export const validatePhone = (value: string): MaybeUndef<string> => {
  if (!value) {
    return undefined;
  }

  const phonePattern = /^\(\d{3}\)\s\d{3}-\d{4}$/;
  if (!phonePattern.test(value)) {
    return 'Invalid phone number format. Expected (XXX) XXX-XXXX';
  }

  return undefined;
};

// Examples:
// 10/06/2023
// 10/06/23
// 10/06/2023, 8:00:00 PM
export const validateTime = (value: string): MaybeUndef<string> => {
  if (!value) {
    return undefined;
  }

  const timePattern =
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})(,\s(0?[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9]\s(AM|PM))?$/;
  if (!timePattern.test(value)) {
    return 'Invalid time format. Acceptable formats: MM/DD/YYYY, MM/DD/YY, or MM/DD/YYYY, HH:mm:ss AM/PM';
  }

  return undefined;
};

// 5-digits
export const validateZipCode = (value: string): MaybeUndef<string> => {
  if (!value) {
    return undefined;
  }

  if (!/^\d{5}$/.test(value)) {
    return 'Invalid Zip Code';
  }

  return undefined;
};
