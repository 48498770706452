import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import captureException from 'utils/captureException';

export type GetOperatorsResponse = {
  operators: string[];
};
export async function getOperators(
  tmsID: number,
  forceRefresh?: boolean
): Promise<Result<Array<string>, ApiError>> {
  try {
    const response = await axios.get<GetOperatorsResponse>(
      `/operators?tmsID=${tmsID}${forceRefresh ? '&forceRefresh=true' : ''}`
    );
    return ok(response.data.operators);
  } catch (error) {
    captureException(error, { functionName: 'getOperators' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to get list of operators' });
    }

    return err({
      message:
        error.response?.data.message || 'Failed to get list of operators',
    });
  }
}
