export enum EmailLabels {
  DeliveryConfirmation = 'delivery confirmation',
  DriverInfo = 'driver info',
  TrackingOrEta = 'tracking/eta',
  AppointmentScheduling = 'appointment scheduling',
  PickupConfirmation = 'pickup confirmation',
  AppointmentConfirmed = 'appointment confirmed',
  CheckCall = 'check call',
  Quote = 'quote',
  QuoteRequest = 'quote request',
  CarrierInfo = 'carrier info',
  LoadBuilding = 'load building',
  TruckList = 'truck list',
}
