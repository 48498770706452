// Includes only relevant fields from API response
export enum StopTypes {
  Pickup = 'pickup',
  Dropoff = 'dropoff',
}

export enum SchedulingPortals {
  Opendock = 'opendock',
  Retalix = 'retalix',
}

export interface GetSlotsResponse {
  trailerType: string;
  start: string;
  end: string;
  loadType: LoadType;
  warehouse: Warehouse;
  slots: Slot[];
}

export interface GetWarehouseResponse {
  warehouseTimezone: string;
  customApptFieldsTemplate: CustomApptFieldsTemplate[];
  settings: WarehouseSettings;
  defaultSubscribedEmail: string;
}

export interface CustomApptFieldsTemplate {
  name: string;
  type: string;
  label: string;
  value: string;
  description: string;
  placeholder?: string;
  dropDownValues: string[];
  hiddenFromCarrier: boolean;
  requiredForCarrier: boolean;
  requiredForCheckIn: boolean;
  requiredForWarehouse: boolean;
  maxLengthOrValue?: number;
  minLengthOrValue?: number;
}

export interface WarehouseSettings {
  referenceNumberIsVisible: boolean;
  referenceNumberIsRequired: boolean;
  referenceNumberDisplayName: string;
  referenceNumberHelperText: string;
  referenceNumberIsUnique: boolean;
}

export interface Warehouse {
  id: string; // ID on neutron.opendock.com
  company_name: string;
  location_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
  contact_email: string;
  contact_phone: string;
}

export interface LoadType {
  id: string;
  name: string;
  direction: string;
}

interface Dock {
  id: string;
  name: string;
  warehouseId: string;
  orgId: string;
  loadTypeIds: string[];
}

export interface Slot {
  dock: Dock;
  availability: StartEnd[];
  startTimes: string[];
  warehouse: SlotWarehouse;
}

export interface SlotWarehouse {
  id: string;
  name: string;
  orgId: string;
  timezone: string;
  email: string;
}

export interface StartEnd {
  start: string;
  end: string;
}

export interface GroupedSlot {
  dock: Dock;
  startTime: Date;
  timezone: string;
}

export interface OrderedSlots {
  start: string;
  end: string;
  warehouse: Warehouse;
  warehouseTimezone: string;
  loadType: LoadType;
  trailerType: string;
  slots: Record<string, GroupedSlot[]>;
}
