import { KeyedMutator } from 'swr';

import { Warehouse } from 'types/Warehouse';
import { useThrowableSWR } from 'utils/fetcher';

type WarehousesFetchResult = {
  warehouses: Warehouse[];
  invalidate: KeyedMutator<WarehousesResponse>;
  isLoading: boolean;
  error: Error;
};

type WarehousesResponse = {
  warehouses: Warehouse[];
};

export default function useFetchRecentWarehouses(): WarehousesFetchResult {
  const { data, isLoading, mutate, error } =
    useThrowableSWR<WarehousesResponse>(`/warehouses/recent`);

  return {
    warehouses: data?.warehouses ?? [],
    invalidate: mutate,
    isLoading,
    error,
  };
}
