import {
  Breadcrumb,
  BreadcrumbHint,
  BrowserClient,
  Scope,
  captureConsoleIntegration,
  defaultStackParser,
  getDefaultIntegrations,
  makeFetchTransport,
  replayIntegration,
} from '@sentry/browser';

import APP_VERSION from '@constants/AppVersion';
import SENTRY_DSN from '@constants/SentryDsn';

import { Maybe, Undef } from 'types/UtilityTypes';

const sentryScope = new Scope();

export function getSentry() {
  return sentryScope;
}

export function initializeSentry() {
  if (sentryScope.getClient() !== undefined) {
    return;
  }

  // As per Sentry's documentation, clients running on browser extensions
  // must be initialized with new BrowserClient().
  // https://docs.sentry.io/platforms/javascript/best-practices/shared-environments/
  const sentryClient = new BrowserClient({
    dsn: SENTRY_DSN,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    beforeBreadcrumb(
      breadcrumb: Breadcrumb,
      hint?: Undef<BreadcrumbHint>
    ): Maybe<Breadcrumb> {
      if (breadcrumb.category === 'xhr') {
        const reqMethod = hint?.xhr.__sentry_xhr_v3__.method;
        const xhr = hint?.xhr as XMLHttpRequest;

        if (xhr.status >= 400) {
          const respBody: Breadcrumb = {
            type: 'http',
            level: 'error',
            category: 'xhr',
            data: {
              url: xhr.responseURL,
              method: reqMethod,
              status_code: xhr.status,
              reason: xhr.response,
            },
          };

          return respBody;
        }
      }

      return breadcrumb;
    },
    integrations: [
      replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
      }),
      captureConsoleIntegration({ levels: ['error'] }),
      ...getDefaultIntegrations({}),
    ],
    // performance monitoring
    tracesSampleRate: 1.0, // capture 100% of the transactions
    // session replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['addCompanionThreadIconArea'],
    release: `${APP_VERSION}-prod`,
  });

  sentryScope.setClient(sentryClient);

  sentryClient.init();
}
