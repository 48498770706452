import { useMemo, useState } from 'react';
import {
  Controller,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { Select as AntdSelect } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { RefreshCwIcon } from 'lucide-react';

import { Button } from 'components/Button';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/Select';
import { RHFTextInput } from 'components/input/RHFTextInput';
import ButtonLoader from 'components/loading/ButtonLoader';
import { ExtendedFormProvider } from 'contexts/extendedFormContext';
import { useServiceFeatures } from 'hooks/useServiceContext';
import { useToast } from 'hooks/useToaster';
import {
  LoadBuildInputs,
  LoadBuildResponse,
  TransportType,
  buildLoad,
} from 'lib/api/buildLoad';
import { getCustomers } from 'lib/api/getCustomers';
import { TMSCustomer } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import ButtonName from 'types/enums/ButtonName';
import ButtonNamePosthog from 'types/enums/ButtonNamePosthog';

export default function TaiLoadBuildingForm() {
  type LoadBuildingTextInputProps = React.ComponentPropsWithoutRef<
    typeof RHFTextInput
  > & { name: FieldPath<LoadBuildInputs> };
  const TaiLoadBuildingTextInput = (props: LoadBuildingTextInputProps) => (
    <RHFTextInput {...props} />
  );

  const { toast } = useToast();
  const { tmsIntegrations } = useServiceFeatures();
  const [isLoadingCustomers, setIsloadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<Maybe<TMSCustomer[]>>(null);
  const [builtLoad, setBuiltLoad] = useState<Maybe<LoadBuildResponse>>(null);

  const fetchCustomers = async () => {
    setIsloadingCustomers(true);

    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
    setIsloadingCustomers(false);
  };

  const handleRefreshCustomers = async () => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setCustomers(res.value.customerList);
      toast({
        description: 'Successfully refreshed customer list.',
        variant: 'success',
      });
    } else {
      toast({
        description: 'Error while refreshing customer list.',
        variant: 'destructive',
      });
    }
  };

  const defaultValues = useMemo<LoadBuildInputs>(() => {
    const parsedValues = {
      // NOTE: For dev, remove
      // transportType: TransportType.FLATBED,
      pickupDate: dayjs().add(1, 'day').toDate(),
      deliveryDate: dayjs().add(2, 'day').toDate(),
    };
    fetchCustomers();

    return parsedValues as LoadBuildInputs;
  }, []);

  const formMethods = useForm<LoadBuildInputs>({ defaultValues });
  const { control, handleSubmit } = formMethods;

  const onSubmitForm: SubmitHandler<LoadBuildInputs> = async (formValues) => {
    await buildLoad(formValues, formMethods, setBuiltLoad);
  };

  return (
    <div className='mb-4'>
      <ExtendedFormProvider aiDefaultValues={false}>
        <FormProvider {...formMethods}>
          <div>
            <form
              onSubmit={handleSubmit(onSubmitForm)}
              className='grid gap-4 grid-cols-1 mt-4 mx-0 w-full'
            >
              <div className='grid gap-4 grid-cols-1 w-full mx-0 '>
                <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                  Load Information
                </div>
                <div>
                  <Label name={'customerId'}>Customer</Label>
                  <div className='flex flex-row w-full items-center gap-2'>
                    <Controller
                      name='customerId'
                      control={control}
                      render={({ field }) => (
                        <div className='w-[90%] text-grayscale-content-2'>
                          <AntdSelect
                            showSearch
                            disabled={isLoadingCustomers}
                            className='h-9 text-grayscale-content-2'
                            placeholder={
                              isLoadingCustomers ? 'Loading...' : 'Choose'
                            }
                            optionFilterProp='children'
                            filterOption={(
                              input: string,
                              option: BaseOptionType | undefined
                            ) =>
                              (
                                option?.label.toLocaleLowerCase() ?? ''
                              ).includes(input.toLocaleLowerCase())
                            }
                            filterSort={(
                              optionA: BaseOptionType,
                              optionB: BaseOptionType
                            ) =>
                              (optionA?.label ?? '')
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? '').toLowerCase()
                                )
                            }
                            onChange={field.onChange}
                            value={field.value}
                            options={customers?.map((customer) => ({
                              value: customer.externalTMSID,
                              label: customer.name,
                            }))}
                          />
                        </div>
                      )}
                    />

                    <RefreshCwIcon
                      className='h-4 w-4 cursor-pointer stroke-grayscale-content-2'
                      onClick={handleRefreshCustomers}
                    />
                  </div>
                </div>

                <div>
                  <Label name={'transportType'}>Transport Type</Label>
                  <Controller
                    name='transportType'
                    control={control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className='w-full mt-1'>
                          <SelectValue placeholder='Choose' />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.keys(TransportType).map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>

                <TaiLoadBuildingTextInput
                  name='commodityDescription'
                  label='Commodity Description'
                />
                <TaiLoadBuildingTextInput
                  name='commodityWeight'
                  label='Commodity Weight (lbs)'
                  inputType='number'
                />
              </div>

              {/* Pickup */}
              <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                Pickup
              </div>

              <TaiLoadBuildingTextInput
                name='pickupLocationZip'
                label='ZIP Code'
              />

              <div className='flex flex-col w-full'>
                <Label name='pickup.date' className='text-sm'>
                  Date
                </Label>
                <Controller
                  name={`pickupDate`}
                  control={control}
                  render={({ field }) => (
                    <div className='mt-1 flex flex-row gap-1'>
                      <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                        <div className='col-span-2'>
                          <DatePicker field={field} />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>

              {/* Dropoff */}
              <div className='text-md text-grayscale-content-1 font-semibold mb-1'>
                Dropoff
              </div>

              <TaiLoadBuildingTextInput
                name='deliveryLocationZip'
                label='ZIP Code'
              />

              <div className='flex flex-col w-full'>
                <Label name='dropoff.date' className='text-base'>
                  Date
                </Label>
                <Controller
                  name={`deliveryDate`}
                  control={control}
                  render={({ field }) => (
                    <div className='mt-1 flex flex-row gap-1'>
                      <div className='grid grid-cols-2 gap-1 flex-1 mx-0 w-full'>
                        <div className='col-span-2'>
                          <DatePicker field={field} />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>

              {formMethods.formState.errors.root && (
                <div className='flex justify-center'>
                  <div className='w-full text-destructive font-semibold text-sm bg-red-200 rounded-lg p-1 text-center'>
                    {formMethods.formState.errors.root.message}
                  </div>
                </div>
              )}

              <Button
                buttonName={ButtonName.BuildLoad}
                buttonNamePosthog={ButtonNamePosthog.BuildLoad}
                type='submit'
                className='w-full'
                disabled={formMethods.formState.isSubmitting}
              >
                {formMethods.formState.isSubmitting ? (
                  <ButtonLoader />
                ) : (
                  ButtonName.BuildLoad
                )}
              </Button>

              <div className='flex-col justify-center'>
                {builtLoad?.quoteId && (
                  <>
                    <div className='whitespace-pre-wrap my-3 rounded py-3 text-grayscale-content-1 px-4 bg-green-bg'>
                      <p className='mb-2'>Load Created 🎉</p>
                      <p className='mb-2 text-sm'>
                        <b className='text-[14px]'>Load ID #: </b>
                        {builtLoad?.quoteId}
                      </p>
                      {/* <p className='mb-1 text-[14px]'>
                        Feature is only supported on Turvo for now
                        <a
                          className='underline'
                          href={`https://app.turvo.com/#/${tmsTenant}/shipments/${createdLoadId}/details`}
                        >
                          Access the created load for more details
                        </a>
                      </p> */}
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </FormProvider>
      </ExtendedFormProvider>
    </div>
  );
}
