export const getPageHeight = () => {
  const pageBody = document.body;
  const pageHtml = document.documentElement;

  return Math.max(
    pageBody.scrollHeight,
    pageBody.offsetHeight,
    pageHtml.clientHeight,
    pageHtml.scrollHeight,
    pageHtml.offsetHeight
  );
};
