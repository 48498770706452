import CheckCallSource from 'types/enums/CheckCallSource';

import { Load, ValueUnit } from './Load';
import { LoadAttributes, initFieldAttributes } from './LoadAttributes';
import { Maybe } from './UtilityTypes';

export type Email = {
  id: number;
  externalID: string;
  threadID: string;
  from: string;
  classificationMethod: string;
  labels: string;
  freightTrackingIDs: Maybe<string[]>;
};

export const InitValueUnit: ValueUnit = {
  val: 0,
  unit: null,
};

export const initLoad: Load = {
  ID: null,
  CreatedAt: '',
  UpdatedAt: '',
  DeletedAt: null,
  serviceID: 0,
  tmsID: 0,
  freightTrackingID: '',
  isPlaceholder: false,
  externalTMSID: '',
  status: '',
  mode: '',
  moreThanTwoStops: false,
  poNums: '',
  operator: '',
  rateData: {
    revenueCode: '',
    collectionMethod: '',

    customerRateType: '',
    customerLineHaulCharge: null,
    customerRateNumUnits: null,
    customerLineHaulRate: null,
    customerLineHaulUnit: '',
    customerTotalCharge: null,

    fscPercent: null,
    fscPerMile: null,

    carrierRateType: '',
    carrierLineHaulCharge: null,
    carrierRateNumUnits: null,
    carrierLineHaulRate: null,
    carrierLineHaulUnit: '',
    carrierTotalCost: null,

    carrierMaxRate: null,

    netProfitUSD: null,
    profitPercent: null,
  },
  customer: {
    externalTMSID: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    contact: '',
    phone: '',
    email: '',
    refNumber: '',
  },
  billTo: {
    externalTMSID: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    contact: '',
    phone: '',
    email: '',
  },
  pickup: {
    externalTMSID: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    contact: '',
    phone: '',
    email: '',
    businessHours: '',
    refNumber: '',
    readyTime: null,
    apptRequired: null,
    apptStartTime: null,
    apptEndTime: null,
    apptNote: '',
    timezone: '',
  },
  consignee: {
    externalTMSID: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    contact: '',
    phone: '',
    email: '',
    businessHours: '',
    refNumber: '',
    mustDeliver: '',
    apptRequired: null,
    apptStartTime: null,
    apptEndTime: null,
    apptNote: '',
    timezone: '',
  },
  carrier: {
    externalTMSID: '',
    mcNumber: '',
    dotNumber: '',
    name: '',
    phone: '',
    dispatcher: '',
    notes: '',
    sealNumber: '',
    scac: '',
    firstDriverName: '',
    firstDriverPhone: '',
    secondDriverName: '',
    secondDriverPhone: '',
    email: '',
    dispatchSource: CheckCallSource.Dispatcher,
    dispatchCity: '',
    dispatchState: '',
    truckNumber: '',
    trailerNumber: '',
    rateConfirmationSent: false,
    confirmationSentTime: null,
    confirmationReceivedTime: null,
    dispatchedTime: null,
    expectedPickupTime: null,
    pickupStart: null,
    pickupEnd: null,
    expectedDeliveryTime: null,
    deliveryStart: null,
    deliveryEnd: null,
    signedBy: '',
  },
  specifications: {
    totalInPalletCount: 0,
    totalOutPalletCount: 0,
    totalPieces: null,
    commodities: '',
    numCommodities: 0,
    totalWeight: null,
    billableWeight: null,
    totalDistance: null,
    transportType: '',

    minTempFahrenheit: 0,
    maxTempFahrenheit: 0,
  },
  notes: [],
};

export const initLoadAttributes: LoadAttributes = {
  ID: initFieldAttributes,
  CreatedAt: initFieldAttributes,
  UpdatedAt: initFieldAttributes,
  DeletedAt: initFieldAttributes,
  freightTrackingID: initFieldAttributes,
  isPlaceholder: { isNotSupported: false, isReadOnly: true, maxLength: 0 },
  status: initFieldAttributes,
  operator: initFieldAttributes,
  poNums: initFieldAttributes,
  rateData: {
    collectionMethod: initFieldAttributes,
    revenueCode: initFieldAttributes,
    customerRateType: initFieldAttributes,
    customerRateNumUnits: initFieldAttributes,
    customerLineHaulRate: initFieldAttributes,
    customerLineHaulUnit: initFieldAttributes,
    customerLineHaulCharge: initFieldAttributes,
    customerTotalCharge: initFieldAttributes,
    fscPercent: initFieldAttributes,
    fscPerMile: initFieldAttributes,
    carrierRateType: initFieldAttributes,
    carrierRateNumUnits: initFieldAttributes,
    carrierLineHaulRate: initFieldAttributes,
    carrierLineHaulUnit: initFieldAttributes,
    carrierLineHaulCharge: initFieldAttributes,
    carrierTotalCost: initFieldAttributes,
    carrierMaxRate: initFieldAttributes,
    netProfitUSD: initFieldAttributes,
    profitPercent: initFieldAttributes,
  },

  customer: {
    externalTMSID: initFieldAttributes,
    name: initFieldAttributes,
    addressLine1: initFieldAttributes,
    addressLine2: initFieldAttributes,
    city: initFieldAttributes,
    state: initFieldAttributes,
    zipCode: initFieldAttributes,
    country: initFieldAttributes,
    contact: initFieldAttributes,
    phone: initFieldAttributes,
    email: initFieldAttributes,
    refNumber: initFieldAttributes,
  },
  billTo: {
    externalTMSID: initFieldAttributes,
    name: initFieldAttributes,
    addressLine1: initFieldAttributes,
    addressLine2: initFieldAttributes,
    city: initFieldAttributes,
    state: initFieldAttributes,
    zipCode: initFieldAttributes,
    contact: initFieldAttributes,
    phone: initFieldAttributes,
    email: initFieldAttributes,
  },
  pickup: {
    externalTMSID: initFieldAttributes,
    name: initFieldAttributes,
    addressLine1: initFieldAttributes,
    addressLine2: initFieldAttributes,
    city: initFieldAttributes,
    state: initFieldAttributes,
    zipCode: initFieldAttributes,
    country: initFieldAttributes,
    contact: initFieldAttributes,
    phone: initFieldAttributes,
    email: initFieldAttributes,
    businessHours: initFieldAttributes,
    refNumber: initFieldAttributes,
    readyTime: initFieldAttributes,
    apptTime: initFieldAttributes,
    apptNote: initFieldAttributes,
  },
  consignee: {
    externalTMSID: initFieldAttributes,
    name: initFieldAttributes,
    addressLine1: initFieldAttributes,
    addressLine2: initFieldAttributes,
    city: initFieldAttributes,
    state: initFieldAttributes,
    zipCode: initFieldAttributes,
    country: initFieldAttributes,
    contact: initFieldAttributes,
    phone: initFieldAttributes,
    email: initFieldAttributes,
    businessHours: initFieldAttributes,
    refNumber: initFieldAttributes,
    mustDeliver: initFieldAttributes,
    apptTime: initFieldAttributes,
    apptNote: initFieldAttributes,
  },
  carrier: {
    mcNumber: initFieldAttributes,
    dotNumber: initFieldAttributes,
    name: initFieldAttributes,
    phone: initFieldAttributes,
    dispatcher: initFieldAttributes,
    sealNumber: initFieldAttributes,
    scac: initFieldAttributes,
    notes: initFieldAttributes,
    firstDriverName: initFieldAttributes,
    firstDriverPhone: initFieldAttributes,
    secondDriverName: initFieldAttributes,
    secondDriverPhone: initFieldAttributes,
    email: initFieldAttributes,
    dispatchCity: initFieldAttributes,
    dispatchState: initFieldAttributes,
    truckNumber: initFieldAttributes,
    trailerNumber: initFieldAttributes,
    confirmationSentTime: initFieldAttributes,
    rateConfirmationSent: initFieldAttributes,
    confirmationReceivedTime: initFieldAttributes,
    dispatchedTime: initFieldAttributes,
    expectedPickupTime: initFieldAttributes,
    pickupStart: initFieldAttributes,
    pickupEnd: initFieldAttributes,
    expectedDeliveryTime: initFieldAttributes,
    deliveryStart: initFieldAttributes,
    deliveryEnd: initFieldAttributes,
    signedBy: initFieldAttributes,
  },
  specifications: {
    totalInPalletCount: initFieldAttributes,
    totalOutPalletCount: initFieldAttributes,
    totalPieces: initFieldAttributes,
    commodities: initFieldAttributes,
    numCommodities: initFieldAttributes,
    totalWeight: initFieldAttributes,
    billableWeight: initFieldAttributes,
    totalDistance: initFieldAttributes,
    minTempFahrenheit: initFieldAttributes,
    maxTempFahrenheit: initFieldAttributes,
  },
  notes: [initFieldAttributes],
};

export const initEmail: Email = {
  id: 0,
  externalID: '',
  threadID: '',
  from: '',
  classificationMethod: '',
  labels: '',
  freightTrackingIDs: [],
};
