import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore library installed on parent module, overriding tsc check
import { Card, Descriptions, Skeleton } from 'antd';
import { Loader2, MailCheckIcon, MailPlusIcon } from 'lucide-react';

import useFetchCarrierVerificationRequest from 'hooks/useFetchCarrierVerification';
import { useToast } from 'hooks/useToaster';
import MCPLogo from 'icons/MCPLogo';
import { inviteCarrier } from 'lib/api/inviteCarrier';
import { CarrierVerification } from 'types/CarrierVerification';
import { Maybe } from 'types/UtilityTypes';

interface CarrierVerificationCardProps {
  fromEmail: string;
}

export default function CarrierVerificationCard({
  fromEmail,
}: CarrierVerificationCardProps) {
  const { carrier, isLoading } = useFetchCarrierVerificationRequest(fromEmail);
  const { toast } = useToast();
  const [inviteLoading, setInviteLoading] = useState(false);

  function borderColor(carrier: Maybe<CarrierVerification>): string {
    if (!carrier) return 'gray';
    if (carrier.risk_rating.toLowerCase().includes('unacceptablefail'))
      return 'red';
    else if (carrier.risk_rating.toLowerCase().includes('unacceptablereview'))
      return 'orange';
    return '#4786FE';
  }

  function reviewText(carrier: Maybe<CarrierVerification>): string {
    if (!carrier) return '';
    if (carrier.risk_rating.toLowerCase().includes('unacceptablefail'))
      return 'Unacceptable Fail';
    else if (carrier.risk_rating.toLowerCase().includes('unacceptablereview'))
      return 'Review Required';
    return '';
  }

  async function onInvite() {
    setInviteLoading(true);

    const res = await inviteCarrier({
      dot_number: carrier?.dot_number.toString() || '',
      email: fromEmail,
    });

    if (res.isOk()) {
      toast({
        description: 'Carrier invited.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
    }

    setInviteLoading(false);
  }

  const carrierNotFound = !carrier && !isLoading;

  const descriptionItems = [
    {
      key: '2',
      label: 'DOT',
      children: carrier?.dot_number,
      span: 1,
    },
    {
      key: '3',
      label: 'MC',
      children: carrier?.docket_number,
      span: 1,
    },
  ];

  if (carrier?.dba)
    descriptionItems.unshift({
      key: '1',
      label: 'DBA',
      children: carrier?.dba,
      span: 2,
    });

  return (
    <div className='flex flex-row px-4 items-center'>
      <Card
        className='flex-grow'
        style={{
          width: 300,
          marginTop: 16,
          borderColor: borderColor(carrier),
        }}
        //TODO: update to allow Intellivite
        actions={
          carrierNotFound || isLoading
            ? []
            : [
                <a
                  key='View in MCP'
                  href={`https://mycarrierpackets.com/CarrierInformation/DOTNumber/${carrier?.dot_number}/DocketNumber/${carrier?.docket_number}`}
                  style={{ display: 'inline' }}
                >
                  <MCPLogo
                    style={{ display: 'inline' }}
                    width={24}
                    height={24}
                  />
                </a>,
                carrier?.risk_rating
                  .toLowerCase()
                  .includes(
                    'unacceptablefail'
                  ) ? null : carrier?.completed_packet ? (
                  <MailCheckIcon
                    style={{ display: 'inline' }}
                    key='In Network'
                  />
                ) : inviteLoading ? (
                  <Loader2
                    style={{ display: 'inline' }}
                    className='mr-2 h-4 w-4 animate-spin'
                  />
                ) : (
                  <MailPlusIcon
                    style={{ display: 'inline' }}
                    key='Invite'
                    onClick={onInvite}
                  />
                ),
              ]
        }
      >
        {carrierNotFound ? (
          <p>Carrier not found.</p>
        ) : (
          <Skeleton loading={isLoading} active>
            {carrier?.risk_rating.toLowerCase().includes('unacceptable') && (
              <div className='flex flex-row justify-center'>
                <p className='uppercase text-lg font-bold'>
                  {reviewText(carrier)}
                </p>
              </div>
            )}
            <Descriptions
              title={carrier?.name}
              size='small'
              items={descriptionItems}
              column={2}
            />
          </Skeleton>
        )}
      </Card>
    </div>
  );
}
