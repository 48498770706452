import AuthService from '@auth/AuthService';
import { Maybe } from '@src/alexandria/types/UtilityTypes';

export default function getEmailAddress(): Promise<Maybe<string>> {
  async function getUserEmail(): Promise<Maybe<string>> {
    const user = await AuthService.getCurrentUser();

    if (user == null) {
      return '';
    }

    return user.email;
  }

  return getUserEmail();
}
