import { createContext } from 'react';

export type TMSContextType = {
  tmsName: string;
  tmsID: number;
};

export const TMSContext = createContext<TMSContextType>({
  tmsName: '',
  tmsID: 0,
});
