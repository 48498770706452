import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/Tabs';
import useFetchRecentWarehouses from 'hooks/useFetchRecentWarehouses';
import { useToast } from 'hooks/useToaster';
import AppointmentBaseForm from 'pages/LoadView/AppointmentScheduling/AppointmentForm';
import { StopTypes } from 'types/Appointment';
import { NormalizedLoad } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import { Warehouse } from 'types/Warehouse';

import AppointmentForm from './EmailAppointmentForm';

dayjs.extend(utc);
dayjs.extend(timezone);

type AppointmentEditorProps = {
  normalizedLoad: NormalizedLoad;
  loadPickupWarehouse: Maybe<Warehouse>;
  loadDropoffWarehouse: Maybe<Warehouse>;
};

export function AppointmentEditor({
  normalizedLoad: load,
  loadPickupWarehouse,
  loadDropoffWarehouse,
}: AppointmentEditorProps) {
  const { toast } = useToast();
  const {
    warehouses: recentWarehouses,
    isLoading,
    error,
  } = useFetchRecentWarehouses();
  const [showSchedulingPortalForm, setShowSchedulingPortalForm] =
    useState(false);
  const [type, setType] = useState<StopTypes>(StopTypes.Pickup);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (error && showSchedulingPortalForm) {
      toast({
        description: 'Error fetching warehouses.',
        variant: 'destructive',
      });
    }
  }, [isLoading, error, showSchedulingPortalForm]);

  useEffect(() => {
    switch (type) {
      case StopTypes.Pickup:
        setShowSchedulingPortalForm(!!loadPickupWarehouse);
        break;
      case StopTypes.Dropoff:
        setShowSchedulingPortalForm(!!loadDropoffWarehouse);
        break;
    }
  }, [type]);

  const toggleSchedulingPortal = () => {
    setShowSchedulingPortalForm((prevState) => !prevState);
  };

  return (
    <Tabs
      className='w-full h-full flex-1 shrink-0 flex flex-col'
      value={type}
      onValueChange={(newType) => setType(newType as StopTypes)}
    >
      <TabsList className='w-full px-0 bg-grayscale-bg-secondary rounded flex flex-row gap-0'>
        <TabsTrigger
          value={StopTypes.Pickup}
          className='flex-1 data-[state=active]:bg-orange-main data-[state=active]:text-white border-none rounded'
        >
          Pickup
        </TabsTrigger>
        <TabsTrigger
          value={StopTypes.Dropoff}
          className='flex-1 data-[state=active]:bg-orange-main data-[state=active]:text-white border-none rounded'
        >
          Dropoff
        </TabsTrigger>
      </TabsList>
      <div className='flex-1 shrink-0 mt-4 pb-4'>
        <TabsContent value={StopTypes.Pickup}>
          {showSchedulingPortalForm ? (
            <AppointmentBaseForm
              type={StopTypes.Pickup}
              load={load}
              recentWarehouses={recentWarehouses}
              toggleSchedulingPortal={toggleSchedulingPortal}
              suggestedWarehouse={loadPickupWarehouse}
            />
          ) : (
            <AppointmentForm
              normalizedLoad={load}
              type={StopTypes.Pickup}
              toggleSchedulingPortal={toggleSchedulingPortal}
            />
          )}
        </TabsContent>
        <TabsContent value={StopTypes.Dropoff}>
          {showSchedulingPortalForm ? (
            <AppointmentBaseForm
              type={StopTypes.Dropoff}
              load={load}
              recentWarehouses={recentWarehouses}
              toggleSchedulingPortal={toggleSchedulingPortal}
              suggestedWarehouse={loadDropoffWarehouse}
            />
          ) : (
            <AppointmentForm
              normalizedLoad={load}
              type={StopTypes.Dropoff}
              toggleSchedulingPortal={toggleSchedulingPortal}
            />
          )}
        </TabsContent>
      </div>
    </Tabs>
  );
}
