import { AvailableTabs } from 'constants/SidebarTabs';
import { Dropoff, NormalizedLoad, Pickup } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';

import { HostInterface } from './interface';

export class Aljex implements HostInterface {
  getFieldMoniker(key: keyof NormalizedLoad): string {
    switch (key) {
      case 'externalTMSID':
        return '';
      case 'freightTrackingID':
        return 'PRO #';
      default:
        return '';
    }
  }
  parseFreightTrackingID(): string {
    const proNumber =
      document.getElementById('headpro')?.innerText?.trim() ?? '';

    return proNumber;
  }

  // ExternalTMSID = FreightTrackingID for Aljex
  parseExternalTMSID(): string {
    return this.parseFreightTrackingID();
  }

  parsePickup(): Maybe<Pickup> {
    return null;
  }

  parseDropoff(): Maybe<Dropoff> {
    return null;
  }

  determineDefaultLoadTab(): AvailableTabs {
    return AvailableTabs.LoadInformation;
  }

  shouldAutomaticallyOpenDrumkit(): boolean {
    return (
      location.href.includes('nfi.aljex.com/route.php') &&
      // Open on PRO page or home page
      (!!document.getElementById('headpro')?.innerText ||
        !!document.getElementById('datagridtable'))
    );
  }
}
