// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import AmplitudeEvent from 'types/enums/AmplitudeEvent';
import getAmplitude from 'utils/amplitude/getAmplitude';

export default function useLogEvent(): (
  event: AmplitudeEvent,
  data?: { [key: string]: any }
) => void {
  if (!isProd()) {
    return () => {};
  }

  return async (event, data) => {
    const amplitude = await getAmplitude();
    amplitude.logEvent(event, {
      ...data,
      origin: window.self.origin,
      pathname: window.location.pathname,
      referrer: document.referrer,
    });
  };
}
