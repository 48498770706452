import { useMemo } from 'react';

import { KeyedMutator } from 'swr';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import { Email, initEmail } from 'types/Email';
import { IngestionMessage } from 'types/IngestionMessage';
import { Maybe } from 'types/UtilityTypes';
import { useThrowableSWR } from 'utils/fetcher';

type EmailFetchResult = {
  email: Maybe<Email>;
  invalidate: KeyedMutator<Email>;
  isLoading: boolean;
  error: Error;
};

export async function triggerIngestEmail(
  threadId?: string,
  ingestionMessage?: IngestionMessage
) {
  // make an API call to another endpoint to call inboxWebhook
  const response = await axios.post(`email/ingest`, {
    ingestionMessage: ingestionMessage,
    threadId: threadId,
  });

  if (response.data['triggered']) {
    console.log('Retriggered ingestion');
  }
}

export default function useFetchEmail(
  threadId?: string,
  ingestionMessage?: IngestionMessage
): EmailFetchResult {
  const { data, isLoading, mutate, error } = useThrowableSWR<Email>(
    threadId ? `email/thread/${encodeURIComponent(threadId!)}` : null
  );

  // To make end-to-end testing easier, ingest new emails synchronously.
  if (!isProd() && error?.response?.status === 404) {
    triggerIngestEmail(threadId, ingestionMessage);
  }

  // Memoize the email result to prevent unnecessary re-renders in SidebarGmailWrapper useEffect
  const email = useMemo(
    () =>
      data
        ? { ...data, threadID: data.threadID ?? threadId ?? '' }
        : threadId
          ? { ...initEmail, threadID: threadId }
          : null,
    [data, threadId]
  );

  return {
    email,
    invalidate: mutate,
    isLoading,
    error,
  };
}
