import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore posthog is in the parent dir
import { usePostHog } from 'posthog-js/react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import isProd from '@utils/isProd';

import captureException from 'utils/captureException';

type PageViewProps = {
  page_name: string;
  [key: string]: any;
};

export default function useLogPostHogPageView(
  pageName: string,
  additionalProps?: Omit<PageViewProps, 'page_name'>
): void {
  const posthog = usePostHog();

  useEffect(() => {
    if (!isProd()) {
      return;
    }

    try {
      posthog.capture('$pageview', {
        $current_url: pageName,
        ...additionalProps,
      });
    } catch (e) {
      captureException(e, {
        functionName: 'useLogPostHogPageView',
        pageName,
        additionalProps,
      });
    }
  }, [posthog, pageName, additionalProps]);
}
