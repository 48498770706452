import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { UpdateTMSRequest, UpdateTMSResult } from 'types/api/UpdateTMS';
import captureException from 'utils/captureException';

export async function updateTMS(
  loadId: number,
  data: UpdateTMSRequest
): Promise<Result<UpdateTMSResult, ApiError>> {
  try {
    const response = await axios.patch<UpdateTMSResult>(
      `/load/${loadId}`,
      data
    );

    return ok(response.data);
  } catch (error) {
    captureException(error, { functionName: 'updateTMS' });

    if (!isAxiosError(error)) {
      return err({ message: 'Oops, something went wrong!' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Oops, something went wrong!',
      email: error.response?.data.email,
      load: error.response?.data.load,
      loadAttributes: error.response?.data.loadAttributes,
    });
  }
}
