import { isAxiosError } from 'axios';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore auth is in the parent dir
import AuthService from '@auth/AuthService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { Exception } from 'types/Exception';
import { ApiError } from 'types/api/ApiError';
import TMS from 'types/enums/Integrations';
import captureException from 'utils/captureException';

// TODO use service context
export async function submitException(
  loadId: number,
  freightTrackingId: string,
  exceptionCode: string,
  isOnTime: boolean,
  source: string,
  driver: string,
  carrier: string,
  trailer: string,
  note: string,
  dateTime: Dayjs,
  tmsName: string
): Promise<Result<Exception[], ApiError>> {
  let dateISO = dateTime.toISOString();
  if (tmsName === TMS.Aljex) {
    dateISO = dateTime.utcOffset(0, true).toISOString(); // Use UTC as placeholder TZ to string version of timestamp the exact same
  }
  // NFI request: associate check call with author
  const user = await AuthService.getCurrentUser();
  const finalNotes = note + ' - ' + user?.email.split('@')[0];

  const requestBody = {
    loadID: loadId,
    freightTrackingID: freightTrackingId,
    eventCode: exceptionCode, //called eventCode in aljex integration, exception for frontend
    isOnTime: isOnTime,
    source: source,
    driver: driver,
    carrier: carrier,
    trailer: trailer,
    note: finalNotes,
    dateTime: tmsName === TMS.Aljex ? new Date(0) : dateISO,
    dateTimeWithoutTimezone: tmsName === TMS.Aljex ? dateISO : new Date(0),
  };

  try {
    const resp = await axios.post(`/load/${loadId}/exception`, requestBody);

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'submitException' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to submit exception' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message: error.response?.data.message || 'Failed to submit exception',
    });
  }
}
