// These enums must match those in drumkit/common/models/integration.
enum TMS {
  Aljex = 'aljex',
  Turvo = 'turvo',
  Relay = 'relay',
  Tai = 'tai',
  McleodEnterprise = 'mcleodenterprise',
}

export default TMS;
