import { isValidNonDateObject } from 'utils/isValidObject';

/**
 * This function normalizes suggested changes in the form of objects and arrays,
 * and is compatible with all pipelines.
 *
 * For objects, we flatten the nested properties:
 *  { carrier: { name: 'John' } } -> { carrier.name: 'John' }
 *
 * For arrays, we simply return their length, but functionalities can be extended as needed:
 *  { trucks: [{id: 1...}, {id: 2...}] } -> { trucks: 2 }
 */
export const flattenSuggestionChanges = (
  obj: Record<string, any>,
  parent?: string
): Record<string, any> => {
  let res: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? parent + '.' + key : key;

    if (isValidNonDateObject(value)) {
      if (Array.isArray(value)) {
        res[propName] = value.length;
      } else {
        res = {
          ...res,
          ...flattenSuggestionChanges(value as Record<string, any>, propName),
        };
      }
    } else {
      res[propName] = value;
    }
  }

  return res;
};
