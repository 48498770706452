import { Controller, FieldPath, useFormContext } from 'react-hook-form';

import { XCircleIcon } from 'lucide-react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/Accordion';
import { DatePicker } from 'components/DatePicker';
import { Label } from 'components/Label';
import { RHFTextInput } from 'components/input/RHFTextInput';

import { RedwoodTruckListInputs } from './Redwood/RedwoodTruckListForm';

type TruckTextInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: FieldPath<RedwoodTruckListInputs>;
};

type TruckInputProps = TruckTextInputProps &
  TruckDateTimeInputProps & {
    type: TruckFieldType;
  };

export const TruckInput = (props: TruckInputProps) => {
  switch (props.type) {
    case 'text':
      return <TruckTextInput {...props} />;
    case 'date':
      return <TruckDateTimeInput {...props} />;
    case 'select':
      return <RHFTextInput {...props} />;
    default:
      return null;
  }
};

export const TruckTextInput = (props: TruckTextInputProps) => (
  <RHFTextInput {...props} />
);

type TruckDateTimeInputProps = React.ComponentPropsWithoutRef<
  typeof RHFTextInput
> & {
  name: `${string}.pickupDate` | `${string}.dropoffDate`;
  label: string;
  includeTime?: boolean;
};

export const TruckDateTimeInput = ({
  name,
  label,
  required,
}: TruckDateTimeInputProps) => {
  const { control } = useFormContext<Record<string, any>>();

  return (
    <div className='mt-2'>
      <Label
        name={name}
        required={required}
        className='text-grayscale-content-3 text-sm'
      >
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className='mt-1 flex flex-row gap-1'>
            <div className='flex gap-4 flex-1'>
              <DatePicker field={field} />
            </div>
            {field.value && (
              <button
                title='Clear date'
                onClick={() => field.onChange(null)}
                className='h-9 flex items-center justify-center'
              >
                <XCircleIcon className='w-4 h-4' />
              </button>
            )}
          </div>
        )}
      />
    </div>
  );
};

type TruckListSectionAccordionItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  triggerClassName?: string;
  label: string;
  name: string;
  showEditIcon: boolean;
  dateEditing?: string;
  setDateEditing?: React.Dispatch<React.SetStateAction<string>>;
};

export function TruckListSectionAccordionItem({
  icon,
  children,
  contentClassName = '',
  triggerClassName = '',
  name,
  label,
  showEditIcon,
  dateEditing,
  setDateEditing,
}: TruckListSectionAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionTrigger
        className={`${triggerClassName} hover:no-underline`}
        icon={icon}
        showEditIcon={showEditIcon}
        editIconCallback={(e) => {
          e.preventDefault();
          if (setDateEditing) {
            setDateEditing(dateEditing === name ? '' : name);
          }
        }}
      >
        {label}
      </AccordionTrigger>
      <AccordionContent className={contentClassName}>
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}

export type TruckFieldType = 'text' | 'location' | 'date' | 'select';

type TruckFieldProps = {
  label: string;
  type: TruckFieldType;
};

export const truckFieldMap: Record<string, TruckFieldProps> = {
  pickupLocation: { label: 'Pickup', type: 'location' },
  pickupDate: { label: 'Pickup Date', type: 'date' },
  dropoffLocation: { label: 'Dropoff', type: 'location' },
  dropoffDate: { label: 'Dropoff Date', type: 'date' },
  type: { label: 'Type', type: 'text' },
  length: { label: 'Length', type: 'text' },
};
