import { AvailableTabs } from 'constants/SidebarTabs';
import { McleodEnterprise } from 'lib/hosts/mcleodenterprise';
import { CustomHost, DrumkitHost } from 'types/DrumkitHosts';
import { Dropoff, NormalizedLoad, Pickup } from 'types/Load';
import { Maybe } from 'types/UtilityTypes';
import TMS from 'types/enums/Integrations';

import { Aljex } from './aljex';
import { DelegatedGmail } from './gmail';
import { Relay } from './relay';

export interface HostInterface {
  getFieldMoniker(key: keyof NormalizedLoad): string;
  parseFreightTrackingID(): string;
  parseExternalTMSID(): string;
  parsePickup(): Maybe<Pickup>;
  parseDropoff(): Maybe<Dropoff>;
  determineDefaultLoadTab(): AvailableTabs;
  shouldAutomaticallyOpenDrumkit(): boolean;
}

export function createTMSInstance(host: DrumkitHost): HostInterface {
  switch (host) {
    case TMS.Aljex:
      return new Aljex();

    case TMS.Relay:
      return new Relay();

    case TMS.McleodEnterprise:
      return new McleodEnterprise();

    case CustomHost.DelegatedGmail:
      return new DelegatedGmail();

    default:
      throw new Error('Invalid Drumkit host: ' + host);
  }
}

export function determineTMSWebsite(): DrumkitHost {
  switch (true) {
    case location.href.includes('aljex.com'):
      return TMS.Aljex;
    case location.href.includes('relaytms.com'):
      return TMS.Relay;
    case location.href.includes('mail.google.com'):
      return CustomHost.DelegatedGmail;
    default:
      return null;
  }
}
