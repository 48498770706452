import React from 'react';

import dayjs from 'dayjs';
import { AlertTriangle } from 'lucide-react';

import useTMSContext from 'hooks/useTMSContext';
import { Exception } from 'types/Exception';
import TMS from 'types/enums/Integrations';

type ExceptionProps = Exception;

type ExceptionTimelineProps = {
  events: ExceptionProps[];
};

const ExceptionTimeline: React.FC<ExceptionTimelineProps> = ({ events }) => {
  if (!events) {
    return null;
  }
  const { tmsName } = useTMSContext();
  const isTZAgnostic = tmsName === TMS.Aljex;

  const shortTZ = dayjs().tz().format('z');
  const normalizedTZ = isTZAgnostic ? '' : ` ${shortTZ}`;

  return (
    <>
      {isTZAgnostic && (
        <span className='text-xs font-normal italic text-gray-500 dark:text-gray-500 ms-4'>
          {`Note: Unless otherwise stated, all timestamps are in the timezone of the check call's location.`}
        </span>
      )}

      <ol className='relative border-s ms-4 border-violet-blue-st-patrick dark:border-violet-400 px-2'>
        {events
          .sort((a, b) =>
            // Sort in descending order (most recent first)

            isTZAgnostic
              ? new Date(b.dateTimeWithoutTimezone).getTime() -
                new Date(a.dateTimeWithoutTimezone).getTime()
              : new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
          )
          .map((event) => (
            <li className='mb-10 ms-3' key={null}>
              <span className='absolute flex items-center justify-center w-6 h-6 mr-2 bg-white rounded-full -start-3 ring-1 ring-offset-4 ring-violet-blue-st-patrick dark:ring-gray-900 dark:bg-blue-900'>
                <AlertTriangle color='#E92C2C' size={'sm'} strokeWidth={1} />
              </span>
              <div className='flex items-start text-zinc-600 dark:text-white text-sm leading-6 mt-6 justify-between'>
                <span>{event.eventCode || event.status}</span>
                <span>
                  <time className='flex text-right text-xs font-normal leading-none text-gray-500 dark:text-gray-500 pl-6'>
                    {(() => {
                      // Handle if somehow no timestamp provided
                      if (!event.dateTime && !event.dateTimeWithoutTimezone) {
                        return <p>N/A</p>;
                      }

                      const dateTime = isTZAgnostic
                        ? new Date(event.dateTimeWithoutTimezone)
                        : new Date(event.dateTime);

                      return (
                        <>
                          {dayjs(dateTime)
                            .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                            .format('M/D/YY')}
                          <br />
                          {`${dayjs(dateTime)
                            .tz(isTZAgnostic ? 'UTC' : undefined) // If valid TZ, undefined = display in user's locale
                            .format('H:mm')} ${normalizedTZ}`}
                        </>
                      );
                    })()}
                  </time>
                </span>
              </div>

              {/* For Relay loads, highlight the fact that these are just check calls where Issue = true */}
              {tmsName === TMS.Relay && (
                <div>
                  <span
                    className={`text-xs leading-5 inline bg-red-bg text-red-main displa`}
                  >
                    Issue:{' '}
                  </span>
                  <span
                    className={`text-xs leading-5 inline text-red-main bg-red-bg px-2`}
                  >
                    {`${true}`}
                  </span>
                </div>
              )}

              {event.isOnTime !== null && (
                <div>
                  <span
                    className={`text-neutral-400 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg' : ''}`}
                  >
                    On Time:{' '}
                  </span>
                  <span
                    className={`text-gray-500 text-xs leading-5 inline ${!event.isOnTime ? 'text-red-main bg-red-bg px-2' : ''}`}
                  >
                    {`${event.isOnTime}`}
                  </span>
                </div>
              )}

              {event.carrier && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Carrier:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.carrier}
                  </span>
                </div>
              )}

              {event.driver && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Driver:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.driver}
                  </span>
                </div>
              )}

              {event.trailer && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Trailer:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.trailer}
                  </span>
                </div>
              )}

              {event.whoEntered && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Author:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.whoEntered}
                  </span>
                </div>
              )}

              {event.source && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Source:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.source}
                  </span>
                </div>
              )}

              {event.note && (
                <div>
                  <span className='text-neutral-400 text-xs leading-5 inline'>
                    Notes:{' '}
                  </span>
                  <span className='text-gray-500 text-xs leading-5 inline'>
                    {event.note}
                  </span>
                </div>
              )}
            </li>
          ))}
      </ol>
    </>
  );
};

export { ExceptionTimeline, ExceptionProps, ExceptionTimelineProps };
