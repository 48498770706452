import { isAxiosError } from 'axios';
import { Result, err, ok } from 'neverthrow';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore utils is in the parent dir
import axios from '@utils/axios';

import { ApiError } from 'types/api/ApiError';
import { UpdateShipperCheckInResult } from 'types/api/UpdateShipperCheckInResult';
import captureException from 'utils/captureException';

export async function updateShipperCheckInSOPs(
  loadId: number,
  freightTrackingId: string,
  frequency: string,
  timeUnit: string,
  defaultCarrierCheckInSettings: boolean,
  pickupAppt: boolean,
  loaded: boolean,
  dropoffAppt: boolean,
  unloaded: boolean
): Promise<Result<UpdateShipperCheckInResult, ApiError>> {
  const requestBody = {
    loadId,
    freightTrackingId,
    frequency,
    timeUnit,
    defaultCarrierCheckInSettings,
    pickupAppt,
    loaded,
    dropoffAppt,
    unloaded,
  };

  try {
    // TODO: TBD
    const resp = await axios.post('', requestBody);

    return ok(resp.data);
  } catch (error) {
    captureException(error, { functionName: 'updateShipperCheckInSOPs' });

    if (!isAxiosError(error)) {
      return err({ message: 'Failed to update Shipper Check-in SOPs' });
    }

    if (error && isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }

    if (error && error.message === 'Extension context invalidated.') {
      throw error;
    }

    return err({
      message:
        error.response?.data.message ||
        'Failed to update Shipper Check-in SOPs',
    });
  }
}
