import { RegisterOptions } from 'react-hook-form';

import { Email } from 'types/Email';

export const emailFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    message: 'Invalid email',
  },
};

export const zipCodeFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^\d{5}$/,
    message: 'Invalid Zip Code',
  },
};

export const phoneFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
    message: 'Invalid phone number format. Expected (XXX) XXX-XXXX',
  },
};

export const datetimeFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value:
      /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})(,\s(0?[1-9]|1[0-2]):[0-5][0-9](:[0-5][0-9])?\s(AM|PM))?$/,
    message:
      'Invalid time format. Acceptable formats: MM/DD/YYYY, MM/DD/YY, or MM/DD/YYYY, HH:mm(:ss) AM/PM',
  },
};

export const codeFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^[a-zA-Z0-9]+$/,
    message: 'Invalid input. Only letters and numbers are allowed.',
  },
};

export const dateOnlyFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    message: 'Invalid date format. Acceptable format: MM/DD/YYYY',
  },
};

export const timeOnlyFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^(0?[1-9]|1[0-2]):[0-5][0-9]\s([A]{1}|[P]{1})[M]{1}$/,
    message: 'Invalid time format. Acceptable format: HH:mm AM/PM',
  },
};

export const _24hourTimeOnlyFieldOptions: RegisterOptions<Email> = {
  pattern: {
    value: /^^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$$/,
    message: 'Invalid time format. Acceptable format: HH:mm',
  },
};

export const required: RegisterOptions<any> = {
  required: 'This field is required',
};
